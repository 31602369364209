import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Button,
  Collapse,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import WorkIcon from "@mui/icons-material/Work";
import { format } from "date-fns";
import "./SingleMultiCityItem.css";
import FlightDetails from "../FlightDetails/FlightDetails";
import FareDetails from "../FareDetails/FareDetails";
import FarePolicy from "../FarePolicy/FarePolicy";
import Baggage from "../Baggage/Baggage";
import CommissionInvoice from "../CommissionInvoice/CommissionInvoice";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import Transit from "../SingleFlight/Transit";
import CircleIcon from "@mui/icons-material/Circle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const SingleMultiCityItem = ({
  flightData,
  adultCount,
  childCount,
  infant,
  to,
  from,
  tripType,
  fromAddress,
  toAddress,
  dDate,
  agentFarePrice,
  setAgentFarePrice,
  commisionFarePrice,
  setCommisionFarePrice,
  customerFare,
  setCustomerFare,
  isLoaded,
}) => {
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const clientPrice = parseInt(
    flightData.system !== "Galileo"
      ? flightData?.customerPrice || flightData?.clientPrice
      : flightData?.price || flightData?.customerPrice
  );

  const clientFare = Math.round(clientPrice);
  const agentFare = Math.round(
    parseInt(
      flightData.system !== "Galileo"
        ? flightData?.subagentprice || flightData.price
        : flightData.subagentprice
    )
  );
  const commission = Math.round(clientFare - agentFare);

  const navigate = useNavigate();
  const FlightInformation = () => {
    navigate("/user/multicityflightinfo", {
      state: {
        flightData,
        adultCount,
        childCount,
        infant,
        to,
        from,
        tripType,
        fromAddress,
        toAddress,
        dDate,
        clientFare,
        agentFare,
        commission,
      },
    });
  };

  const paxCount = adultCount + childCount + infant;
  let count = [];
  for (let i = 0; i < paxCount; i++) {
    count.push(i);
  }
  //todo: calculate total flight duration
  const calDuration = (arr) => {
    const timeArr = arr.map((item) => item.flightduration);
    const convertTime = timeArr.map(
      (item) =>
        parseInt(item.split(" ")[0]) * 3600 * 1000 +
        parseInt(item.split(" ")[1]) * 60 * 1000
    );
    const milliseconds = convertTime.reduce((cur, acc) => cur + acc, 0);
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    minutes = minutes % 60;
    hours = hours % 24;
    return `${hours.toString().padStart(2, 0)}H:${minutes
      .toString()
      .padStart(2, 0)}Min`;
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          transition: "all .5s ease-in-out",
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
          marginBottom: "10px",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Grid container sm={8} md={10} padding="15px 15px 0px 15px">
          {flightData.segments.map((segment, i, arr) => (
            <Grid
              container
              columnSpacing={2}
              key={i}
              sx={{ mb: 1 }}
              alignItems="center"
            >
              {/* //todo: first section */}
              <Grid item md={2.5}>
                <Box>
                  <Box width="50px" height="50px">
                    <img
                      src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${segment[0]?.marketingcareer}.png`}
                      className={`${flightData?.system?.toLowerCase()}`}
                      alt={`${segment[0]?.marketingcareer}`}
                    />
                  </Box>
                  <Tooltip title={`${segment[0]?.marketingcareer}`}>
                    <Typography
                      fontSize={{ xs: "10px", md: "13px" }}
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                        color: "var(--secondary-color)",
                      }}
                      noWrap
                    >
                      {`${segment[0]?.marketingcareerName}`}
                    </Typography>
                  </Tooltip>
                  <Typography
                    sx={{
                      color: "var(--gray)",
                      fontSize: { xs: "10px", md: "12px" },
                      fontWeight: 500,
                    }}
                  >
                    {flightData?.refundable === "Refundable"
                      ? "Refundable"
                      : " Non Refundable"}
                  </Typography>
                </Box>
              </Grid>
              {/* //todo: second section */}
              <Grid item md={3.5}>
                <Box>
                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "15px",
                        lg: "22px",
                      },
                    }}
                  >
                    {segment[0]?.departureTime
                      ? format(new Date(segment[0]?.departureTime), "hh:mm a")
                      : "Departure Date"}
                    <Typography style={{ color: "var(--secondary-color)" }}>
                      {segment[0]?.departureLocation?.split(",")[0]}
                    </Typography>
                  </Typography>

                  <Tooltip
                    title={`${segment[0]?.departureAirport}${", "}${
                      segment[0]?.departure
                    }`}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                        color: "var(--secondary-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                        },
                      }}
                      noWrap
                    >
                      {segment[0]?.departureAirport}
                      {", "}
                      {segment[0].departure}
                    </Typography>
                  </Tooltip>
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontWeight: 400,
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                      },
                    }}
                  >
                    {segment[0]?.departureTime
                      ? format(new Date(segment[0]?.departureTime), "dd MMM yy")
                      : "Departure Date"}
                  </Typography>
                </Box>
              </Grid>
              {/* //todo: third section */}
              <Grid item md={2.5}>
                <Transit flightData={flightData} />
              </Grid>
              {/* //todo:Fourth Section */}
              <Grid item md={3.5} textAlign="left">
                <Box>
                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "15px",
                        lg: "22px",
                      },
                    }}
                  >
                    {segment[segment.length - 1]?.arrivalTime
                      ? format(
                          new Date(segment[segment?.length - 1]?.arrivalTime),
                          "hh:mm a"
                        )
                      : "Arrival Time"}
                    <Typography sx={{ color: "var(--secondary-color)" }}>
                      {
                        segment[segment?.length - 1]?.arrivalLocation?.split(
                          ","
                        )[0]
                      }
                    </Typography>
                  </Typography>

                  <Tooltip
                    title={`${
                      segment[segment?.length - 1]?.arrivalAirport
                    }${", "}${segment[segment?.length - 1]?.arrival}`}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                        color: "var(--secondary-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                        },
                      }}
                      noWrap
                    >
                      {segment[segment.length - 1]?.arrivalAirport}
                      {", "}
                      {segment[segment.length - 1]?.arrival}
                    </Typography>
                  </Tooltip>
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontWeight: 400,
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                      },
                    }}
                  >
                    {segment[segment.length - 1]?.arrivalTime
                      ? format(
                          new Date(segment[segment.length - 1]?.arrivalTime),
                          "dd MM yy"
                        )
                      : "Arrival Date"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* //todo: left section */}
        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Box textAlign="end" mr={{ xs: "10px", sm: "0px" }}>
            <Typography
              style={{
                fontSize: "18px",
                color: "var(--secondary-color)",
                fontWeight: 500,
              }}
            >
              SAR{" "}
              {commaNumber(
                flightData?.TotalWithMarkUp?.toFixed(2) ||
                  flightData?.clientPrice
              )}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                color: "var(--gray)",
                textDecoration: "line-through",
                fontWeight: 500,
              }}
            >
              {/* SAR {commaNumber(clientFare)} */}
            </Typography>
          </Box>
          <Box
            textAlign="end"
            mt={1}
            display={{ xs: "flex", sm: "block" }}
            justifyContent="space-between"
          >
            <Button
              size="small"
              className="shine-effect"
              style={{
                color: "var(--white)",
                fontWeight: 500,
                backgroundColor: "var(--primary-color)",
                borderRadius: "5px",
              }}
              disabled={flightData?.system === "Galileo" ? true : false}
              onClick={FlightInformation}
            >
              BOOK NOW
            </Button>
            {/* //todo:Flight Details Start */}
            {["right"].map((anchor) => (
              <Box key={anchor}>
                <Button
                  size="small"
                  onClick={toggleDrawer(anchor, true)}
                  style={{
                    color: "var(--primary-color)",
                    fontWeight: 500,
                    fontSize: "11px",
                    padding: 0,
                    marginTop: "5px",
                  }}
                >
                  Flight Details
                  <PlayArrowIcon style={{ padding: "0", fontSize: "18px" }} />
                </Button>
                <SwipeableDrawer
                  style={{ margin: "0px", padding: "0px" }}
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  <Box
                    bgcolor="var(--drawer-bgcolor)"
                    sx={{ width: { xs: "85vw", sm: "70vw", md: "50vw" } }}
                  >
                    <Box>
                      <Box margin={{ xs: "15px", md: "2vw 0 2vw 3vw" }}>
                        <Typography
                          sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                          color="var(--secondary-color)"
                          fontWeight={500}
                        >
                          {
                            flightData?.segments[0]?.departureLocation?.split(
                              " ,"
                            )[0]
                          }
                          ({flightData?.departure}){" - "}
                          {
                            flightData?.segments[
                              flightData?.segments?.length - 1
                            ]?.arrivalLocation?.split(" ,")[0]
                          }
                          ({flightData?.arrival})
                        </Typography>
                        <Typography>
                          {tripType === "oneway"
                            ? "One Way"
                            : tripType === "return"
                            ? "Return"
                            : "Multi City"}{" "}
                          Flight <span>|</span>{" "}
                          {adultCount > 0 && `Adult(${adultCount})`}
                          {childCount > 0 && `Children(${childCount})`}
                          {infant > 0 && `Infant(${infant})`} <span>|</span>{" "}
                          {/* {format(
                            new Date(flightData?.departureDate),
                            "dd MMM yyyy"
                          )}
                          {" | "}
                          {format(
                            new Date(flightData?.arrivalDate),
                            "dd MMM yyyy"
                          )} */}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Flight Details
                        </Typography>
                        {/* //todo: Flight Details seciton */}
                        <FlightDetails
                          flightData={flightData}
                          clientFare={clientFare}
                          agentFare={agentFare}
                          commission={commission}
                          adultCount={adultCount}
                          childCount={childCount}
                          infant={infant}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Baggage
                        </Typography>
                        {/* //todo Baggage section */}
                        <Baggage
                          flightData={flightData}
                          clientFare={clientFare}
                          agentFare={agentFare}
                          commission={commission}
                          adultCount={adultCount}
                          childCount={childCount}
                          infant={infant}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Fare Details
                        </Typography>
                        {/* //todo: Fare Details */}
                        <FareDetails
                          flightData={flightData}
                          clientFare={clientFare}
                          agentFare={agentFare}
                          commission={commission}
                          adultCount={adultCount}
                          childCount={childCount}
                          infant={infant}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Fare Policy
                        </Typography>
                        {/* //todo: Fare Policy Section */}
                        <FarePolicy
                          flightData={flightData}
                          clientFare={clientFare}
                          agentFare={agentFare}
                          commission={commission}
                          adultCount={adultCount}
                          childCount={childCount}
                          infant={infant}
                        />
                      </Box>

                      {/* -------new end */}
                    </Box>
                    <Box textAlign="Right" px={5} pb={4}>
                      <Button
                        size="small"
                        className="shine-effect"
                        style={{
                          color: "var(--white)",
                          fontWeight: 500,
                          backgroundColor: "var(--primary-color)",
                          borderRadius: "5px",
                        }}
                        onClick={FlightInformation}
                      >
                        BOOK NOW
                      </Button>
                    </Box>
                  </Box>
                </SwipeableDrawer>
              </Box>
            ))}
            {/* //todo:Flight Details End */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleMultiCityItem;
