import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const AirlineCheckBox = ({
  filteredData,
  setfilteredData,
  data,
  setData,
  setPageCount,
}) => {
  const [checkedItems, setCheckedItems] = useState({}); // todo:Initialize empty object for checked items

  // let [names, setNames] = useState([]); //todo: store the name in empty array
  const [itemsToShow, setItemsToShow] = useState(5); //todo: show more and show less state
  //todo: show more and show less functions
  const showmore = () => {
    setItemsToShow(uniqueAirlineNames.length);
  };
  const showless = () => {
    setItemsToShow(5);
  };
  //todo:shorten the whole Array
  let airlineNames = data.map((item) => {
    return {
      careerName: item.careerName,
      career: item.career,
      clientPrice: item.system !== "Galileo" ? item.clientPrice : item.price,
      isActive: false,
    };
  });

  //todo:find uniqueAirlineNames form the Array
  let uniqueAirlineNames = [
    ...new Map(airlineNames.map((item) => [item["career"], item])).values(),
  ];

  //todo:find how many duplicate airlines are in the array
  let airlineDuplicateCount = uniqueAirlineNames.reduce((acc, curr) => {
    const str = JSON.stringify(curr.career);
    acc[str] = (acc[str] || 0) + 1;
    return acc;
  }, {});

  // todo:Returns a map of airline names to their unique airline names.
  uniqueAirlineNames.map((item) => {
    for (let count in airlineDuplicateCount) {
      if (item.career === JSON.parse(count)) {
        item.count = airlineDuplicateCount[count];
      }
    }
  });

  const handleCheckBox = (careerName) => {
    // todo:Update state of checked items when checkbox is checked or unchecked
    const event = window.event;
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
    let names = [];
    if (event.target.checked) {
      // setNames([...names, event.target.name]);
      names.push(event.target.name);
    } else {
      // setNames(names.filter((item) => item !== careerName));
      names.filter((item) => item !== careerName);
    }
    // todo: set data depending on careerName
    if (names.length !== 0) {
      const filter = data?.filter((item) => names.includes(item?.careerName));
      setfilteredData(filter);
      setPageCount(Math.ceil(filter?.length / 30));
    } else {
      setfilteredData(data);
      setPageCount(Math.ceil(data?.length / 30));
    }
  };

  return (
    <Box>
      {uniqueAirlineNames
        .sort((a, b) => a.clientPrice - b.clientPrice)
        .slice(0, itemsToShow)
        .map((item, index) => {
          return (
            <Box width="100%" key={index}>
              <Tooltip title={item?.careerName}>
                <FormGroup className="check-box-text09">
                  <FormControlLabel
                    control={<Checkbox className="box-0" />}
                    label={item?.careerName}
                    name={item?.careerName}
                    checked={checkedItems[item?.careerName]}
                    onChange={() => handleCheckBox(item?.careerName)}
                  />
                </FormGroup>
              </Tooltip>
            </Box>
          );
        })}
      {itemsToShow === 5 ? (
        <button
          style={{
            outline: "none",
            border: "none",
            cursor: "pointer",
            padding: "2px 10px",
          }}
          onClick={showmore}
        >
          Show More
        </button>
      ) : (
        <button
          style={{
            outline: "none",
            border: "none",
            cursor: "pointer",
            padding: "2px 10px",
          }}
          onClick={showless}
        >
          Show Less
        </button>
      )}
    </Box>
  );
};

export default AirlineCheckBox;
