import { Button, Container, Modal, Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import FlightSearchBox from "../FlightSearchBox/FlightSearchBox";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import HotelIcon from "@mui/icons-material/Hotel";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import "./HomeSearchBox.css";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import secureLocalStorage from "react-secure-storage";
import License from "../License/License";
import VisaForm from "../VisaForm/VisaForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "50%",
  background: "var(--light-gray)",
  borderRadius: "20px",
  boxShadow: 24,
  px: { xs: 2, sm: 4 },
  py: 2,
};

const HomeSearchBox = ({ type, setType, handleTypeChange }) => {
  const user = secureLocalStorage.getItem("user-info");
  const navigate = useNavigate();

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  const [value, setValue] = React.useState("oneway");

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yy")
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "dd MMM yy")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  // for multiCity
  const now = useRef(new Date());
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));
  const [fromSendData, setFromSendData] = useState("RUH");
  const [fromSearchText, setFromSearchText] = useState("King Khaled Intl[RUH]");
  const [faddress, setfaddress] = useState("Riyadh,SAUDI ARABIA");
  const [toSendData, setToSendData] = useState("JED");
  const [toSearchText, setToSearchText] = useState("Jeddah Intl[JED]");
  const [toAddress, setToAddress] = useState("Jeddah,SAUDI ARABIA");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);
  const [result, setResult] = useState(1);
  const [className, setClassName] = useState("Economy");
  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  const [searchData, setSearchData] = useState({
    agentId: user?.user?.agentId || "TFA1000",
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    CityCount: 1,
    segments: [
      {
        id: 0,
        openFrom: false,
        DepFrom: fromSendData.trim(),
        depFromText: fromSearchText.trim(),
        ArrTo: "DXB",
        arrToText: "Dubai Intl Airport [DXB]",
        openTo: false,
        Date: new Date().toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
    ],
  });

  const [openLicense, setOpenLicense] = useState(false);
  const handleOpenLicense = () => setOpenLicense(true);
  const handleCloseLicense = () => setOpenLicense(false);

  const [openVisa, setOpenVisa] = useState(false);
  const handleOpenVisa = () => setOpenVisa(true);
  const handleCloseVisa = () => setOpenVisa(false);

  return (
    <Box>
      <Container>
        <Box
          sx={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            p: { xs: 1, sm: 2, md: 3 },
            my: "10px",
            borderRadius: "10px",
          }}
        >
          <TabContext value={type}>
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "100%" },
                height: { md: "fit-content", sm: "100%", xs: "100%" },
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                opacity: "1",
                ".MuiTabs-root": {
                  minHeight: "fit-content",
                  margin: { xs: "0", sm: "5px" },
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  padding: { xs: "0px 0px", sm: "0px 5px", md: "0px 30px" },
                  borderRadius: "5px",
                },
                ".MuiTabs-flexContainer": {
                  gap: { xs: "0px", sm: "5px", md: "20px" },
                },
                "& button": {
                  opacity: "1",
                  background: "transparent",
                  color: "var(--primary-color)",
                  width: "fit-content",
                  minHeight: "fit-content",
                  fontSize: { xs: "10px", sm: "14px", md: "16px" },
                },
                "& button.Mui-selected,& button.Mui-selected >svg": {
                  background: "transparent",
                  color: "var(--secondary-color) !important",
                },
                ".MuiTab-root": {
                  minWidth: "fit-content",
                },
                ".MuiButtonBase-root": {
                  minWidth: "fit-content",
                },
              }}
            >
              <TabList
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={type}
                onChange={handleTypeChange}
                TabIndicatorProps={{
                  style: {
                    background: "var(--secondary-color)",
                    height: "3px",
                  },
                }}
              >
                <Tab
                  icon={
                    <FlightIcon
                      sx={{
                        fontSize: {
                          xs: "15px",
                          sm: "15px",
                          md: "20px",
                        },
                        display: { xs: "none", sm: "flex" },
                        color: "var(--primary-color)",
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Flight"
                  value="flight"
                />
                <Tab
                  icon={
                    <HotelIcon
                      sx={{
                        fontSize: {
                          xs: "15px",
                          sm: "20px",
                          color: "var(--primary-color)",
                        },
                        display: { xs: "none", sm: "flex" },
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Hotel"
                  value="hotel"
                />
                <Tab
                  icon={
                    <TravelExploreIcon
                      sx={{
                        fontSize: {
                          xs: "15px",
                          sm: "20px",
                          color: "var(--primary-color)",
                        },
                        display: { xs: "none", sm: "flex" },
                      }}
                    />
                  }
                  iconPosition="start"
                  label="Holiday"
                  value="holiday"
                />

                <Button
                  sx={{ display: "flex", gap: "8px" }}
                  onClick={handleOpenVisa}
                >
                  <AirplaneTicketIcon
                    sx={{
                      fontSize: {
                        xs: "15px",
                        sm: "20px",
                        color: "var(--primary-color)",
                      },
                      display: { xs: "none", sm: "flex" },
                    }}
                  />
                  Visa
                </Button>
                <Button
                  sx={{ display: "flex", gap: "8px" }}
                  onClick={handleOpenLicense}
                >
                  <DriveEtaIcon
                    sx={{
                      fontSize: {
                        xs: "15px",
                        sm: "20px",
                        color: "var(--primary-color)",
                      },
                      display: { xs: "none", sm: "flex" },
                    }}
                  />
                  License
                </Button>
              </TabList>
            </Box>

            <TabPanel value={"flight"} style={{ padding: "0px 0px 0px 0px" }}>
              <FlightSearchBox
                options={options}
                setOptions={setOptions}
                value={value}
                setValue={setValue}
                fromSearchText={fromSearchText}
                setFromSearchText={setFromSearchText}
                toSearchText={toSearchText}
                setToSearchText={setToSearchText}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                returningDate={returningDate}
                setReturningDate={setReturningDate}
                travelDate={travelDate}
                setTravelDate={setTravelDate}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                fromSendData={fromSendData}
                setFromSendData={setFromSendData}
                toSendData={toSendData}
                setToSendData={setToSendData}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                className={className}
                setClassName={setClassName}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
                searchData={searchData}
                setSearchData={setSearchData}
              />
            </TabPanel>
            <TabPanel value={"hotel"} style={{ padding: "0px 0px 0px 0px" }}>
              <Box sx={{ py: 3, textAlign: "center" }}>
                <Typography
                  sx={{ color: "var(--secondary-color)", fontSize: 30, mt: 3 }}
                >
                  Comming Soon
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel value={"holiday"} style={{ padding: "0px 0px 0px 0px" }}>
              <Box sx={{ py: 3, textAlign: "center" }}>
                <Typography
                  sx={{ color: "var(--secondary-color)", fontSize: 30, mt: 3 }}
                >
                  Comming Soon
                </Typography>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
      {/* //todo: Visa Modal */}
      <Modal open={openVisa} onClose={handleCloseVisa}>
        <Box
          sx={{
            ...style,
            width: { xs: "90%", sm: "80%", md: "50%" },
            height: { xs: 400, sm: 400, md: "auto" },
            overflowY: { xs: "scroll", sm: "scroll", md: "auto" },
          }}
        >
          <VisaForm handleCloseVisa={handleCloseVisa} />
        </Box>
      </Modal>
      {/* //todo: License Modal */}
      <Modal open={openLicense} onClose={handleCloseLicense}>
        <Box
          sx={{
            ...style,
            width: { xs: "90%", sm: "80%", md: "50%" },
            height: { xs: 400, sm: 400, md: "auto" },
            overflowY: { xs: "scroll", sm: "scroll", md: "auto" },
          }}
        >
          <License handleCloseLicense={handleCloseLicense} />
        </Box>
      </Modal>
    </Box>
  );
};

export default HomeSearchBox;
