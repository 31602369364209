import { Badge, Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import secureLocalStorage from "react-secure-storage";
import "./TravelCalender.css";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const TravelCalender = ({ setTotalData }) => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date().getDate());
  const [bookingData, setBookingData] = useState([]);
  const [allBookingData, setAllBookingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [recentBookings, setRecentBookings] = useState(null);
  const [thatDayBookings, setThatDayBookings] = useState(
    allBookingData?.current
      ?.filter(
        (item) => new Date(item?.date).getDate() === new Date().getDate()
      )
      ?.map((data) => data?.data) || []
  );

  const handleSelectDate = (date) => {
    setSelectedDate(new Date(date).getDate());
  };

  const { isLoading: loading, refetch } = useQuery(
    "Travel-calendar",
    () =>
      fetch(
        `https://api.flyjatt.com/v1/Queues/index.php?agentId=${agentId}&month=${month}`
      ).then((res) => res.json()),
    {
      onSuccess: (data) => {
        setBookingData(data);
        setAllBookingData(data);
      },
      onError: (err) => {
        console.log(err.message);
      },
    }
  );

  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://api.flyjatt.com/v1/Queues/index.php?agentId=${agentId}&all`)
      .then((res) => res.json())
      .then((data) => {
        setRecentBookings(data);
      });
    //todo: end of fetch all data
  }, [agentId]);

  const sendToQueuesDetails = (agentId, BookingId, tripType, pnr, gds) => {
    navigate("/user/queuesdetail", {
      state: {
        agentId,
        BookingId,
        tripType,
        pnr,
        gds,
        path: "queues",
      },
    });
  };

  return (
    <Box>
      <Grid
        container
        spacing={{ xs: 3, sm: 1 }}
        sx={
          {
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "start",
            // gap: "5px",
            // flexDirection: { md: "row", sm: "column", xs: "column" },
          }
        }
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              Height: { xs: "auto", sm: "40vh", md: "40vh" },
            }}
          >
            <Typography
              sx={{
                width: "100%",
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                color: "var(--primary-color)",
                fontSize: "20px",
                fontWeight: "500",
                my: "5px",
              }}
            >
              <ArrowBackIosIcon
                sx={{ cursor: "pointer" }}
                fontSize="20px"
                onClick={() => {
                  setMonth(
                    moment(month).subtract(1, "month").format("YYYY-MM")
                  );
                  handleRefetch();
                }}
              />
              {moment(month).format("MMM YYYY")}
              <ArrowForwardIosIcon
                sx={{ cursor: "pointer" }}
                fontSize="20px"
                onClick={() => {
                  setMonth(moment(month).add(1, "month").format("YYYY-MM"));
                  handleRefetch();
                }}
              />
            </Typography>
            <Grid container spacing={0.5}>
              {!loading
                ? bookingData?.current?.map((item, index) => (
                    <Grid item md={1.71} key={index}>
                      <Box
                        onClick={() => {
                          handleSelectDate(item?.date);
                          setTotalData(item?.data);
                          setThatDayBookings(item?.data);
                          console.log(item?.data);
                        }}
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow:
                            item?.count !== 0
                              ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                              : 0,
                          borderRadius: "4px",
                          padding: "10px",
                          background: item?.count === 0 ? "#dddd" : "white",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "gray",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {moment(item?.date).format("DD")}
                        </Typography>
                        {item?.count !== 0 ? (
                          <Typography
                            sx={{
                              position: "absolute",
                              top: -5,
                              right: -5,
                              background: "#54B435",
                              color: "white",
                              borderRadius: "50%",
                              height: "20px",
                              width: "20px",
                              textAlign: "center",
                              verticalAlign: "middle",
                              zIndex: 9999,
                              fontSize: "12px",
                            }}
                          >
                            {item?.count}
                          </Typography>
                        ) : null}
                      </Box>
                    </Grid>
                  ))
                : "loading..."}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              // width: "50%",
              Height: { xs: "auto", sm: "35vh", md: "35vh" },
              overflowY: "auto",
            }}
          >
            {thatDayBookings?.length !== 0 ? (
              thatDayBookings?.map((booking, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      background: "var(--tab-bg)",
                      m: "5px",
                      p: "5px 10px",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      sendToQueuesDetails(
                        booking?.AgentId,
                        booking?.BookingId,
                        booking?.TripType,
                        booking?.Pnr,
                        booking?.gdsSystem
                      )
                    }
                  >
                    <Box
                      sx={{
                        width: "80%",
                        display: "flex",
                        flexDirection: "column",
                        ".MuiTypography-root": {
                          textAlign: "start",
                          fontSize: "12px",
                        },
                      }}
                    >
                      <Typography>{booking?.BookingId}</Typography>
                      <Typography>{booking?.Name}</Typography>
                      <Typography>{booking?.Career}</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        ".MuiTypography-root": {
                          textAlign: "end",
                          fontSize: "12px",
                        },
                      }}
                    >
                      <Typography>{booking?.DepFrom}</Typography>
                      <Typography>{booking?.ArrTo}</Typography>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box>
                <Typography
                  sx={{
                    width: "100%",
                    background: "var(--tab-bg)",
                    color: "gray",
                    padding: "5px 0px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                >
                  No Booking Found
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TravelCalender;
