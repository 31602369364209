import {
  Box,
  Button,
  Container,
  createTheme,
  Grid,
  Pagination,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Loader from "../../images/loader/Render.gif";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchHistory.css";
import NoData from "../Shared/NoData/NoData";
import Header from "../Header/Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222222",
      darker: "#222222",
    },
  },
});

const SearchHistory = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  let agentId = user?.user?.agentId;
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [allSearchHistory, setAllSearchHistory] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const size = 25;
  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchHistory(allSearchHistory?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsLoaded(false);
    fetch(
      `https://api.flyjatt.com/v1/SearchHistory/index.php?agentId=${agentId}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length > 0) {
          const count = data?.data?.length;
          setPageCount(Math.ceil(count / size));
          setSearchHistory(data?.data?.slice(0, size));
          setAllSearchHistory(data?.data);
          setIsLoaded(true);
        }
      });
  }, [agentId]);

  //todo: select filter
  const handleChangeSelect = (e) => {
    const option = e.target.value?.replaceAll(/\s/g, "")?.toLowerCase();
    if (option !== "") {
      const count = allSearchHistory.filter(
        (item) =>
          item?.TripType?.replaceAll(/\s/g, "")?.toLowerCase() === option
      ).length;
      setPageCount(Math.ceil(count / size));
      setSearchHistory(
        allSearchHistory
          .filter(
            (item) =>
              item?.TripType?.replaceAll(/\s/g, "")?.toLowerCase() === option
          )
          ?.slice(0, size)
      );
    } else {
      const count = allSearchHistory.length;
      setPageCount(Math.ceil(count / size));
      setSearchHistory(allSearchHistory?.slice?.(0, size));
    }
  };

  if (!isLoaded) {
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "70vh",
        width: "70vw",
        marginInline: "auto",
      }}
    >
      <Box
        style={{
          width: "50%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Loader}
          alt="loader"
          style={{
            width: "100%",
            objectFit: "center",
          }}
        />
      </Box>
    </Box>;
  }

  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              justifyContent: { md: "space-between", sm: "start", xs: "start" },
              alignItems: { md: "center", sm: "start", xs: "start" },
              my: "5px",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "24px",
                fontWeight: "500",
              }}
            >
              Search History
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
                justifyContent: {
                  md: "space-between",
                  sm: "start",
                  xs: "start",
                },
                alignItems: { md: "center", sm: "start", xs: "start" },
                my: "5px",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  background: "var(--primary-color)",
                  height: "35px",
                  borderRadius: "20px",
                  input: {
                    border: "none",
                    background: "none",
                    outline: "none",
                    float: "left",
                    padding: 0,
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "normal",
                    transition: "all 0.4s ease-in-out",
                    lineHeight: "35px",
                    width: "0px",
                  },
                  "input::placeholder": { color: "white" },
                  "&:hover input": {
                    width: { xs: "180px", sm: "235px" },
                    padding: "0 10px",
                  },
                  "&:hover button": {
                    background: "white",
                    color: "black",
                  },
                  button: {
                    border: "none",
                    color: "white",
                    float: "right",
                    minHeight: "0px",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    background: "var(--primary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.4s ease-in-out",
                    cursor: "pointer",
                    textDecoration: "none",
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Search....."
                  onChange={(e) => {
                    let searchInput = e.target.value;
                    if (searchInput !== "") {
                      const filterData = allSearchHistory.filter((item) => {
                        return Object.values(item)
                          .join("")
                          .toLowerCase()
                          .includes(searchInput.toLowerCase());
                      });
                      setPageCount(Math.ceil(filterData?.length / size));
                      setSearchHistory(filterData?.slice(0, size));
                    } else if (searchInput === "") {
                      setPageCount(Math.ceil(allSearchHistory?.length / size));
                      setSearchHistory(allSearchHistory?.slice(0, size));
                    }
                  }}
                />
                <button>
                  <SearchIcon />
                </button>
              </Box>

              <select
                onChange={handleChangeSelect}
                style={{
                  width: "150px",
                  borderRadius: "4px",
                  height: "100%",
                  padding: "5px 10px",
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  borderColor: "var(--secondary-color)",
                }}
              >
                <option value="">Show All</option>
                <option value="oneway">Oneway</option>
                <option value="return">Return</option>
                <option value="multicity">Multi City</option>
              </select>
            </Box>
          </Box>
          {Object.keys(searchHistory).length !== 0 ? (
            <Box>
              <Box
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "0px",
                    background: "var(--secondary-color)",
                  },
                  height: "calc(100vh - 230px)",
                  overflowY: "scroll",
                  table: {
                    width: "100%",
                    position: "relative",
                    borderCollapse: "collapse",
                  },
                  th: {
                    position: "sticky",
                    top: 0,
                    zIndex: "999",
                    background: "var(--primary-color)",
                    color: "white",
                    border: "1px solid #ddd",
                    padding: "8px 12px",
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                  td: {
                    textAlign: "center",
                    verticalAlign: "middle",
                    border: "1px solid #ddd !important",
                    padding: "5px !important",
                    fontSize: "12px",
                  },
                  "tr:nth-child(even)": {
                    background: "#f2f2f2",
                  },
                  "tr:hover": {
                    background: "#ddd",
                  },
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Search Date</th>
                      <th>Type</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Departure date</th>
                      <th>Arrival Date </th>
                      <th>Search By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchHistory?.map((search, index) => (
                      <tr key={index}>
                        <td>
                          {search?.created_at
                            ? format(
                                new Date(search?.created_at),
                                "dd MMM yy hh:mm a"
                              )
                            : "Search Date"}
                        </td>
                        <td>
                          {search?.TripType?.toUpperCase() || "Trip Type"}
                        </td>
                        <td>{search?.DepFrom || "From"}</td>
                        <td>{search?.ArrTo || "To"}</td>
                        <td>{search?.GoDate || "Departure Date"}</td>
                        <td>{search?.BackDate || "One Way"}</td>
                        <td>{search?.searchBy || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  py: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {/* <Typography style={{ fontSize: "15px", color: "#222222" }}>
                  {`Showing Results ${(page - 1) * size} - ${
                    searchHistory?.length
                  } of ${allSearchHistory?.length}`}
                </Typography> */}
                <ThemeProvider theme={theme}>
                  <Stack spacing={2}>
                    <Pagination
                      size="small"
                      count={pageCount}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>
                </ThemeProvider>
              </Box>
            </Box>
          ) : (
            <>
              <NoData />
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default SearchHistory;
