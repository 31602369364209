import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Success from "../../images/undraw/undraw_completed_tasks_vs6q.svg";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import Delete from "../../images/undraw/undraw_throw_away_re_x60k.svg";
import Loader from "../../images/loader/Render.gif";
import CircleIcon from "@mui/icons-material/Circle";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { format } from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vh",
  height: "fit-content",
  background: "white",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const Staff = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  let agentId = user?.user?.agentId;
  const [isLoading, setIsLoading] = useState(false);
  const [reFetch, setRefetch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allStaff, setAllStaff] = useState([]);
  const [staff, setStaff] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (data) => {
    setFormInfo({
      staffId: data.staffId,
      Name: data?.name,
      Email: data?.email,
      Designation: data?.designation,
      Phone: data?.phone,
      Role: data?.role,
      Password: data?.password,
      Status: data?.status,
    });
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://api.flyjatt.com/v1/Staff/index.php?agentId=${agentId}`)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setAllStaff(data);
        setStaff(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.message);
        Swal.fire({
          icon: "error",
          title: "Failed to fetch",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/user/dashboard");
        });
      });
  }, [allStaff?.length, agentId, navigate, reFetch]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let url = "https://api.flyjatt.com/v1/Staff/index.php?edit";
    let body = JSON.stringify(formInfo);
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setIsSubmitting(false);
          handleCloseModal();
          e.target.reset();
          Swal.fire({
            imageUrl: Success,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Staff Edit successfully",
            html: `For any query.Please contact us at <strong> contact@ticketfactory.sa</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate("/user/staff");
          });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        handleCloseModal();
        e.target.reset();
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: err.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Again",
        }).then(() => {
          navigate("/user/staff");
        });
      });
    e.target.reset();
  };

  //todo: delete Staff functionality
  const deleteStaff = (staffId) => {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You Wants to Delete this Staff ?",
      showCancelButton: true,
      confirmButtonColor: "var(--secondary-color)",
      confirmButtonText: "Delete",
      cancelButtonColor: "var(--primary-color)",
      cancelButtonText: "Cancel",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let url = `https://api.flyjatt.com/v1/Staff/index.php?delete`;
        let body = JSON.stringify({
          staffId,
        });
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              Swal.fire({
                imageUrl: Delete,
                imageWidth: 400,
                imageHeight: 200,
                title: "Your Stuff is Deleted!",
                html: `For any query.Please contact us at <strong> contact@ticketfactory.sa</strong> or Call <strong> +971509517784 </strong>`,
                confirmButtonText: "OK",
                confirmButtonColor: "var(--primary-color)",
              }).then(() => {
                navigate("/user/staff");
                setRefetch((prev) => !prev);
              });
            } else {
              throw new Error("error");
            }
          })
          .catch((err) => {
            console.log(err.message);
            Swal.fire({
              imageUrl: ServerDown,
              imageWidth: 400,
              imageHeight: 200,
              title: "Failed! To Delete",
              html: `For any query.Please contact us at <strong> contact@ticketfactory.sa</strong> `,
              confirmButtonText: "Try Again",
              cancelButtonColor: "var(--primary-color)",
            }).then(() => {
              navigate("/user/staff");
            });
          });
      }
    });
  };

  const handleStatus = (data) => {
    let url = `https://api.flyjatt.com/v1/Staff/index.php?status`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        staffId: data?.staffId,
        status: data?.status === "Active" ? "Deactive" : "Active",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setRefetch((prev) => !prev);
        }
      })
      .catch((err) => {
        setRefetch((prev) => !prev);
        console.log(err.message);
      });
  };

  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allStaff.filter((item) => {
      return (
        new Date(item?.created).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created).getDate() <= new Date(endDate).getDate()
      );
    });
    setStaff(filterData);
  };

  if (isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "5px",
            }}
          >
            <Typography
              sx={{ color: "var(--secondary-color)", fontSize: "24px" }}
            >
              My Staffs
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "end",
                alignItem: "center",
              }}
            >
              <Box
                onClick={() => navigate("/user/addstaff")}
                sx={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  padding: "5px 10px",
                  background: "var(--secondary-color)",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  fontSize: "14px",
                }}
              >
                Add Staff
              </Box>
            </Box>
          </Box>
          <Box sx={{ mb: "5px" }}>
            <form onSubmit={handleFilter}>
              <Grid
                container
                spacing={1}
                alignItems="end"
                justifyContent="end"
                sx={{
                  zIndex: "9999",
                  input: {
                    width: "100%",
                    padding: "5px 10px",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "5px",
                    cursor: "pointer",
                    outline: "none",
                  },
                }}
              >
                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box>
                    <input
                      title="Enter Start Date"
                      required
                      id="startDate"
                      name="startDate"
                      type="text"
                      value={startDate}
                      readOnly
                      onClick={() => {
                        setOpenEndDate(false);
                        setOpenStartDate((prev) => !prev);
                      }}
                    />
                  </Box>
                  {openStartDate && (
                    <Calendar
                      color={"var(--primary-color)"}
                      date={new Date(startDate)}
                      onChange={(date) => {
                        setStartDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenStartDate(false);
                      }}
                      maxDate={new Date()}
                      months={1}
                      className="new-dashboard-calendar"
                      name="dashboard-calendar"
                    />
                  )}
                </Grid>

                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box>
                    <input
                      title="Enter End Date"
                      required
                      id="endDate"
                      name="endDate"
                      type="text"
                      value={endDate}
                      readOnly
                      onClick={() => {
                        setOpenEndDate((prev) => !prev);
                        setOpenStartDate(false);
                      }}
                    />
                  </Box>
                  {openEndDate && (
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(endDate)}
                      onChange={(date) => {
                        setEndDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenEndDate(false);
                      }}
                      months={1}
                      className="new-dashboard-calendar"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "5px",
                  }}
                >
                  <Button
                    title="Click to Filter"
                    sx={{
                      background: "var(--secondary-color)",
                      color: "white",
                      padding: "5px 20px !important",
                      fontSize: "12px !important",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    FILTER
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "5px",
                  }}
                >
                  <Button
                    title="Click to Reset"
                    sx={{
                      background: "var(--secondary-color)",
                      color: "white",
                      padding: "5px 20px !important",
                      fontSize: "12px !important",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                    onClick={() => setStaff(allStaff)}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Box
            className="table-wrapper"
            sx={{
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "var(--secondary-color)",
                borderRadius: "0px",
              },
              height: "calc(100vh - 220px)",
              overflowY: "scroll",
            }}
          >
            <table className="responsive-table">
              <thead>
                <tr>
                  <th width="15%">Name</th>
                  <th width="15%">Email</th>
                  <th width="15%">Designation</th>
                  <th width="10%">Role</th>
                  <th width="15%">Phone</th>
                  <th width="15%">Status</th>
                  <th width="15%">Action</th>
                </tr>
              </thead>

              <tbody>
                {staff
                  ? staff?.map((data) => (
                      <tr>
                        <td>{data?.name || "Name"}</td>
                        <td>{data?.email || "Email"}</td>
                        <td>{data?.designation || "Designation"}</td>
                        <td>{data?.role || "Role"}</td>
                        <td>{data?.phone || "Phone"}</td>
                        <td>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              color:
                                data?.status === "Active" ? "green" : "red",
                            }}
                          >
                            <CircleIcon sx={{ fontSize: "10px" }} />
                            {data?.status || "Status"}
                          </Typography>
                        </td>
                        <td>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title={"Edit Staff"}>
                              <EditIcon onClick={() => handleOpenModal(data)} />
                            </Tooltip>
                            <Tooltip title={"Delete Staff"}>
                              <DeleteForeverIcon
                                onClick={() => deleteStaff(data?.staffId)}
                              />
                            </Tooltip>
                            <Tooltip
                              title={
                                data?.status === "Active"
                                  ? "DeActive"
                                  : "Active"
                              }
                            >
                              <Switch
                                checked={
                                  data?.status === "Active" ? true : false
                                }
                                onChange={() => handleStatus(data)}
                              />
                            </Tooltip>
                          </Box>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </Box>
        </Box>
      </Container>
      {/* //todo Update staff modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                mb: "5px",
              }}
            >
              <Typography
                sx={{ color: "var(--secondary-color)", fontSize: "24px" }}
              >
                Update Staff
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  label: {
                    fontSize: "14px",
                    color: "var(--secondary-color)",
                    fontWeight: "500",
                  },
                  input: {
                    outline: "none",
                    width: "100%",
                    border: "1px solid var(--secondary-color)",
                    color: "var(secondary-color)",
                    fontSize: "14px",
                    borderRadius: "4px",
                    padding: "5px 10px",
                  },
                  select: {
                    outline: "none",
                    width: "100%",
                    border: "1px solid var(--secondary-color)",
                    color: "var(secondary-color)",
                    fontSize: "14px",
                    borderRadius: "4px",
                    padding: "5px 10px",
                  },
                  ".MuiTypography-root": {
                    fontSize: "14px ",
                    color: "var(--primary-color)",
                    textDecoration: "underline",
                  },
                }}
              >
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Typography>Staff Information</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <label htmlFor="Name">
                      <span style={{ color: "red" }}>*</span>Staff Name
                    </label>
                    <input
                      required
                      id="Name"
                      name="Name"
                      type="text"
                      value={formInfo?.Name}
                      placeholder="Staff Name"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <label htmlFor="Phone">
                      <span style={{ color: "red" }}>*</span>Staff Phone
                      <span style={{ color: "red", fontSize: "10px" }}>
                        (enter valid number)
                      </span>
                    </label>
                    <input
                      required
                      id="Phone"
                      name="Phone"
                      placeholder="Phone Number"
                      value={formInfo?.Phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <label htmlFor="Designation">
                      <span style={{ color: "red" }}>*</span>Staff Designation
                    </label>
                    <input
                      required
                      id="Designation"
                      name="Designation"
                      type="text"
                      placeholder="Designation "
                      value={formInfo?.Designation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Typography>Login Information</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <label htmlFor="Email">
                      <span style={{ color: "red" }}>*</span>Email
                    </label>
                    <input
                      required
                      autoComplete="off"
                      id="Email"
                      name="Email"
                      type="email"
                      placeholder="Your Email"
                      value={formInfo?.Email}
                      readOnly
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <label htmlFor="Password">
                      <span style={{ color: "red" }}>*</span>Password
                    </label>
                    <Box position={"relative"}>
                      <input
                        required
                        id="Password"
                        name="Password"
                        type={showPassword ? "text" : "password"}
                        placeholder=" Enter Your Password "
                        value={formInfo?.Password}
                        onChange={handleChange}
                      />

                      {showPassword ? (
                        <Visibility
                          onClick={() => setShowPassword((prev) => !prev)}
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() => setShowPassword((prev) => !prev)}
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <label htmlFor="Role">
                      <span style={{ color: "red" }}>*</span>Select Role
                    </label>
                    <select
                      required
                      id="Role"
                      name="Role"
                      value={formInfo?.Role}
                      onChange={handleChange}
                    >
                      <option value="">Select Role</option>
                      <option value="staff">Staff</option>
                    </select>
                  </Grid>
                  <Grid container item justifyContent={"start"}>
                    <Button
                      variant="contained"
                      style={{
                        color: "#fff",
                        backgroundColor: "var(--primary-color)",
                        outline: "none",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        padding: "5px 20px",
                        fontSize: "14px",
                      }}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Staff;
