/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import { format } from "date-fns";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./SingleFlight.css";
import Transit from "./Transit";

const SingleFlight = ({
  flightData,
  adultCount,
  childCount,
  infant,
  to,
  from,
  tripType,
  fromAddress,
  toAddress,
  dDate,
  agentFarePrice,
  setAgentFarePrice,
  commisionFarePrice,
  setCommisionFarePrice,
  customerFare,
  setCustomerFare,
}) => {
  const [value, setValue] = useState("1");
  const [flightDetails, setFlightDetails] = useState(false);
  const [toggledrawer, setToggledrawer] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const [changeStateSession, setChangeStateSession] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const clientPrice = parseInt(
    flightData.system !== "Galileo" ? flightData.clientPrice : flightData.price
  );
  // const percentRate = parseInt(commissionData.defaultCommissionRate) / 100;
  const clientFare = Math.round(clientPrice);
  const agentFare = Math.round(
    parseInt(
      flightData.system !== "Galileo" ? flightData.price : flightData.BasePrice
    )
  );
  const commission = Math.round(clientFare - agentFare);

  const navigate = useNavigate();
  const FlightInformation = () => {
    flightData?.system === "Sabre"
      ? navigate("/user/flightinformation", {
          state: {
            flightData,
            adultCount,
            childCount,
            infant,
            to,
            from,
            tripType,
            fromAddress,
            toAddress,
            dDate,
            clientFare,
            agentFare,
            commission,
          },
        })
      : flightData.system === "Galileo"
      ? navigate("/user/flightinformation", {
          state: {
            flightData,
            adultCount,
            childCount,
            infant,
            to,
            from,
            tripType,
            fromAddress,
            toAddress,
            dDate,
            clientFare,
            agentFare,
            commission,
          },
        })
      : navigate("/user/flightinformation", {
          state: {
            flightData,
            adultCount,
            childCount,
            infant,
            to,
            from,
            tripType,
            fromAddress,
            toAddress,
            dDate,
            clientFare,
            agentFare,
            commission,
          },
        });
  };

  let calParcent = (num, percentage) => {
    const result = num * (percentage / 100);
    return parseFloat(result.toFixed(0));
  };
  let percntVal = calParcent(parseInt(flightData.price), 7);

  const offerPrice = parseInt(flightData.price) + parseInt(percntVal);
  const PaxCount = adultCount + childCount + infant;
  let count = [];
  for (let i = 0; i < PaxCount; i++) {
    count.push(i);
  }

  // ----   --------Copy form ALL.js end----------
  return (
    <Box mb={2.5}>
      <Grid
        container
        sx={{
          boxShadow:
            "-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)",
          borderRadius: "10px",
        }}
      >
        <Grid item md={10} p={2}>
          <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid item xs={6} sm={2.5} md={2.5}>
              <Box>
                <img
                  src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flightData.segments[0]?.marketingcareer}.png`}
                  className={`${flightData?.system?.toLowerCase()}`}
                  alt={`${flightData.career}`}
                  width="40px"
                  height="40px"
                />
                <Tooltip
                  title={`${flightData?.segments[0]?.marketingcareerName}`}
                >
                  <Typography
                    fontSize={{ xs: "10px", md: "13px" }}
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      color: "var(--secondary-color)",
                    }}
                    noWrap
                  >
                    {`${flightData?.segments[0]?.marketingcareerName}`}
                  </Typography>
                </Tooltip>
                <Typography>
                  {flightData?.refundable === "Refundable" ? (
                    <Typography
                      sx={{
                        mt: { xs: 0, sm: 1 },
                        color: "var(--gray)",
                        fontSize: { xs: "10px", md: "12px" },
                        fontWeight: 500,
                      }}
                    >
                      Refundable
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        mt: 1,
                        color: "var(--gray)",
                        fontSize: { xs: "10px", md: "12px" },
                        fontWeight: 500,
                      }}
                    >
                      Non Refundable
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3.5} md={3.5}>
              <Box>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontWeight: 500,
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "15px",
                      lg: "22px",
                    },
                  }}
                >
                  {flightData?.departureTime}{" "}
                  <span style={{ color: "var(--secondary-color)" }}>
                    {flightData?.segments[0]?.departureLocation?.split(" ,")[0]}
                  </span>
                </Typography>

                <Tooltip
                  title={`${flightData?.segments[0]?.departureAirport}${", "}${
                    flightData?.departure
                  }`}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      color: "var(--secondary-color)",
                      fontWeight: 400,
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                      },
                    }}
                    noWrap
                  >
                    {flightData?.segments[0]?.departureAirport}
                    {", "}
                    {flightData.departure}
                  </Typography>
                </Tooltip>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontWeight: 400,
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                    },
                  }}
                >
                  {flightData?.departureDate}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={2.5} md={2.5} mt={{ xs: 1.5, sm: "0" }}>
              <Transit flightData={flightData} />
            </Grid>
            <Grid
              item
              xs={6}
              sm={3.5}
              md={3.5}
              // textAlign={{ xs: "left", sm: "left" }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontWeight: 500,
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "15px",
                      lg: "22px",
                    },
                  }}
                >
                  {flightData?.arrivalTime}{" "}
                  <span style={{ color: "var(--secondary-color)" }}>
                    {
                      flightData?.segments[
                        flightData?.segments?.length - 1
                      ]?.arrivalLocation?.split(" ,")[0]
                    }
                  </span>
                </Typography>

                <Tooltip
                  title={`${
                    flightData?.segments[flightData?.segments?.length - 1]
                      ?.arrivalAirport
                  }${", "}${
                    flightData?.segments[flightData?.segments?.length - 1]
                      ?.arrival
                  }`}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      color: "var(--secondary-color)",
                      fontWeight: 400,
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                      },
                    }}
                    noWrap
                  >
                    {
                      flightData?.segments[flightData?.segments?.length - 1]
                        ?.arrivalAirport
                    }
                    {", "}
                    {
                      flightData?.segments[flightData?.segments?.length - 1]
                        ?.arrival
                    }
                  </Typography>
                </Tooltip>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontWeight: 400,
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                    },
                  }}
                >
                  {flightData?.arrivalDate}
                </Typography>
                <Typography
                  sx={{
                    color: "var(--gray)",
                    fontWeight: 400,
                    mt: { xs: "0", sm: 2 },
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                    },
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  Baggage:{" "}
                  {parseInt(flightData.bags) > 4 ? (
                    <>{flightData?.bags?.slice(0, 2) || 0} Kg</>
                  ) : flightData.bags === "2 N" ? (
                    <>2 N</>
                  ) : flightData.bags === "2 P" ? (
                    <>2 P</>
                  ) : parseInt(flightData.bags) < 4 ? (
                    <>{parseInt(flightData.bags)} Piece</>
                  ) : parseInt(flightData.bags) === "NaN" ? (
                    <>{flightData.bags}</>
                  ) : (
                    <>0 Kg</>
                  )}
                  {" || "}
                  Seats: {flightData?.seat || 9}
                </Typography>
                <Typography
                  sx={{
                    color: "var(--gray)",
                    fontWeight: 400,
                    mt: { xs: "0", sm: 2 },
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                    },
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  Seats: {flightData?.seat || 9}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
     
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          pt={{ xs: 0, sm: 2 }}
          pb={{ xs: 1.5, sm: 2 }}
          pr={2}
          textAlign={{ xs: "center", sm: "right" }}
        >
          <Box textAlign="end" mr={{ xs: "5px", sm: "0px" }}>
            <Typography
              sx={{
                fontSize: "18px",
                color: "var(--secondary-color)",
                fontWeight: 500,
                display: { xs: "none", sm: "block" },
              }}
            >
              SAR{" "}
              {commaNumber(
                flightData.TotalWithMarkUp?.toFixed(2) || flightData?.TotalFare
              )}
            </Typography>
          </Box>

          <Box
            textAlign="end"
            mt={{ xs: 0, sm: 1 }}
            px={{ xs: 2, sm: "0" }}
            display={{ xs: "flex", sm: "block" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "" }}
          >
            <Button
              size="small"
              // className="shine-effect"
              style={{
                color: "var(--white)",
                fontWeight: 500,
                backgroundColor: "var(--primary-color)",
                borderRadius: "5px",
              }}
              padding={{ xs: "7px 20px", sm: "" }}
              height={{ xs: "40px", sm: "" }}
              // disabled
              disabled={flightData?.system === "Galileo" ? true : false}
              onClick={FlightInformation}
            >
              BOOK NOW
            </Button>
            {["right"].map((anchor) => (
              <Box key={anchor}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "var(--secondary-color)",
                    fontWeight: 500,
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  SAR{" "}
                  {commaNumber(
                    flightData.TotalWithMarkUp?.toFixed(2) ||
                      flightData?.TotalFare
                  )}
                </Typography>
                <Button
                  size="small"
                  onClick={toggleDrawer(anchor, true)}
                  sx={{
                    color: "var(--primary-color)",
                    fontWeight: 500,
                    fontSize: "11px",
                    padding: 0,
                    marginTop: { xs: 0, sm: "5px" },
                  }}
                >
                  Flight Details
                  <PlayArrowIcon style={{ padding: "0", fontSize: "18px" }} />
                </Button>
                {/* ----new start flight details */}
                <SwipeableDrawer
                  style={{ margin: "0px", padding: "0px" }}
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  <Box
                    bgcolor="var(--drawer-bgcolor)"
                    sx={{ width: { xs: "85vw", sm: "70vw", md: "50vw" } }}
                  >
                    <Box>
                      <Box margin={{ xs: "15px", md: "2vw 0 2vw 3vw" }}>
                        <Typography
                          sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                          color="var(--secondary-color)"
                          fontWeight={500}
                        >
                          {
                            flightData?.segments[0]?.departureLocation?.split(
                              " ,"
                            )[0]
                          }
                          ({flightData?.departure}){" - "}
                          {
                            flightData?.segments[
                              flightData?.segments?.length - 1
                            ]?.arrivalLocation?.split(" ,")[0]
                          }
                          ({flightData?.arrival})
                        </Typography>
                        <Typography fontSize={{ xs: 10, sm: 14 }}>
                          Oneway Flight <span>|</span>{" "}
                          {adultCount > 0 && `Adult(${adultCount})`}
                          {childCount > 0 && `Children(${childCount})`}
                          {infant > 0 && `Infant(${infant})`} <span>|</span>{" "}
                          {format(
                            new Date(flightData?.departureDate),
                            "dd MMM yyyy"
                          )}
                          {" | "}
                          {format(
                            new Date(flightData?.arrivalDate),
                            "dd MMM yyyy"
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Flight Details
                        </Typography>
                        <Box
                          bgcolor="var(--white)"
                          margin={{ xs: "15px", md: "2vw 3vw" }}
                          padding={{ xs: "7px 10px", sm: "8px 20px" }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            borderBottom="2px solid var(--primary-color)"
                          >
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "var(--secondary-color)",
                                  fontSize: { xs: 12, sm: 14 },
                                  fontWeight: 500,
                                }}
                              >
                                Depart:{" "}
                                <span
                                  style={{
                                    color: "var(--primary-color)",
                                  }}
                                >
                                  {flightData?.departureDate}
                                </span>{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: { xs: 12, sm: 14 },
                                }}
                              >
                                {flightData?.segment === 1 ? (
                                  <>Non</>
                                ) : (
                                  <>{flightData?.segment - 1} </>
                                )}
                                Stop {flightData?.flightduration}{" "}
                                <span
                                  style={{
                                    color: "var(--gray)",
                                  }}
                                >
                                  {flightData?.segments?.map((data) => (
                                    <>
                                      {flightData?.system === "Sabre" ? (
                                        data?.marketingcareer !==
                                        data?.operatingcareer ? (
                                          <>
                                            Operated By:{" "}
                                            {data?.operatingCarrierName}
                                          </>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: { xs: 12, sm: 14 },
                                  color: "var(--primary-color)",
                                }}
                              >
                                {
                                  flightData?.segments[0]?.departureLocation?.split(
                                    ","
                                  )[0]
                                }
                                {" - "}
                                {
                                  flightData?.segments[
                                    flightData?.segments?.length - 1
                                  ]?.arrivalLocation?.split(",")[0]
                                }{" "}
                              </Typography>
                            </Grid>
                          </Grid>

                          {flightData?.segments.map((data, i, arr) => (
                            <Box my={2}>
                              <Grid container spacing={{ xs: 1, sm: 2 }}>
                                <Grid item xs={5} sm={6} md={2.5}>
                                  <Box>
                                    <Box
                                      sx={{
                                        width: {
                                          xs: "30px",
                                          sm: "40px",
                                          md: "50px",
                                        },
                                        height: {
                                          xs: "30px",
                                          sm: "40px",
                                          md: "50px",
                                        },
                                      }}
                                    >
                                      <img
                                        src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.marketingcareer}.png`}
                                        alt="flight logo"
                                        width="100%"
                                        height="100%"
                                      />
                                    </Box>
                                    <Typography
                                      sx={{
                                        color: "var(--primary-color)",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                      }}
                                      noWrap
                                    >
                                      {data?.marketingcareerName}
                                      <br />
                                      <span
                                        style={{
                                          color: "var(--fontcolor)",
                                        }}
                                      >
                                        {data?.marketingcareer}{" "}
                                        {data?.marketingflight} &{" "}
                                        {data?.bookingcode}
                                      </span>
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={7} sm={6} md={3.5}>
                                  <Typography fontSize={{ xs: 13, sm: 20 }}>
                                    <span
                                      style={{
                                        color: "var(--primary-color)",
                                      }}
                                    >
                                      {data?.departureLocation?.split(" ,")[0]},{" "}
                                    </span>
                                    <span
                                      style={{
                                        color: "var(--primary-color)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {data?.departure}
                                    </span>
                                    <br />
                                    <Tooltip
                                      title={`${data?.departureAirport}`}
                                    >
                                      <Typography
                                        style={{
                                          color: "var(--secondary-color)",
                                          fontSize: { xs: 12, sm: 13 },
                                        }}
                                        noWrap
                                      >
                                        {data?.departureAirport}
                                      </Typography>
                                    </Tooltip>

                                    <span
                                      style={{
                                        color: "var(--fontcolor)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {format(
                                        new Date(
                                          data?.departureTime?.toString()
                                        ),
                                        "dd MMM yyyy hh:mm a"
                                      )}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} sm={6} md={2.5} margin="auto">
                                  <Box textAlign="center">
                                    <Typography
                                      sx={{
                                        color: "var(--primary-color)",
                                        fontWeight: 500,
                                        fontSize: {
                                          xs: "10px",
                                          md: "12px",
                                        },
                                      }}
                                    >
                                      {data?.flightduration}
                                    </Typography>
                                    <Box className="stop-bar-parent">
                                      <CircleIcon
                                        sx={{
                                          color: "var(--transit)",
                                          fontSize: "15px",
                                        }}
                                      />
                                      <Box className="stop-bar-line-details"></Box>
                                      <CircleIcon
                                        sx={{
                                          color: "var(--transit)",
                                          fontSize: "15px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={7} sm={6} md={3.5}>
                                  <Typography fontSize={{ xs: 13, sm: 20 }}>
                                    <span
                                      style={{
                                        color: "var(--primary-color)",
                                      }}
                                    >
                                      {data?.arrivalLocation?.split(" ,")[0]},{" "}
                                    </span>
                                    <span
                                      style={{
                                        color: "var(--primary-color)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {data?.arrival}
                                    </span>
                                    <br />

                                    <Tooltip title={`${data?.arrivalAirport}`}>
                                      <Typography
                                        style={{
                                          color: "var(--secondary-color)",
                                          fontSize: "12px",
                                        }}
                                        noWrap
                                      >
                                        {data?.arrivalAirport}
                                      </Typography>
                                    </Tooltip>

                                    <span
                                      style={{
                                        color: "var(--fontcolor)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {format(
                                        new Date(data?.arrivalTime?.toString()),
                                        "dd MMM yyyy hh:mm a"
                                      )}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Baggage
                        </Typography>
                        <Box
                          bgcolor="var(--white)"
                          margin={{ xs: "15px", md: "2vw 3vw" }}
                          padding={{ xs: "7px 10px", sm: "8px 20px" }}
                        >
                          <Box className="flight-search-table">
                            <table
                              style={{
                                borderCollapse: "collapse",
                                width: "100%",
                              }}
                            >
                              <tr>
                                <th>Baggage</th>
                                <th>Check-In</th>
                                <th>Cabin</th>
                              </tr>

                              <tr>
                                <td>Adult</td>
                                <td>
                                  {flightData?.bags === "3" ||
                                  flightData?.bags === "2" ||
                                  flightData?.bags === "1" ? (
                                    <>{flightData?.bags?.split(" ")[0]} Piece</>
                                  ) : flightData?.bags === " " ? (
                                    <>0 Kg</>
                                  ) : (
                                    <>{flightData?.bags?.slice(0, 2) || 0} Kg</>
                                  )}
                                </td>
                                <td>7 Kg</td>
                              </tr>
                              {childCount > 0 && (
                                <tr>
                                  <td>Child</td>
                                  <td>
                                    {flightData?.bags === "3" ||
                                    flightData?.bags === "2" ||
                                    flightData?.bags === "1" ? (
                                      <>
                                        {flightData?.bags?.split(" ")[0]} Piece
                                      </>
                                    ) : flightData?.bags === " " ? (
                                      <>0 Kg</>
                                    ) : flightData?.bags.length === 6 ? (
                                      <>
                                        {flightData?.bags?.slice(2, 4) || 0} Kg{" "}
                                      </>
                                    ) : (
                                      <>
                                        {flightData?.bags?.slice(0, 2) || 0} Kg
                                      </>
                                    )}
                                  </td>
                                  <td>7 Kg</td>
                                </tr>
                              )}
                              {infant > 0 && (
                                <tr>
                                  <td>Infant</td>
                                  <td>
                                    {flightData?.bags === "3" ||
                                    flightData?.bags === "2" ||
                                    flightData?.bags === "1" ? (
                                      <>
                                        {flightData?.bags?.split(" ")[0]} Piece
                                      </>
                                    ) : flightData?.bags === " " ? (
                                      <>0 Kg</>
                                    ) : flightData?.bags.length === 6 ? (
                                      <>
                                        {flightData?.bags?.slice(4, 6) || 0} Kg{" "}
                                      </>
                                    ) : (
                                      <>
                                        {flightData?.bags?.slice(0, 2) || 0} Kg
                                      </>
                                    )}
                                  </td>
                                  <td>7 Kg</td>
                                </tr>
                              )}
                            </table>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Price Breakdown
                        </Typography>
                        <Box
                          bgcolor="var(--white)"
                          margin={{ xs: "15px", md: "2vw 3vw" }}
                          padding={{ xs: "7px 10px", sm: "8px 20px" }}
                        >
                          <Grid container className="flight-search-table">
                            <Grid item xs={12}>
                              <table
                                style={{
                                  borderCollapse: "collapse",
                                  width: "100%",
                                }}
                              >
                                <tr>
                                  <th width="10%">Pax&nbsp;Type</th>
                                  <th width="20%">Base&nbsp;Fare</th>
                                  <th width="20%">Tax&nbsp;+&nbsp;Fees</th>
                                  <th width="30%">Per&nbsp;Passenger</th>
                                  <th width="20%">Total&nbsp;Cost</th>
                                </tr>
                                {flightData?.pricebreakdown.map((data) => (
                                  <tr>
                                    <td>
                                      {data.PaxType === "ADT"
                                        ? "Adult"
                                        : data.PaxType === "CNN"
                                        ? "Child"
                                        : "Infant"}
                                    </td>
                                    <td>
                                      SAR&nbsp;{commaNumber(data.BaseFare)}
                                    </td>
                                    <td>
                                      SAR&nbsp;
                                      {commaNumber(
                                        parseInt(data.Tax) +
                                          parseInt(data.ServiceFee)
                                      )}
                                    </td>

                                    <td>
                                      SAR&nbsp;({commaNumber(data.BaseFare)}
                                      &nbsp;
                                      {"*"}&nbsp;{data.PaxCount})
                                    </td>

                                    <td>
                                      SAR&nbsp;
                                      {commaNumber(
                                        (parseInt(data?.BaseFare) +
                                          parseInt(data?.Tax) +
                                          parseInt(data?.ServiceFee)) *
                                          parseInt(data?.PaxCount)
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            justifyContent="space-between"
                            padding="20px"
                          >
                            <Grid item>
                              <Typography
                                mb="5px"
                                sx={{
                                  color: "var(--black)",
                                  fontSize: "12px",
                                }}
                              >
                                Total (
                                {flightData?.pricebreakdown.length === 3 ? (
                                  <>
                                    {parseInt(
                                      flightData?.pricebreakdown[0].PaxCount
                                    ) +
                                      parseInt(
                                        flightData?.pricebreakdown[1].PaxCount
                                      ) +
                                      parseInt(
                                        flightData?.pricebreakdown[2].PaxCount
                                      )}
                                  </>
                                ) : flightData?.pricebreakdown.length === 2 ? (
                                  <>
                                    {parseInt(
                                      flightData?.pricebreakdown[0].PaxCount
                                    ) +
                                      parseInt(
                                        flightData?.pricebreakdown[1].PaxCount
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {parseInt(
                                      flightData?.pricebreakdown[0].PaxCount
                                    )}
                                  </>
                                )}{" "}
                                Traveler)
                              </Typography>
                              <Typography
                                sx={{
                                  color: "var(--total-text-color)",
                                  fontSize: "12px",
                                }}
                              >
                                Commission & Your Saving{" "}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "var(--black)",
                                  fontSize: "12px",
                                }}
                              >
                                SAR {flightData.clientPrice}
                              </Typography>

                              <Typography
                                sx={{
                                  color: "var(--total-text-color)",
                                  fontSize: "12px",
                                }}
                              >
                                SAR {flightData.comission}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            bgcolor="var(--primary-color)"
                            padding={{ xs: "7px 10px", sm: "8px 20px" }}
                          >
                            <Typography color="var(--white)" fontSize="13px">
                              Total Payable
                            </Typography>
                            <Typography color="var(--white)" fontSize="13px">
                              SAR {flightData.price}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            backgroundColor: "var(--primary-color)",
                            padding: "4px 0 4px 3vw",
                            color: "var(--white)",
                            fontWeight: 500,
                            width: { xs: "100%", sm: "30%", md: "25%" },
                          }}
                        >
                          Fare Policy
                        </Typography>
                        <Box
                          bgcolor="var(--white)"
                          margin={{ xs: "15px", md: "2vw 3vw" }}
                          padding={{ xs: "7px 10px", sm: "8px 20px" }}
                        >
                          <Typography
                            sx={{
                              color: "var(--fontcolor)",
                              fontSize: "12px",
                              fontWeight: 500,
                              padding: { xs: "5px", sm: "20px" },
                            }}
                          >
                            Pay attention to the following notifications in the
                            CANCELLATIONS section:
                            <br />
                            <br />
                            TICKET IS NON-REFUNDABLE — the ticket is
                            non-refundable;
                            <br />
                            TICKET IS NON-REFUNDABLE FOR CANCEL/REFUND — the
                            ticket is non-refundable;
                            <br />
                            REFUND IS NOT PERMITTED — the ticket is
                            non-refundable;
                            <br />
                            ANY TIME TICKET IS NON-REFUNDABLE — the ticket is
                            non-refundable;
                            <br />
                            TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW — the
                            ticket cannot be refunded in case of no-show.
                            <br />
                            Change rules are described in the section with the
                            CHANGES subtitle.
                            <br />
                            <br />
                            The CHANGES ARE NOT PERMITTED line means that you
                            cannot make any changes and in such a case, you are
                            not allowed to change the date/time/route of the
                            flight.
                          </Typography>
                        </Box>
                      </Box>

                      {/* -------new end */}
                    </Box>
                    <Box textAlign="Right" px={5} pb={4}>
                      <Button
                        size="large"
                        className="shine-effect"
                        style={{
                          color: "var(--white)",
                          fontWeight: 500,
                          backgroundColor: "var(--primary-color)",
                          borderRadius: "5px",
                        }}
                        onClick={FlightInformation}
                      >
                        BOOK NOW
                      </Button>
                    </Box>
                  </Box>
                </SwipeableDrawer>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleFlight;
