/* eslint-disable jsx-a11y/no-distracting-elements */
import { Box, Container } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import HomeSearchBox from "../HomeSearchBox/HomeSearchBox";
import { MoreDeals } from "../MoreDeals/MoreDeals";
import { RecentSearch } from "../RecentSearch/RecentSearch";
import ServiceSection from "../ServiceSection/ServiceSection";
import { TrendingDeals } from "../TrendingDeals/TrendingDeals";

import "./Dashboard.css";

const Dashboard = () => {
  const [type, setType] = React.useState("flight");
  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };
  return (
    <Box sx={{ mt: 2 }}>
      <Header />
      <Box mt={2}>
        <HomeSearchBox
          type={type}
          setType={setType}
          handleTypeChange={handleTypeChange}
        />
      </Box>
      <RecentSearch />
      {/* <ServiceSection /> */}
      <TrendingDeals />
      <MoreDeals />
    </Box>
  );
};

export default Dashboard;
