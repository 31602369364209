import React, { useEffect, useState } from "react";
import { Box, ClickAwayListener, Container, Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { addDays, format } from "date-fns";
import { Button } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import commaNumber from "comma-number";
import Header from "../Header/Header";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

const LedgerReport = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  let agentId = user?.user?.agentId;
  const [ledgerData, setLedgerData] = useState([]);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yyyy")
  );
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = `https://api.flyjatt.com/v1/Report/ClientLedger.php?agentId=${agentId}&endDate=${new Date(
      endDate
    ).toLocaleDateString("sv")}&startDate=${new Date(
      startDate
    ).toLocaleDateString("sv")}`;
    await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setLedgerData(data);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Failed",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/user/dashboard");
        });
      });
  };

  const handleClickAway = () => {
    setOpenEndDate(false);
    setOpenStartDate(false);
  };

  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: "relative",
              display: { xs: "block", sm: "flex", md: "flex" },
              justifyContent: "space-between",
              alignItems: "end",
              mb: 1,
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "28px",
                color: "var(--secondary-color)",
              }}
            >
              Report
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                alignItems="end"
                justifyContent={"start"}
              >
                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Tooltip title="Enter Start Date">
                      <input
                        required
                        id="startDate"
                        name="startDate"
                        style={{
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                        type="text"
                        value={startDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate(false);
                          setOpenStartDate((prev) => !prev);
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {openStartDate && (
                    <Calendar
                      color={"var(--primary-color)"}
                      date={new Date(startDate)}
                      onChange={(date) => {
                        setStartDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenStartDate(false);
                      }}
                      maxDate={addDays(new Date(), -1)}
                      months={1}
                      className="new-dashboard-calendar"
                      name="dashboard-calendar"
                    />
                  )}
                </Grid>

                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Tooltip title="Enter End Date">
                      <input
                        required
                        id="endDate"
                        name="endDate"
                        style={{
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                        type="text"
                        value={endDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate((prev) => !prev);
                          setOpenStartDate(false);
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {openEndDate && (
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(endDate)}
                      onChange={(date) => {
                        setEndDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenEndDate(false);
                      }}
                      months={1}
                      className="new-dashboard-calendar"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "5px",
                  }}
                >
                  <Button
                    sx={{
                      background: "var(--secondary-color)",
                      color: "white",
                      padding: "5px 20px !important",
                      fontSize: "12px !important",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    PROCEED
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </ClickAwayListener>
        {ledgerData.length !== 0 ? (
          <Box
            className="table-wrapper"
            sx={{
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "var(--secondary-color)",
                borderRadius: "0px",
              },
              height: "calc(100vh - 300px)",
              overflowY: "scroll",
            }}
          >
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>TransactionId</th>
                  <th>Date</th>
                  <th>Remarks</th>
                  <th>Amount</th>
                  <th>Balance</th>
                </tr>
              </thead>

              <tbody>
                {ledgerData !== "No Record Found"
                  ? ledgerData?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item?.deposit > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#177c33",
                                borderRadius: "4px",
                              }}
                            >
                              Deposit
                            </button>
                          ) : item?.purchase > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                borderRadius: "4px",
                                backgroundColor: "#ed1c24",
                              }}
                            >
                              Purchase
                            </button>
                          ) : item?.loan > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#ff6464",
                                borderRadius: "4px",
                              }}
                            >
                              Loan
                            </button>
                          ) : item?.void > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#235f83",
                                borderRadius: "4px",
                              }}
                            >
                              Void
                            </button>
                          ) : item?.refund > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#7a64ff",
                                borderRadius: "4px",
                              }}
                            >
                              Refund
                            </button>
                          ) : item?.others > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#272323",
                                borderRadius: "4px",
                              }}
                            >
                              Other
                            </button>
                          ) : item?.servicefee > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#0b8668",
                                borderRadius: "4px",
                              }}
                            >
                              Service Fee
                            </button>
                          ) : (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "orange",
                                borderRadius: "4px",
                              }}
                            >
                              Issue Rejected
                            </button>
                          )}
                        </td>
                        <td>{item?.reference}</td>
                        <td>
                          {item?.created_at
                            ? format(
                                new Date(item?.created_at),
                                "dd MMM yyyy hh:mm a"
                              )
                            : "Transaction Date"}
                        </td>
                        <td>{item?.details || "-"}</td>
                        <td>
                          {item?.deposit > "0"
                            ? "+" + commaNumber(Number(item?.deposit))
                            : item?.purchase > "0"
                            ? "-" + commaNumber(Number(item?.purchase))
                            : item?.returnMoney > "0"
                            ? "+" + commaNumber(Number(item?.returnMoney))
                            : item?.loan > "0"
                            ? "+" + commaNumber(Number(item?.loan))
                            : item?.void > "0"
                            ? "+" + commaNumber(Number(item?.void))
                            : item?.refund > "0"
                            ? "+" + commaNumber(Number(item?.refund))
                            : item?.reissue > "0"
                            ? "+" + commaNumber(Number(item?.reissue))
                            : item?.others > "0"
                            ? "+" + commaNumber(Number(item?.others))
                            : item?.servicefee > "0"
                            ? "+" + commaNumber(Number(item?.servicefee))
                            : ""}
                          {""}
                          SAR
                        </td>

                        <td>
                          {commaNumber(item?.lastAmount) || "Balance"} SAR
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </Box>
        ) : (
          <Box mt={1}>
            <Typography style={{ background: "#ccc", padding: "4px 8px" }}>
              No Records found...!
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default LedgerReport;
