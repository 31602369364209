import { Box } from "@mui/system";
import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
import Reset from "../../images/undraw/undraw_forgot_password_re_hxwm.svg";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import Logo from "../../images/logo/logo.png";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    let url = `https://api.flyjatt.com/v1/Auth/forgetpassword.php?email=${loginData.email}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          Swal.fire({
            imageUrl: Reset,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "New Password has been send In Your Email",
            html: `For any query.Please contact us at <strong> contact@ticketfactory.sa</strong> or Call <strong> +971509517784 </strong>`,
            showConfirmButton: false,
            timer: 1500,
          }).then(function () {
            navigate("/");
          });
        } else {
          Swal.fire({
            imageUrl: Invalid,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Your email address is incorrect",
            confirmButtonText: "Try again",
            confirmButtonColor: "var(--primary-color)",
          }).then(function () {
            navigate("/");
          });
        }
        setIsLoading(false);
      });
    e.preventDefault();
    e.target.reset();
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "var(--primary-color)",
      }}
    >
      <Box
        sx={{
          background: "var(--white)",
          width: "fit-content",
          height: "fit-content",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            background: "var(--white)",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "10px",
          }}
          onClick={() => navigate("/")}
        >
          <Box
            sx={{
              height: "60px",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box className="regiter-content" mb={2}>
            <Typography
              sx={{
                fontSize: "18px",
                color: "var(--black)",
                fontWeight: "500px",
                width: "100%",
                textAlign: "center",
                marginBottom: "5px",
              }}
            >
              Forgot Password
            </Typography>

            <Typography
              sx={{
                fontSize: "13px",
                color: "var(--black)",
                fontWeight: "500px",
                width: "100%",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Enter your registered email to reset your password.
            </Typography>
          </Box>

          <input
            required
            style={{
              width: "calc(100% - 10px)",
              height: "50px",
              border: "none",
              paddingLeft: "10px",
              outline: "none",
              background: "var(--input-bgcolor)",
              fontSize: "14px",
              borderRadius: "10px",
            }}
            placeholder="Enter Your Email"
            id="email"
            name="email"
            type="email"
            onChange={handleOnChange}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              background: "var(--primary-color)",
              width: "100%",
              marginTop: "10px",
              "&:hover": {
                background: "var(--primary-color)",
              },
            }}
          >
            {!isLoading ? (
              "Reset Password"
            ) : (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            )}
          </Button>
          <Typography
            sx={{
              margin: "10px 0px",
              fontSize: "14px",
              color: "var(--black)",
              fontWeight: "500px",
              width: "100%",
              textAlign: "center",
            }}
          >
            New here?{" "}
            <NavLink to="/signup" style={{ textDecoration: "none" }}>
              Sign Up
            </NavLink>
          </Typography>
          <Typography
            sx={{
              margin: "10px 0px",
              fontSize: "14px",
              color: "var(--black)",
              fontWeight: "500px",
              width: "100%",
              textAlign: "center",
            }}
          >
            Already have an account?{" "}
            <NavLink to="/signin" style={{ textDecoration: "none" }}>
              Sign In
            </NavLink>
          </Typography>
        </form>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
