import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import seat1 from "../../images/Icon/bag.svg";
import bag from "../../images/Icon/seat.svg";
import anemy from "../../images/anemy.png";
import FlightUserInfo from "../../components/FlightUserinfo/FlightUserInfo";
import FlightUserInfoFlyHub from "../../components/FlightUserinfo/FlightUserInfoFlyHub";
import FlightUserInfoSabre from "../../components/FlightUserinfo/FlightUserInfoSabre";
import Loader from "../../images/loader/Render.gif";
import NotFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import FlightInfoDetails from "../../components/FlightInfoDetails/FlightInfoDetails";
import FlightIcon from "@mui/icons-material/Flight";
import "./FlightInformation.css";
import { format } from "date-fns";
import Header from "../../components/Header/Header";

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const FlightInformation = (props) => {
  const location = useLocation();
  const totalFares = parseInt(location?.state?.flightData?.TotalFare);
  const [loadData, setLoadData] = useState([]);
  const { adultCount, childCount, infant } = location.state;
  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  //todo: cupon
  const [coupon, setCoupon] = useState("");
  const [couponAppliedMessage, setCouponAppliedMessage] = useState({});
  //todo:end cupon
  //todo: Baggage Information
  const [adultBaggage, setAdultBaggage] = useState(0);
  const [childBaggage, setChildBaggage] = useState(0);
  const [infantBaggage, setInfantBaggage] = useState(0);
  //todo: End Baggage Information end

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  let url;
  let body;
  let segments = [];
  location.state?.flightData?.segments?.map((data) =>
    segments.push({
      departure: data.departure,
      arrival: data.arrival,
      dpTime: data.departureTime,
      arrTime: data.arrivalTime,
      bCode: data.bookingcode,
      mCarrier: data.marketingcareer,
      mCarrierFN: data.marketingflight,
      oCarrier: data.marketingcareer,
      oCarrierFN: data.operatingflight,
    })
  );

  if (location.state?.flightData?.system === "Sabre") {
    url = "https://api.flyjatt.com/v1/AirBooking/AirPrice.php";
    body = {
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infant,
      segment: location.state.flightData.segment,
      tripType: location.state.tripType,
      segments: segments,
    };
  }
  useEffect(() => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data?.OTA_AirLowFareSearchRS?.PricedItinCount === 1) {
          setLoadData(data);
        } else {
          throw new Error(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          imageUrl: NotFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Data Found",
          confirmButtonText: "Search Another Flights...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate(-1);
        });
      });
  }, [
    body.adultCount,
    body.childCount,
    body.infant,
    body.segment,
    body.tripType,
    navigate,
  ]);

  let adultPrice = 0,
    adultTaxPrice = 0,
    childPrice = 0,
    childTaxPrice = 0,
    infTaxPrice = 0,
    infPrice = 0,
    inTotalBaseFare = 0,
    totalTax = 0,
    totalFare = 0,
    totalBaseFare = 0,
    serviceFeeAdult = 0,
    serviceFeeChild = 0,
    serviceFeeInfant = 0,
    discount = 0,
    agentTotal = 0,
    limitTime;

  if (!isLoaded) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      {Object.keys(loadData).length !== 0 ? (
        <Container sx={{ padding: { md: "0" }, mt: 2 }}>
          <Grid container mt={4}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8.5}
              order={{ xs: 2, sm: 2, md: 1 }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: 16, sm: 22 },
                    color: "var(--secondary-color)",
                    fontWeight: 500,
                  }}
                >
                  Flight Information Details
                </Typography>
                <Box>
                  {location?.state?.flightData?.segments.map(
                    (data, index, arr) => (
                      <Box my={2}>
                        <Grid container spacing={{ xs: 1, sm: 2 }}>
                          <Grid item xs={5} sm={6} md={2.5}>
                            <Box
                              width={{ xs: "30px", sm: "50px" }}
                              height={{ xs: "30px", sm: "50px" }}
                              borderRadius="50%"
                              boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                              overflow="hidden"
                            >
                              <img
                                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.marketingcareer}.png`}
                                alt="flight logo"
                                width="100%"
                                height="100%"
                              />
                            </Box>
                            <Typography
                              sx={{
                                color: "var(--primary-color)",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                              noWrap
                            >
                              {data?.marketingcareerName}
                              <br />
                              <span
                                style={{
                                  color: "var(--fontcolor)",
                                }}
                              >
                                {data?.marketingcareer} {data?.marketingflight}{" "}
                                & {data?.bookingcode}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={7} sm={6} md={3.5}>
                            <Typography fontSize={{ xs: 14, sm: 20 }}>
                              <span
                                style={{
                                  color: "var(--primary-color)",
                                }}
                              >
                                {data?.departureLocation?.split(" ,")[0]},{" "}
                              </span>
                              <span
                                style={{
                                  color: "var(--primary-color)",
                                  fontSize: "12px",
                                }}
                              >
                                {data?.departure}
                              </span>
                              <br />
                              <Tooltip title={`${data?.departureAirport}`}>
                                <Typography
                                  style={{
                                    color: "var(--secondary-color)",
                                    fontSize: "13px",
                                  }}
                                  noWrap
                                >
                                  {data?.departureAirport}
                                </Typography>
                              </Tooltip>

                              <span
                                style={{
                                  color: "var(--fontcolor)",
                                  fontSize: "12px",
                                }}
                              >
                                {format(
                                  new Date(data?.departureTime?.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={6} md={2.5} margin="auto">
                            <Box textAlign="center">
                              <Typography
                                sx={{
                                  color: "var(--primary-color)",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "12px",
                                    sm: "10px",
                                    md: "12px",
                                  },
                                }}
                              >
                                {data?.flightduration}
                              </Typography>
                              <Box className="stop-bar-parent">
                                <CircleIcon
                                  sx={{
                                    color: "var(--transit)",
                                    fontSize: "15px",
                                  }}
                                />
                                <Box className="stop-bar-line-details"></Box>
                                <CircleIcon
                                  sx={{
                                    color: "var(--transit)",
                                    fontSize: "15px",
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            sm={6}
                            md={3.5}
                            textAlign={{ xs: "left", sm: "end" }}
                          >
                            <Typography fontSize={{ xs: 14, sm: 20 }}>
                              <span
                                style={{
                                  color: "var(--primary-color)",
                                }}
                              >
                                {data?.arrivalLocation?.split(" ,")[0]},{" "}
                              </span>
                              <span
                                style={{
                                  color: "var(--primary-color)",
                                  fontSize: "12px",
                                }}
                              >
                                {data?.arrival}
                              </span>
                              <br />

                              <Tooltip title={`${data?.arrivalAirport}`}>
                                <Typography
                                  style={{
                                    color: "var(--secondary-color)",
                                    fontSize: "13px",
                                  }}
                                  noWrap
                                >
                                  {data?.arrivalAirport}
                                </Typography>
                              </Tooltip>

                              <span
                                style={{
                                  color: "var(--fontcolor)",
                                  fontSize: "12px",
                                }}
                              >
                                {format(
                                  new Date(data?.arrivalTime?.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* Transit time start  */}
                        <Box textAlign="center" my={{ xs: 3 }}>
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: "12px",
                              textAlign: "center",
                              color: "var(--secondary-color)",
                              display:
                                index === arr.length - 1 ? "none" : "block",
                            }}
                          >
                            {index === 0 && (
                              <Box
                                sx={{
                                  width: "50%",
                                  margin: "auto",
                                  bgcolor: "var(--transit)",
                                  py: "3px",
                                  textAlign: "center",
                                  borderRadius: "25px",
                                }}
                              >
                                Transit Time:{" "}
                                {location?.state?.flightData?.transit?.transit1}
                              </Box>
                            )}
                            {index === 1 && (
                              <Box
                                sx={{
                                  width: "50%",
                                  margin: "auto",
                                  bgcolor: "var(--transit)",
                                  py: "3px",
                                  textAlign: "center",
                                  borderRadius: "25px",
                                  fontSize: 12,
                                }}
                              >
                                Transit Time:{" "}
                                {location?.state?.flightData?.transit?.transit2}
                              </Box>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
                <FlightUserInfoSabre
                  loadData={loadData}
                  flightData={location.state.flightData}
                  userData={location.state}
                  searchResult={loadData}
                  adultPrice={adultPrice}
                  childPrice={childPrice}
                  infPrice={infPrice}
                  adultTaxPrice={adultTaxPrice}
                  childTaxPrice={childTaxPrice}
                  infTaxPrice={infTaxPrice}
                  serviceFeeAdult={serviceFeeAdult}
                  serviceFeeChild={serviceFeeChild}
                  serviceFeeInfant={serviceFeeInfant}
                  inTotalBaseFare={inTotalBaseFare}
                  totalBaseFare={totalBaseFare}
                  totalTax={totalTax}
                  totalFare={totalFare}
                  limitTime={limitTime}
                  isLoaded={isLoaded}
                  setIsLoaded={setIsLoaded}
                  clientFare={location.state.clientFare}
                  coupon={coupon}
                  setCoupon={setCoupon}
                  couponAppliedMessage={couponAppliedMessage}
                  setCouponAppliedMessage={setCouponAppliedMessage}
                  adultBaggage={adultBaggage}
                  setAdultBaggage={setAdultBaggage}
                  childBaggage={childBaggage}
                  setChildBaggage={setChildBaggage}
                  infantBaggage={infantBaggage}
                  setInfantBaggage={setInfantBaggage}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3.5}
              order={{ xs: 1, sm: 1, md: 3 }}
            >
              {/*// todo: price breakdown section */}
              <FlightInfoDetails
                loadData={loadData}
                totalFares={totalFares}
                searchData={location?.state?.flightData}
                adultCount={location?.state?.adultCount}
                childCount={location?.state?.childCount}
                infant={location?.state?.infant}
              />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
            width: "50vw",
            marginInline: "auto",
          }}
        >
          <Box
            style={{
              width: "50%",
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Loader}
              alt="loader"
              style={{
                width: "100%",
                objectFit: "center",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default FlightInformation;
