import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  Box,
  Button,
  Container,
  createTheme,
  Grid,
  Pagination,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import Swal from "sweetalert2";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";
import SearchIcon from "@mui/icons-material/Search";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222222",
      darker: "#222222",
    },
  },
});

const Traveller = () => {
  const users = secureLocalStorage.getItem("user-info");
  const agentId = users?.user?.agentId;
  const [allTravelers, setAllTravelers] = useState([]);
  const [travelers, setTravelers] = useState([]);
  const navigate = useNavigate();
  // todo: pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    let url = `https://api.flyjatt.com/v1/Traveller/index.php?allagentId=${agentId}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.number_of_page);
        setAllTravelers(data);
        setTravelers(data);
      });
  }, [agentId, page]);

  async function handelDelete(id, paxId) {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You wants to delete this traveller ?",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Yes delete it !",
      cancelButtonColor: "crimson",
      cancelButtonText: "Don't delete it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let url = `https://api.flyjatt.com/v1/Traveller/index.php?delete`;
        let body = {
          id: id,
          agentId: agentId,
          paxId: paxId,
        };
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status.toLowerCase() === "success") {
              Swal.fire({
                // imageUrl: Issue,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: "Delete this traveller",
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                // setIsDone(true);
                // setIssueLoading(false);
                navigate(0);
              });
            } else {
              Swal.fire({
                imageUrl: Invalid,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${data?.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                // setIsDone(true);
                // setIssueLoading(false);
                navigate(0);
              });
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              imageUrl: Invalid,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Issue Ticket Failed!",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              // setIsDone(true);
              // setIssueLoading(false);
              navigate(0);
            });
          });
      }
    });
  }

  //  todo:search functionality handle
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = allTravelers.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setTravelers(filterData);
    } else if (searchInput === "") {
      setTravelers(allTravelers);
    }
  };
  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allTravelers.filter((item) => {
      return (
        new Date(item?.created).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created).getDate() <= new Date(endDate).getDate()
      );
    });
    setTravelers(filterData);
  };

  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex", md: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "24px",
                fontWeight: "500",
              }}
            >
              Travelers
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  background: "var(--primary-color)",
                  height: "35px",
                  borderRadius: "20px",
                  input: {
                    border: "none",
                    background: "none",
                    outline: "none",
                    float: "left",
                    padding: 0,
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "normal",
                    transition: "all 0.4s ease-in-out",
                    lineHeight: "35px",
                    width: "0px",
                  },
                  "input::placeholder": { color: "white" },
                  "&:hover input": {
                    width: { xs: "180px", sm: "235px" },
                    padding: "0 10px",
                  },
                  "&:hover button": {
                    background: "white",
                    color: "black",
                  },
                  button: {
                    border: "none",
                    color: "white",
                    float: "right",
                    minHeight: "fit-content",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    background: "var(--primary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.4s ease-in-out",
                    cursor: "pointer",
                    textDecoration: "none",
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Search....."
                  onChange={handelSearchItems}
                />
                <button>
                  <SearchIcon />
                </button>
              </Box>
              {/* <Box>
                <form onSubmit={handleFilter}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="end"
                    justifyContent="end"
                    sx={{
                      zIndex: "9999",
                      input: {
                        width: "100%",
                        padding: "5px 10px",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "5px",
                        cursor: "pointer",
                        outline: "none",
                      },
                    }}
                  >
                    <Grid
                      style={{ position: "relative" }}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <Box>
                        <input
                          title="Enter Start Date"
                          required
                          id="startDate"
                          name="startDate"
                          type="text"
                          value={startDate}
                          readOnly
                          onClick={() => {
                            setOpenEndDate(false);
                            setOpenStartDate((prev) => !prev);
                          }}
                        />
                      </Box>
                      {openStartDate && (
                        <Calendar
                          color={"var(--primary-color)"}
                          date={new Date(startDate)}
                          onChange={(date) => {
                            setStartDate(format(new Date(date), "dd MMM yyyy"));
                            setOpenStartDate(false);
                          }}
                          maxDate={new Date()}
                          months={1}
                          className="new-dashboard-calendar"
                          name="dashboard-calendar"
                        />
                      )}
                    </Grid>

                    <Grid
                      style={{ position: "relative" }}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <Box>
                        <input
                          title="Enter End Date"
                          required
                          id="endDate"
                          name="endDate"
                          type="text"
                          value={endDate}
                          readOnly
                          onClick={() => {
                            setOpenEndDate((prev) => !prev);
                            setOpenStartDate(false);
                          }}
                        />
                      </Box>
                      {openEndDate && (
                        <Calendar
                          color="var(--primary-color)"
                          date={new Date(endDate)}
                          onChange={(date) => {
                            setEndDate(format(new Date(date), "dd MMM yyyy"));
                            setOpenEndDate(false);
                          }}
                          months={1}
                          className="new-dashboard-calendar"
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        gap: "5px",
                      }}
                    >
                      <Button
                        title="Click to Filter"
                        sx={{
                          background: "var(--secondary-color)",
                          color: "white",
                          padding: "5px 20px !important",
                          fontSize: "12px !important",
                          width: "100%",
                          "&:hover": {
                            background: "var(--secondary-color)",
                            color: "white",
                          },
                        }}
                        type="submit"
                      >
                        FILTER
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        gap: "5px",
                      }}
                    >
                      <Button
                        title="Click to Reset"
                        sx={{
                          background: "var(--secondary-color)",
                          color: "white",
                          padding: "5px 20px !important",
                          fontSize: "12px !important",
                          width: "100%",
                          "&:hover": {
                            background: "var(--secondary-color)",
                            color: "white",
                          },
                        }}
                        onClick={() => setTravelers(allTravelers)}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box> */}
              <Button
                sx={{
                  fontSize: "12px",
                  textTransform: "capitalize",
                  background: "var(--secondary-color)",
                  color: "var(--white)",
                  "&:hover": {
                    background: "var(--secondary-color)",
                    color: "var(--white)",
                  },
                }}
                onClick={() => navigate("/user/addtraveller")}
              >
                Add Travelers
              </Button>
            </Box>
          </Box>
          <Box>
            <Box
              className="table-wrapper"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "var(--secondary-color)",
                  borderRadius: "0px",
                },
                height: "calc(100vh - 220px)",
                overflowY: "scroll",
              }}
            >
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th width="15%">Name</th>
                    <th width="10%">Gender</th>
                    <th width="10%">Type</th>
                    <th width="10%">DOB</th>
                    <th width="10%">Nationality</th>
                    <th width="10%">Passport No</th>
                    <th width="15%">Passport Expire Date</th>
                    <th width="10%">Phone </th>
                    <th width="10%">Action </th>
                  </tr>
                </thead>
                <tbody>
                  {travelers?.map((traveller, index) => (
                    <tr key={index}>
                      <td className="fixed-table-row">
                        {traveller?.fName || "Name"} {traveller?.lName}{" "}
                      </td>
                      <td>{traveller?.gender || "gender"}</td>
                      <td>
                        {traveller?.type === "ADT" ? (
                          <>Adult</>
                        ) : traveller?.type === "CNN" ? (
                          <>Child</>
                        ) : (
                          <>Infant</> || "Type"
                        )}
                      </td>
                      <td>
                        {traveller?.dob !== "0000-00-00"
                          ? traveller?.dob
                            ? format(new Date(traveller?.dob), "dd MMM yyyy")
                            : "DOB"
                          : "Invalid Date"}
                      </td>
                      <td>{traveller?.passNation || "Nationality"}</td>
                      <td>{traveller?.passNo || "Passport No"}</td>
                      <td>
                        {traveller?.passEx !== "0000-00-00"
                          ? traveller?.passEx
                            ? format(new Date(traveller?.passEx), "dd MMM yyyy")
                            : "Expire Date"
                          : "Invalid Date"}
                      </td>
                      <td>{traveller?.phone || "Phone"}</td>
                      <td>
                        <Button
                          size="small"
                          onClick={() =>
                            handelDelete(traveller.id, traveller.paxId)
                          }
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Box
              sx={{
                width: "100%",
                py: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <Typography style={{ fontSize: "15px", color: "#222222" }}>
                Showing Results 1 - 10 of 20
              </Typography> */}
              <ThemeProvider theme={theme}>
                <Stack spacing={2}>
                  <Pagination
                    size="small"
                    count={pageCount}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Traveller;
