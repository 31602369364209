import React from "react";
import { Box } from "@mui/system";
import Oneway from "../Oneway/Oneway";
import Roundway from "../Roundway/Roundway";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Multicity from "../MultiCity/Multicity";
import { Tab } from "@mui/material";
import "./FlightSearchBox.css";

const FlightSearchBox = ({
  options,
  setOptions,
  value,
  setValue,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  from,
  setFrom,
  to,
  setTo,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  setClassName,

  changeFrom,
  setChangeFrom,
  searchData,
  setSearchData,
}) => {
  const handleClassName = (event) => {
    setClassName(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: "10px 0px" }}>
      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            height: { md: "fit-content", sm: "100%", xs: "100%" },
            display: "flex",
            justifyContent: {
              md: "space-between",
              sm: "center",
              xs: "center",
            },
            alignItems: "center",
            opacity: "1",
            ".MuiTabs-root": {
              minHeight: "fit-content",
            },
            "& button": {
              opacity: "1",
              borderRadius: "5px",
              background: "transparent",
              color: "var(--primary-color)",
              marginRight: "2px",
              width: "fit-content",
              minHeight: "fit-content",
              padding: "10px",
            },
            "& button.Mui-selected,& button.Mui-selected >svg": {
              background: "var(--white)",
              color: "var(--secondary-color) !important",
            },
          }}
        >
          {/* <RadioGroup
            row
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "var(--secondary-color)",
                fontWeight: "500",
              },
            }}
          >
            <FormControlLabel
              value="return"
              control={<BpRadio />}
              label="Round Way"
            />
            <FormControlLabel
              value="oneway"
              control={<BpRadio />}
              label="One Way"
            />

            <FormControlLabel
              value="multicity"
              control={<BpRadio />}
              label="Multi City"
            />
          </RadioGroup> */}
          <TabList
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <Tab label="One Way" value="oneway" />
            <Tab label="Round Way" value="return" />
            <Tab label="Multi City" value="multicity" />
          </TabList>
          {/* <FormGroup
            row
            sx={{
              fontSize: "10px",
              "& .MuiCheckbox-root.Mui-checked,.MuiCheckbox-root.Mui-checked > .MuiSvgIcon-root":
                {
                  color: "var(--secondary-color)",
                },
              ".MuiSvgIcon-root": {
                color: "var(--white)",
              },
              gap: "5px",
              color: "var(--white)",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCalendarAvailability}
                  onChange={handleChangeOptions}
                  name="showCalendarAvailability"
                />
              }
              label="Calendar Availability"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={directFlightOnly}
                  onChange={handleChangeOptions}
                  name="directFlightOnly"
                />
              }
              label="Direct FLights"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeCheckedBaggage}
                  onChange={handleChangeOptions}
                  name="includeCheckedBaggage"
                />
              }
              label="Checked Baggage"
            />
          </FormGroup> */}
        </Box>
        <TabPanel value="oneway" style={{ padding: "10px 0px 0px" }}>
          <Oneway
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
          />
        </TabPanel>
        <TabPanel value="return" style={{ padding: "10px 0px 0px" }}>
          <Roundway
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
          />
        </TabPanel>

        <TabPanel value="multicity" style={{ padding: "10px 0px 0px" }}>
          <Multicity
            tripType={value}
            iconColor={"#DC143C"}
            bgColor={"#fff"}
            bordercolor={"#003566"}
            faddress={faddress}
            setfaddress={setfaddress}
            toAddress={toAddress}
            setToAddress={setToAddress}
            fromSendData={fromSendData}
            setFromSendData={setFromSendData}
            toSendData={toSendData}
            setToSendData={setToSendData}
            fromSearchText={fromSearchText}
            setFromSearchText={setFromSearchText}
            toSearchText={toSearchText}
            setToSearchText={setToSearchText}
            setValue={setValue}
            adultCount={adultCount}
            setAdultCount={setAdultCount}
            childCount={childCount}
            setChildCount={setChildCount}
            infant={infant}
            setInfant={setInfant}
            result={result}
            setResult={setResult}
            className={className}
            handleClassName={handleClassName}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            changeFrom={changeFrom}
            setChangeFrom={setChangeFrom}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default FlightSearchBox;
