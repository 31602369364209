import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const FareRules = ({ data }) => {
  return (
    <Box>
      {/* refundPenalties start */}
      <Box mb={2}>
        <Typography>
          {data?.passengerCode === "ADT"
            ? "Adult"
            : data?.passengerCode === "INF"
            ? "Infant"
            : "Child"}
        </Typography>
        {data.isRefundable === true && (
          <Box>
            <Typography sx={{ color: "var(--primary-color)", fontSize: 13 }}>
              Refund Penalties
            </Typography>

            {data?.refundPenalties?.map((data) => (
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Typography
                  sx={{ color: "var(--secondary-color)", fontSize: 12 }}
                >
                  {data?.applicability || null}
                </Typography>
                <Typography
                  sx={{ color: "var(--secondary-color)", fontSize: 12 }}
                >
                  {data?.penalty?.amount || null}
                </Typography>
              </Stack>
            ))}
          </Box>
        )}
      </Box>
      {/* refundPenalties end */}

      {/* exchangePenalties start */}
      <Box my={2}>
        <Typography>
          {data?.passengerCode === "ADT"
            ? "Adult"
            : data?.passengerCode === "INF"
            ? "Infant"
            : "Child"}
        </Typography>
        {data.isChangeable === true && (
          <Box>
            <Typography sx={{ color: "var(--primary-color)", fontSize: 13 }}>
              Exchange Penalties
            </Typography>

            {data?.exchangePenalties?.map((data) => (
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Typography
                  sx={{ color: "var(--secondary-color)", fontSize: 12 }}
                >
                  {data?.applicability || null}
                </Typography>
                <Typography
                  sx={{ color: "var(--secondary-color)", fontSize: 12 }}
                >
                  {data?.penalty?.amount || null}
                </Typography>
              </Stack>
            ))}
          </Box>
        )}
      </Box>
      {/* exchangePenalties end */}
    </Box>
  );
};

export default FareRules;
