import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
  Collapse,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import secureLocalStorage from "react-secure-storage";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import flightData from "../flightData";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import { format } from "date-fns";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto var(--secondary-color)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "radial-gradient(var(--white),var(--white) 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const Multicity = ({
  tripType,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSearchText,
  setFromSearchText,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  setValue,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
  from,
  setFrom,
  to,
  setTo,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  searchData,
  setSearchData,
}) => {
  const data = flightData; // json data from flight Data
  const navigate = useNavigate();
  // todo: multiCity search Body

  const addCity = () => {
    const tempSearchData = [...searchData.segments];
    tempSearchData.push({
      id: tempSearchData.length,
      DepFrom: tempSearchData[tempSearchData.length - 1].ArrTo,
      depFromText: tempSearchData[tempSearchData.length - 1].arrToText,
      ArrTo: "JFK",
      arrToText: "John F Kennedy Intl [JFK]",
      openTo: false,
      Date: new Date().toLocaleDateString("sv"),
      openDate: false,
      open: false,
    });
    setSearchData({
      ...searchData,
      segments: tempSearchData,
      CityCount: tempSearchData.length,
    });
  };
  const removeCity = (id) => {
    const tempSearchData = searchData.segments.filter((item) => item.id !== id);
    setSearchData({
      ...searchData,
      segments: tempSearchData,
      CityCount: tempSearchData.length,
    });
  };
  // todo: end multiCity search Body

  const initialData = [
    {
      code: "RUH",
      name: "King Khaled Intl ",
      Address: "Riyadh,SAUDI ARABIA",
    },
    { code: "JED", name: "Jeddah Intl ", Address: "Jeddah,SAUDI ARABIA" },
    {
      code: "ABT",
      name: "Al Aqiq Airport",
      Address: "Al Baha,SAUDI ARABIA",
    },
    {
      code: "AHB",
      name: "Abha Intl Airport",
      Address: "Abha,SAUDI ARABIA",
    },
    { code: "AJF", name: "JOUF Airport", Address: "Al Jouf,SAUDI ARABIA" },
    {
      code: "AQI",
      name: "Qaisumah Intl Airport",
      Address: "Qaisumah,SAUDI ARABIA",
    },
    { code: "DHA", name: "Dhahran Intl ", Address: "Dhahran,SAUDI ARABIA" },
    {
      code: "DMM",
      name: "King Fahad Intl Airport",
      Address: "Dammam,SAUDI ARABIA",
    },
    {
      code: "DWD",
      name: "Dawadmi Intl Airport",
      Address: "Dawadmi Intl Airport,SAUDI ARABIA",
    },
  ];

  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state
  const [fromSuggest, setFromSuggest] = useState(initialData);
  const [toSuggest, setToSuggest] = useState(initialData);

  // Opens the dialog when the user clicks.
  const handleClickOpen = (index, segment) => {
    const tempSearchData = [...searchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: !segment.open,
    };
    setSearchData({ ...searchData, segments: tempSearchData });
  };

  // Closes the child process.
  const handleClose = (index) => {
    const tempSearchData = [...searchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: false,
    };
    setSearchData({ ...searchData, segments: tempSearchData });
    setResult(adultCount + childCount + infant);
  };

  // Sets the number of children.
  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
      setSearchData({ ...searchData, adultCount: adultCount + 1 });
    }
  }

  // Decrement the count of children.
  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      setSearchData({ ...searchData, adultCount: adultCount - 1 });
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
          setSearchData({ ...searchData, infant: infant - 1 });
        }
      }
    }
  }

  function childInclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
      setSearchData({ ...searchData, childCount: childCount + 1 });
    }
  }

  function childDecrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
      setSearchData({ ...searchData, childCount: childCount - 1 });
    }
  }

  // Increment the default value if the value is not a child.
  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
        setSearchData({ ...searchData, infantCount: infant + 1 });
      }
    }
  }

  // Decrement the infant by 1.
  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
      setSearchData({ ...searchData, infantCount: infant - 1 });
    }
  }

  const formOnChange = (e) => {
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setFromSuggest(suggestion);
      }
    } else {
      setFromSuggest(initialData);
    }
  };

  const fromGetSuggetion = (index) => {
    const fromSuggestedText = (name, code, address) => {
      const tempSearchData = [...searchData.segments];

      tempSearchData[index] = {
        ...tempSearchData[index],
        DepFrom: code.trim(),
        depFromText: `${name} (${code.trim()})`,
        openFrom: false,
        openTo: true,
      };
      setSearchData({ ...searchData, segments: tempSearchData });
      setFromSendData(code);
      setFromSearchText(`${name} (${code})`);
      setFromSuggest([]);
      setfaddress(address);
    };
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {fromSuggest.length !== 0 ? (
            fromSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "var( --secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.Address}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "12px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "var(--white)",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: 400,
                        }}
                      >
                        {item.code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setToSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setToSuggest(suggestion);
      }
    } else {
      setToSuggest(initialData);
    }
  };

  const toGetSuggetion = (index) => {
    const toSuggestedText = (name, code, address) => {
      const tempSearchData = [...searchData.segments];
      if (index === tempSearchData.length - 1) {
        tempSearchData[index] = {
          ...tempSearchData[index],
          ArrTo: code.trim(),
          arrToText: `${name} (${code.trim()})`,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
      } else {
        tempSearchData[index] = {
          ...tempSearchData[index],
          ArrTo: code.trim(),
          arrToText: `${name} (${code.trim()})`,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
        tempSearchData[index + 1] = {
          ...tempSearchData[index + 1],
          DepFrom: code.trim(),
          depFromText: `${name} (${code.trim()})`,
        };
      }
      setSearchData({ ...searchData, segments: tempSearchData });
      setToSendData(code);
      setToSearchText(`${name} (${code})`);
      setToSuggest([]);
      setToAddress(address);
    };
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {toSuggest.length !== 0 ? (
            toSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "5px",
                    backgroundColor: "var( --secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      toSuggestedText(
                        ` ${item.name}`,
                        `${item.code}`,
                        `${item.Address}`
                      )
                    } //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "12px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          color: "var(--white)",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          paddingRight: "10px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: 400,
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  async function handleSearch(e) {
    e.preventDefault();
    //todo: setChangeState for reTrigger useEffect
    setChangeState((prev) => !prev);
    //todo: setChangeState for reTrigger useEffect end

    // todo: setClick to true to start loader
    setClick(true);
    secureLocalStorage.setItem("multi-city", {
      faddress,
      toAddress,
      fromSearchText,
      toSearchText,
      departureDate: format(new Date(from), "dd MMM yy"),
      adultCount,
      childCount,
      infant,
      tripType,
      fromSendData,
      toSendData,
      className,
      searchData,
      changeState,
    });
    navigate("/user/multicityaftersearch", {
      state: {
        faddress,
        toAddress,
        fromSearchText,
        toSearchText,
        departureDate: format(new Date(from), "dd MMM yy"),
        adultCount,
        childCount,
        infant,
        tripType,
        fromSendData,
        toSendData,
        className,
        searchData,
        changeState,
      },
    });
  }

  const handleSelect = (date, index) => {
    const tempSearchData = [...searchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      Date: new Date(date).toLocaleDateString("sv"),
      openDate: false,
      openFrom: false,
      openTo: false,
    };
    setSearchData({ ...searchData, segments: tempSearchData });
  };

  const handleClickAway = (index) => {
    setResult(adultCount + childCount + infant);
    const tempSegment = [...searchData.segments];
    tempSegment[index] = {
      ...tempSegment[index],
      openFrom: false,
      openTo: false,
      openDate: false,
      open: false,
    };
    setSearchData({
      ...searchData,
      segments: tempSegment,
    });
  };

  return (
    <Box style={{ position: "relative" }}>
      <form onSubmit={handleSearch}>
        {searchData.segments.map((segment, index, arr) => (
          <Box key={index} sx={{ mb: 1 }}>
            <ClickAwayListener onClickAway={() => handleClickAway(index)}>
              <Grid
                key={index}
                sx={{
                  height: "fit-content",
                  width: "100%",
                  position: "relative",
                  mt: { md: "0px", sm: "5px", xs: "5px" },
                }}
                container
                alignItems="center"
                rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 1 }}
                columnSpacing={0}
              >
                {/* //todo destination section */}
                <Grid
                  container
                  alignItems="center"
                  md={5}
                  sx={{
                    height: "90px",
                    borderRadius: "10px",
                    backgroundColor: "var(--white)",
                  }}
                >
                  {/* //todo: Departure City section */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      position: "relative",
                      border: "1px solid var(--gray)",
                      borderRadius: "10px 10px 0px 0px",
                      padding: "5px",
                      height: "50%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        gap: "5px",
                      }}
                      onClick={() => {
                        const tempSegment = [...searchData.segments];
                        tempSegment[index] = {
                          ...tempSegment[index],
                          openFrom: !segment.openFrom,
                          openTo: false,
                          openDate: false,
                        };
                        setSearchData({
                          ...searchData,
                          segments: tempSegment,
                        });
                      }}
                    >
                      <Box
                        style={{
                          width: "10%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "25px",
                            width: "25px",
                            borderRadius: "50%",
                            backgroundColor: "var(--primary-color)",
                            color: "var(--white)",
                          }}
                        >
                          <FlightTakeoffIcon sx={{ fontSize: "16px" }} />
                        </Box>
                      </Box>
                      <Box
                        style={{
                          width: "90%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        {/* <Tooltip
                          title={`Departure From: ${segment.depFromText}`}
                        > */}
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "var(--black)",
                            }}
                            noWrap
                          >
                            {segment.depFromText}
                          </Typography>
                        </Box>
                        {/* </Tooltip> */}
                      </Box>
                    </Box>
                    <Collapse
                      in={segment.openFrom}
                      timeout="auto"
                      unmountOnExit
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "90%",
                          left: "0",
                          width: "96%",
                          backgroundColor: "var( --secondary-color)",
                          height: "fit-content",
                          borderBottom: "1px solid var(  --gray)",
                          borderLeft: "1px solid var(  --gray)",
                          borderRight: "2px solid var(  --gray)",
                          borderRadius: "0px 0px 5px 5px",
                          zIndex: "999",
                          padding: "5px 5px 0",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "var(--secondary-color)",
                            zIndex: 10,
                          }}
                          backgroundColor="var(--white)"
                        >
                          <input
                            autoComplete="off"
                            autoFocus
                            onChange={formOnChange}
                            className="customPlaceholder"
                            placeholder="Search a airport..."
                            style={{
                              color: "var(--secondary-color)",
                              fontWeight: 400,
                              paddingLeft: "20px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "transparent",
                              border: "none",
                              outline: "none",
                            }}
                          />
                        </Box>
                        <Box>{fromGetSuggetion(index)}</Box>
                      </Box>
                    </Collapse>
                  </Grid>
                  {/* //todo: Arrival City section */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      position: "relative",
                      border: "1px solid var(--gray)",
                      borderTop: "none",
                      borderRadius: "0px 0px 10px 10px",
                      padding: "5px",
                      height: "50%",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        gap: "5px",
                      }}
                      onClick={() => {
                        const tempSegment = [...searchData.segments];
                        tempSegment[index] = {
                          ...tempSegment[index],
                          openFrom: false,
                          openTo: !segment.openTo,
                          openDate: false,
                        };
                        setSearchData({
                          ...searchData,
                          segments: tempSegment,
                        });
                      }}
                    >
                      <Box
                        style={{
                          width: "10%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "25px",
                            width: "25px",
                            borderRadius: "50%",
                            backgroundColor: "var(--primary-color)",
                            color: "var(--white)",
                          }}
                        >
                          <FlightLandIcon sx={{ fontSize: "16px" }} />
                        </Box>
                      </Box>
                      {/* <Tooltip title={`Arrival To: ${segment.arrToText}`}> */}
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "var(--black)",
                          }}
                        >
                          {segment.arrToText}
                        </Typography>
                      </Box>
                      {/* </Tooltip> */}
                    </Box>
                    <Collapse
                      in={segment.openTo}
                      timeout="auto"
                      unmountOnExit
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "90%",
                          left: "0",
                          width: "96%",
                          backgroundColor: "var( --secondary-color)",
                          height: "fit-content",
                          borderBottom: "1px solid var(  --gray)",
                          borderLeft: "1px solid var(  --gray)",
                          borderRight: "2px solid var(  --gray)",
                          borderRadius: "0px 0px 5px 5px",
                          zIndex: "999",
                          padding: "5px 5px 0",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "var(--secondary-color)",
                            zIndex: 10,
                          }}
                          backgroundColor="var(--white)"
                        >
                          <input
                            autoComplete="off"
                            autoFocus
                            onChange={toOnChange}
                            className="customPlaceholder"
                            placeholder="Search a airport..."
                            style={{
                              color: "var(--secondary-color)",
                              fontWeight: 400,
                              paddingLeft: "20px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "transparent",
                              border: "none",
                              outline: "none",
                            }}
                          />
                        </Box>
                        <Box>{toGetSuggetion(index)}</Box>
                      </Box>
                    </Collapse>
                  </Grid>
                </Grid>
                {/* //todo:Travel Date */}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  sx={{
                    position: "relative",
                    padding: {
                      md: "0px 10px",
                      sm: "0px",
                      xs: "0px",
                    },
                    height: "90px",
                  }}
                >
                  <Box
                    style={{
                      border: "1px solid var(--gray)",
                      borderRadius: "10px",
                      background: "var(--white)",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        borderRight: "1px solid var(--gray)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "50%",
                      }}
                      onClick={() => {
                        const tempSearchData = [...searchData.segments];
                        tempSearchData[index] = {
                          ...tempSearchData[index],
                          openFrom: false,
                          openTo: false,
                          openDate: !segment.openDate,
                        };
                        setSearchData({
                          ...searchData,
                          segments: tempSearchData,
                        });
                      }}
                    >
                      <Box
                        sx={{
                          width: "20%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                        }}
                      >
                        <CalendarMonthIcon
                          sx={{
                            fontSize: "35px",
                            color: "var(--primary-color)",
                            padding: "10px 0px 0px 10px",
                          }}
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          width: "80%",
                          height: "fit-content",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--secondary-color)",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {`${format(new Date(segment?.Date), "MMMM")}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "var(--secondary-color)",
                            fontSize: "30px",
                            fontWeight: "500",
                            lineHeight: "1",
                          }}
                        >
                          {`${format(new Date(segment?.Date), "dd")}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {`${format(new Date(segment?.Date), "EEEE")}`}
                        </Typography>
                      </Box>
                    </Box>
                    {/* //todo: return date section */}
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "50%",
                      }}
                      onClick={() => setValue("return")}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CalendarMonthIcon
                          sx={{
                            fontSize: "35px",
                            color: "var(--primary-color)",
                            padding: "10px 0px 0px 10px",
                          }}
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "center",
                          width: "100%",
                          height: "50%",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--secondary-color)",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          Add Return
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {segment.openDate && (
                    <Box>
                      <Calendar
                        color="#ffa84d"
                        // date={new Date(from)}
                        onChange={(date) => handleSelect(date, index)}
                        months={1}
                        minDate={
                          index === 0
                            ? new Date()
                            : new Date(arr[index - 1].Date)
                        }
                        className={"dashboard-calendar"}
                      />
                    </Box>
                  )}
                </Grid>

                {/* //todo: Passenger Box section */}
                {index === 0 ? (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={2}
                    style={{
                      position: "relative",
                      padding: {
                        md: "0 5px 0 0",
                        sm: "0px",
                        xs: "0px",
                      },
                      height: "90px",
                      zIndex: "9",
                    }}
                  >
                    <Box
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          background: "var(--white)",
                        }}
                        onClick={() => handleClickOpen(index, segment)}
                      >
                        {/* //todo: Class section */}
                        <Box
                          sx={{
                            width: "100%",
                            height: "50%",
                            border: "1px solid var(--gray)",
                            borderRadius: "10px 10px 0 0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AirlineSeatReclineNormalIcon
                            sx={{
                              fontSize: "35px",
                              color: "var(--primary-color)",
                              padding: "0px 0px 0px 10px",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "var(--secondary-color)",
                              fontSize: "14px",
                              fontWeight: "500",
                              padding: "0px 0px 0px 10px",
                            }}
                          >
                            {`${className}`}
                          </Typography>
                        </Box>
                        {/* //todo: Traveler Section */}
                        <Box
                          sx={{
                            width: "100%",
                            height: "50%",
                            border: "1px solid var(--gray)",
                            borderTop: "none",
                            borderRadius: "0px 0px 10px 10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <GroupsIcon
                            sx={{
                              fontSize: "35px",
                              color: "var(--primary-color)",
                              padding: "0px 0px 0px 10px",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "var(--secondary-color)",
                              fontSize: "14px",
                              fontWeight: "500",
                              padding: "0px 0px 0px 10px",
                            }}
                          >
                            {result} Traveler
                          </Typography>
                        </Box>
                      </Box>
                      {/* //todo: Passenger Box */}
                      <Collapse
                        in={segment.open}
                        timeout="auto"
                        unmountOnExit
                        sx={{ width: "100%" }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: {
                              lg: "110%",
                              md: "110%",
                              sm: "100%",
                              xs: "100%",
                            },
                            right: "0px",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--secondary-color)",
                              padding: "15px",
                              overflow: "hidden",
                              width: "260px",
                              border: "1px solid var(--gray)",
                              borderRadius: "10px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "start",
                              gap: "5px",
                            }}
                          >
                            <Box>
                              <Box>
                                <Typography
                                  style={{
                                    textAlign: "left",
                                    marginBottom: "5px",
                                    color: "var(--white)",
                                    fontFamily: "poppins",
                                    fontWeight: "500",
                                  }}
                                >
                                  Passenger
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    gap: "10px",
                                    width: "50%",
                                  }}
                                >
                                  <button
                                    onClick={adultDecrement}
                                    style={{
                                      backgroundColor: "var(--primary-color)",
                                      color: "var(--white)",
                                      border: "none",
                                      width: "25px",
                                      height: "25px",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    -
                                  </button>
                                  <h5 style={{ color: "var(--white)" }}>
                                    {adultCount}
                                  </h5>
                                  <button
                                    onClick={adultInclement}
                                    style={{
                                      backgroundColor: "var(--primary-color)",
                                      color: "var(--white)",
                                      border: "none",
                                      width: "25px",
                                      height: "25px",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    +
                                  </button>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "start",
                                    width: "50%",
                                    flexDirection: "column",
                                    color: "var(--white)",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "var(--white)",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Adult
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--white)",
                                      fontSize: "14px",
                                    }}
                                  >
                                    12+ yrs
                                  </Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    width: "50%",
                                  }}
                                >
                                  <button
                                    onClick={childDecrement}
                                    style={{
                                      backgroundColor: "var(--primary-color)",
                                      color: "var(--white)",
                                      border: "none",
                                      width: "25px",
                                      height: "25px",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    -
                                  </button>
                                  <h5 style={{ color: "var(--white)" }}>
                                    {childCount}
                                  </h5>
                                  <button
                                    onClick={childInclement}
                                    style={{
                                      backgroundColor: "var(--primary-color)",
                                      color: "var(--white)",
                                      border: "none",
                                      width: "25px",
                                      height: "25px",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    +
                                  </button>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "start",
                                    width: "50%",
                                    flexDirection: "column",
                                    color: "var(--white)",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "var(--white)",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Children
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--white)",
                                      fontSize: "14px",
                                    }}
                                  >
                                    2- less than 12 yrs
                                  </Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    gap: "10px",
                                    width: "50%",
                                  }}
                                >
                                  <button
                                    onClick={infantDecrement}
                                    style={{
                                      backgroundColor: "var(--primary-color)",
                                      color: "var(--white)",
                                      border: "none",
                                      width: "25px",
                                      height: "25px",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    -
                                  </button>
                                  <h5 style={{ color: "var(--white)" }}>
                                    {infant}
                                  </h5>
                                  <button
                                    onClick={infantIncrement}
                                    style={{
                                      backgroundColor: "var(--primary-color)",
                                      color: "var(--white)",
                                      border: "none",
                                      width: "25px",
                                      height: "25px",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    +
                                  </button>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "start",
                                    width: "50%",
                                    flexDirection: "column",
                                    color: "var(--white)",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "var(--white)",
                                    }}
                                  >
                                    Infant
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "var(--white)",
                                    }}
                                  >
                                    0 - 23 month{" "}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box my={1} sx={{ width: "100%" }}>
                              <hr />
                            </Box>
                            <Box>
                              <Box>
                                <FormControl>
                                  <RadioGroup
                                    value={className}
                                    row
                                    onChange={handleClassName}
                                  >
                                    <FormControlLabel
                                      value="Economy"
                                      control={<BpRadio />}
                                      label="Economy"
                                      sx={{
                                        mr: "21px",
                                        color: "var(--white)",
                                      }}
                                    />
                                    <FormControlLabel
                                      value="Business"
                                      control={<BpRadio />}
                                      label="Business"
                                      sx={{
                                        mr: "21px",
                                        color: "var(--white)",
                                      }}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                              <Box mt={2} style={{ textAlign: "right" }}>
                                <Button
                                  size="small"
                                  onClick={handleClose}
                                  className="shine-effect"
                                  style={{
                                    backgroundColor: "var(--primary-color)",
                                    color: "var(--white)",
                                  }}
                                >
                                  DONE
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  </Grid>
                ) : null}
                {/* //todo: add and remove city button */}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={1}
                  style={{
                    height: "90px",
                    // zIndex: "999",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    {/* //todo:Remove City */}
                    <Button
                      title="Remove City"
                      disabled={arr.length === 1 ? true : false}
                      sx={{
                        display: index === 0 ? "none" : "flex",
                        fontSize: "14px",
                        backgroundColor: "var(--tomato-color)",
                        color: "var(--white)",
                        "&:hover": {
                          backgroundColor: "var(--tomato-color)",
                          color: "var(--white)",
                          cursor: "pointer",
                        },
                        zIndex: "1",
                      }}
                      onClick={() => removeCity(segment.id)}
                    >
                      <RemoveIcon sx={{ zIndex: "1" }} />
                    </Button>
                    {/* //todo:Add City */}
                    <Button
                      title="Add City"
                      disabled={arr.length > 4 ? true : false}
                      sx={{
                        fontSize: "14px",
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white)",
                        "&:hover": {
                          backgroundColor: "var(--primary-color)",
                          color: "var(--white)",
                          cursor: "pointer",
                        },
                        zIndex: "1",
                      }}
                      onClick={addCity}
                    >
                      <AddIcon sx={{ zIndex: "1" }} />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Box>
        ))}
        {/* //todo: Search Button */}
        <Grid md={12} lg={12} sm={12} xs={12}>
          <Box
            sx={{
              height: { lg: "100%", md: "100%", sm: "70%", xs: "70%" },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              className="shine-effect"
              sx={{
                fontSize: "16px",
                height: "50px",
                width: {
                  lg: "20%",
                  md: "20%",
                  sm: "100%",
                  xs: "100%",
                },
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
                textTransform: "capitalize",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "var(--primary-color)",
                  cursor: "pointer",
                },
              }}
            >
              <SearchIcon style={{ fontSize: "30px" }} />
              {click ? "Wait..." : "Search"}
            </Button>
          </Box>
        </Grid>
      </form>
    </Box>
  );
};

export default Multicity;
