import { Box, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CalenderImg from "../../images/homePage/weekly-calendar.png";
import ConfirmImg from "../../images/homePage/approved.png";
import DollerImg from "../../images/homePage/coin.png";
import SearchImg from "../../images/homePage/search.png";
import commaNumber from "comma-number";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const HomePageStack = () => {
  const navigate = useNavigate();
  const [allQueuesData, setAllQueuesData] = useState({});
  const users = secureLocalStorage.getItem("user-info");
  let agentId = users?.user?.agentId;
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Stats/index.php?agentId=${agentId}`)
      .then((res) => res.json())
      .then((data) => {
        setAllQueuesData(data);
      });
    //todo: end of fetch all data
  }, [agentId]);

  useEffect(() => {
    const url = `https://api.flyjatt.com/v1/Agent/index.php?myaccount`;
    const body = JSON.stringify({
      agentId,
    });
    const config = {
      method: "POST",
      header: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body,
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => setBalance(data?.balance))
      .catch((err) => console.log(err.message));
  }, [balance, agentId]);

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          justifyContent: "start",
          alignItems: "space-around",
        }}
      >
        <Box
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            textDecoration: "none",
            width: "100%",
            height: "fit-content",
            background: "var(--white)",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            padding: "5px 15px",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={CalenderImg} alt="..." width="60%" />
          </Box>

          <Box
            sx={{
              width: "80%",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1.5rem",

                fontWeight: "500",
              }}
            >
              {allQueuesData?.TodayBooking || 0}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1rem",
                textTransform: "capitalize",
                fontWeight: "500",
              }}
            >
              Flights Booking Today
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            textDecoration: "none",
            width: "100%",
            height: "fit-content",
            background: "var(--white)",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px 15px",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ConfirmImg} alt="..." width="60%" />
          </Box>

          <Box
            sx={{
              width: "80%",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1.5rem",

                fontWeight: "500",
              }}
            >
              {allQueuesData?.TodayTicketed || 0}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1rem",
                textTransform: "capitalize",
                fontWeight: "500",
              }}
            >
              Ticket Issued Today
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            textDecoration: "none",
            width: "100%",
            height: "fit-content",
            background: "var(--white)",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px 15px",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={SearchImg} alt="..." width="60%" />
          </Box>

          <Box
            sx={{
              width: "80%",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1.5rem",

                fontWeight: "500",
              }}
            >
              {allQueuesData?.TodaySearch || 0}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1rem",
                textTransform: "capitalize",
                fontWeight: "500",
              }}
            >
              Today's Search
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            textDecoration: "none",
            width: "100%",
            height: "fit-content",
            background: "var(--white)",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px 15px",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={DollerImg} alt="..." width="60%" />
          </Box>

          <Box
            sx={{
              width: "80%",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1.5rem",
                fontWeight: "500",
                width: "100%",
              }}
              noWrap
            >
              {`${commaNumber(balance || 0)} SAR`}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: ".8rem",

                fontWeight: "500",
              }}
            >
              SAR. Current Balance
            </Typography>
            <Link
              underline="hover"
              sx={{
                color: "var(--secondary-color)",
                fontSize: "1rem",
                textTransform: "capitalize",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => navigate("/user/adddeposit")}
            >
              Request Top Up
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePageStack;
