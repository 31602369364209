import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import commaNumber from "comma-number";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useQuery } from "react-query";
import AirlineNames from "../AirlineNames/AirlineNames";
import Address from "../Address/Address";

const PDFPageDesign = ({
  // userData,
  // adultTotalPrice,
  // childTotalPrice,
  // infantTotalPrice,
  // totalPrice,
  // prices,
  // copy,
  // check,
  // companyImage,
  // margin,
  // discount,
  // discountPrice,
  // multiCityAllData,

  //   new start hare
  // bookingId,
  // flightData,
  // fareTotal,
  // passengerData,
  // allData,
  // tripType,
  userData,
  adultTotalPrice,
  childTotalPrice,
  infantTotalPrice,
  totalPrice,
  prices,
  copy,
  check,
  companyImage,
  margin,
  discount,
  discountPrice,
  multiCityAllData,

  bookingId,
  flightData,
  fareTotal,
  passengerData,
  allData, //airRetrive api data
  tripType,
  stateData, //Queues api data
  qeuesData,
}) => {
  const table = {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };
  const tableRow = {
    margin: "auto",
    flexDirection: "row",
  };

  const tableCol = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  //Passenger Details
  const tableColName = {
    width: "35%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColGender = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColType = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColNumber = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableColfromto = {
    width: "22%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColtime = {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableColflight = {
    width: "17%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableCol2 = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  };

  const tableCell = {
    // margin: "auto",
    margin: "3px",
    fontSize: "8px",
  };
  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "8px",
  };

  const adress = {
    fontSize: "10px",
    color: "#8b8b8b",
  };

  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;
  const subagentId = users?.user?.subagentId;
  const companyLogo = users?.user?.logo;

  const [companyName, setCompanyName] = useState("");
  const [agentId, setAgentId] = useState("");
  const [siteConfig, setSiteConfig] = useState({});

  return (
    <Document>
      <Page size="A4" style={{ padding: "27px 20px" }}>
        <View style={{ padding: "15px" }}>
          {/* <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {copy === "Agent Invoice" ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    fontSize: "10px",
                    width: "180px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "900",
                      paddingBottom: "10px",
                    }}
                  >
                    {companyName}
                  </Text>
                  <Text style={adress}>{siteConfig.address}</Text>
                  <Text style={adress}>Email: {siteConfig.email}</Text>
                  <Text style={adress}>Phone: {siteConfig.phone}</Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {companyLogo === "" || companyLogo === "undefined" ? null : (
                <Image
                  style={{ height: "50px" }}
                  src={`https://cdn.flyfarint.com/WL/${agentID}/${subagentId}/${companyLogo}`}
                />
              )}
            </View>
          </View> */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: `${margin || 0}`,
            }}
          >
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                width: "200px",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {companyLogo === "" || companyLogo === "undefined" ? (
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingBottom: "5px",
                    }}
                  >
                    {users?.user?.company}
                  </Text>
                ) : (
                  <Image
                    style={{ height: "50px" }}
                    src={`https://api.flyjatt.com/${companyLogo}`}
                  />
                )}
              </View>

              <Text style={adress}>{users?.user?.address}</Text>
              <Text style={adress}>Email: {users?.user?.email}</Text>
              <Text style={adress}>Phone: {users?.user?.phone}</Text>
            </View>

            <View
              style={{
                color: "#D3D3D3",
                fontSize: "35px",
                fontWeight: 800,
                fontStyle: "Poppins",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{ color: "#003566", opacity: "0.4", fontWeight: "bold" }}
              >
                {copy}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "11px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <Text>Reference: {bookingId || ""}</Text>

            {copy === "e - Ticket" ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "11px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Text>
                  Ticketed:{" "}
                  {new Date(allData?.timestamp).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    // weekday: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: false,
                  })}
                </Text>
                <Text style={{ marginLeft: "15px" }}>
                  Airlines PNR:{" "}
                  {flightData === undefined
                    ? ""
                    : flightData[0]?.confirmationId}
                </Text>
              </View>
            ) : (
              <Text>
                Booking: {allData?.creationDetails?.creationDate}
                {allData?.creationDetails?.creationTime}
              </Text>
            )}

            {/* {allData?.fareRules.length > 0 ? (
              <Text>
                {allData?.fareRules[0]?.isRefundable === true ? (
                  <Text style={{ color: "green" }}>Refundable </Text>
                ) : (
                  <Text style={{ color: "red" }}>Non Refundable </Text>
                )}
              </Text>
            ) : (
              "Non Refundable"
            )} */}

            {/* | {allData?.tripType?.toUpperCase()} */}
          </View>

          <View>
            <Text
              style={{
                fontSize: "12px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              PASSENGER DETAILS
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableColName}>
                  <Text style={tableCell}>Passenger Name </Text>
                </View>
                <View style={tableColGender}>
                  <Text style={tableCell}>Gender</Text>
                </View>
                <View style={tableColType}>
                  <Text style={tableCell}>Passenger Type</Text>
                </View>
                <View style={tableColNumber}>
                  <Text style={tableCell}>
                    {copy === "e - Ticket"
                      ? "Ticket Number"
                      : "Passport Number"}
                  </Text>
                </View>
              </View>
              {copy === "e - Ticket" ? (
                <View>
                  {stateData?.Status !== "Hold" ||
                  stateData?.Status !== "Cancelled" ? (
                    <View>
                      {passengerData?.length === 0 ? (
                        <View>
                          {" "}
                          <View>
                            {qeuesData?.data?.travelers?.map(
                              (traveler, index) => (
                                <View style={tableRow} key={index}>
                                  <View style={tableColName}>
                                    <Text style={tableCell}>
                                      {traveler?.givenName?.slice(
                                        traveler?.givenName?.lastIndexOf(" ")
                                      )}{" "}
                                      {traveler?.givenName?.slice(
                                        0,
                                        traveler?.givenName?.lastIndexOf(" ") ||
                                          "First Name"
                                      )}{" "}
                                      {traveler?.surname || "Last Name"}
                                    </Text>
                                  </View>
                                  <View style={tableColGender}>
                                    <Text style={tableCell}>
                                      {(traveler?.identityDocuments &&
                                        traveler?.identityDocuments[0]
                                          ?.gender) ||
                                        "Gender"}
                                    </Text>
                                  </View>
                                  <View style={tableColType}>
                                    <Text style={tableCell}>
                                      {traveler?.type || "Type"}
                                    </Text>
                                  </View>
                                  <View style={tableColNumber}>
                                    <Text style={tableCell}>
                                      {/* {(traveler?.identityDocuments &&
                                        traveler?.identityDocuments[0]?.documentNumber?.toUpperCase()) ||
                                        "Domestic Flight"} */}
                                      {allData?.flightTickets === undefined
                                        ? null
                                        : allData?.flightTickets[index]?.number}
                                    </Text>
                                  </View>
                                </View>
                              )
                            )}
                          </View>
                        </View>
                      ) : (
                        <View>
                          {" "}
                          {passengerData?.map((data, index) => (
                            <View style={tableRow} key={index}>
                              <View style={tableColName}>
                                <Text style={tableCell}>
                                  {data?.gender === "Male" ? (
                                    <Text>
                                      {data?.gender === "Male" &&
                                      data?.type === "ADT" ? (
                                        <Text>
                                          MR {data?.fName?.toUpperCase()}{" "}
                                          {data?.lName?.toUpperCase()}
                                        </Text>
                                      ) : (
                                        <Text>
                                          MSTR {data?.fName?.toUpperCase()}{" "}
                                          {data?.lName?.toUpperCase()}
                                        </Text>
                                      )}
                                    </Text>
                                  ) : (
                                    <Text>
                                      {data?.gender === "Female" &&
                                      data?.type === "ADT" ? (
                                        <Text>
                                          MS {data?.fName?.toUpperCase()}{" "}
                                          {data?.lName?.toUpperCase()}
                                        </Text>
                                      ) : (
                                        <Text>
                                          MISS {data?.fName?.toUpperCase()}{" "}
                                          {data?.lName?.toUpperCase()}
                                        </Text>
                                      )}
                                    </Text>
                                  )}
                                </Text>
                              </View>
                              <View style={tableColGender}>
                                <Text style={tableCell}>
                                  {data?.gender?.toUpperCase()}
                                </Text>
                              </View>
                              <View style={tableColType}>
                                <Text style={tableCell}>
                                  {data?.type === "ADT"
                                    ? "ADULT"
                                    : data.type === "INF"
                                    ? "INFANT"
                                    : "CHILD"}
                                </Text>
                              </View>
                              <View style={tableColNumber}>
                                <Text style={tableCell}>
                                  {allData?.flightTickets === undefined
                                    ? null
                                    : allData?.flightTickets[index]?.number}
                                </Text>
                              </View>
                            </View>
                          ))}
                        </View>
                      )}
                    </View>
                  ) : null}
                </View>
              ) : (
                <View>
                  {passengerData?.length === 0 ? (
                    <View>
                      {qeuesData?.data?.travelers?.map((traveler, index) => (
                        <View style={tableRow} key={index}>
                          <View style={tableColName}>
                            <Text style={tableCell}>
                              {traveler?.givenName?.slice(
                                traveler?.givenName?.lastIndexOf(" ")
                              )}{" "}
                              {traveler?.givenName?.slice(
                                0,
                                traveler?.givenName?.lastIndexOf(" ") ||
                                  "First Name"
                              )}{" "}
                              {traveler?.surname || "Last Name"}
                            </Text>
                          </View>
                          <View style={tableColGender}>
                            <Text style={tableCell}>
                              {(traveler?.identityDocuments &&
                                traveler?.identityDocuments[0]?.gender) ||
                                "Gender"}
                            </Text>
                          </View>
                          <View style={tableColType}>
                            <Text style={tableCell}>
                              {traveler?.type || "Type"}
                            </Text>
                          </View>
                          <View style={tableColNumber}>
                            <Text style={tableCell}>
                              {(traveler?.identityDocuments &&
                                traveler?.identityDocuments[0]?.documentNumber?.toUpperCase()) ||
                                "Domestic Flight"}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  ) : (
                    <View>
                      {passengerData?.map((data, index) => (
                        <View style={tableRow} key={index}>
                          <View style={tableColName}>
                            <Text style={tableCell}>
                              {data?.gender === "Male" ? (
                                <Text>
                                  {data?.gender === "Male" &&
                                  data?.type === "ADT" ? (
                                    <Text>
                                      MR {data?.fName?.toUpperCase()}{" "}
                                      {data?.lName?.toUpperCase()}
                                    </Text>
                                  ) : (
                                    <Text>
                                      MSTR {data?.fName?.toUpperCase()}{" "}
                                      {data?.lName?.toUpperCase()}
                                    </Text>
                                  )}
                                </Text>
                              ) : (
                                <Text>
                                  {data?.gender === "Female" &&
                                  data?.type === "ADT" ? (
                                    <Text>
                                      MS {data?.fName?.toUpperCase()}{" "}
                                      {data?.lName?.toUpperCase()}
                                    </Text>
                                  ) : (
                                    <Text>
                                      MISS {data?.fName?.toUpperCase()}{" "}
                                      {data?.lName?.toUpperCase()}
                                    </Text>
                                  )}
                                </Text>
                              )}
                            </Text>
                          </View>
                          <View style={tableColGender}>
                            <Text style={tableCell}>
                              {data?.gender?.toUpperCase()}
                            </Text>
                          </View>
                          <View style={tableColType}>
                            <Text style={tableCell}>
                              {data?.type === "ADT"
                                ? "ADULT"
                                : data.type === "INF"
                                ? "INFANT"
                                : "CHILD"}
                            </Text>
                          </View>
                          <View style={tableColNumber}>
                            <Text style={tableCell}>{data?.passNo}</Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              )}
            </View>
          </View>
          <View>
            <Text
              style={{
                fontSize: "12px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              FLIGHT ITINERARIES
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableColflight}>
                  <Text style={tableCell}>Flight </Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Departure From</Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Arrival To</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Depart At</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Arrive At</Text>
                </View>
                <View style={tableColflight}>
                  <Text style={tableCell}>Info</Text>
                </View>
              </View>

              {flightData?.map((data, index) => (
                <View style={tableRow} key={index}>
                  <View style={tableColflight}>
                    <Text style={tableCell}>
                      <AirlineNames code={data.airlineCode} />{" "}
                      {data?.airlineCode}-{data?.flightNumber}
                      <br />
                      {data?.aircraftTypeName}
                    </Text>
                  </View>
                  <View style={tableColfromto}>
                    <Text style={tableCell}>
                      ({data.fromAirportCode}){" "}
                      <Address code={data.fromAirportCode} />
                      <br />
                      {data?.departureTerminalName || null}{" "}
                      {data?.departureGate
                        ? `Gate: ${data?.departureGate || null}`
                        : null}
                    </Text>
                  </View>
                  <View style={tableColfromto}>
                    <Text style={tableCell}>
                      ({data.toAirportCode}){" "}
                      <Address code={data.toAirportCode} />
                      <br />
                      {data?.arrivalTerminalName || null}{" "}
                      {data?.arrivalGate
                        ? `Gate: ${data?.arrivalGate || null}`
                        : null}
                    </Text>
                  </View>
                  <View style={tableColtime}>
                    <Text style={tableCell}>
                      {data.departureDate} {data.departureTime?.slice(0, 5)}
                    </Text>
                  </View>
                  <View style={tableColtime}>
                    <Text style={tableCell}>
                      {data.arrivalDate} {data.arrivalTime?.slice(0, 5)}
                    </Text>
                  </View>
                  <View style={tableColflight}>
                    <Text style={tableCellInfo}>
                      {data?.cabinTypeName}-{data?.bookingClass}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          {check === "1" ? (
            <View>
              <View>
                <Text
                  style={{
                    fontSize: "12px",
                    color: "#03437B",
                    padding: "20px 0 10px 0",
                  }}
                >
                  PRICE BREAKDOWN
                </Text>
                <View style={table}>
                  <View style={{ margin: "auto", flexDirection: "row" }}>
                    <View style={tableCol}>
                      <Text style={tableCell}>Passenger</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Base Fare</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Tax</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Sub Total</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Baggage</Text>
                    </View>
                  </View>
                  {fareTotal?.map((data, index) => (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {data?.pricedTravelerType === "ADT"
                            ? "Adult"
                            : data?.pricedTravelerType === "INF"
                            ? "Infant"
                            : "Child"}
                          {" X "}
                          {data?.travelerIndices?.length || 1}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {parseInt(data?.totals?.subtotal) *
                            parseInt(data?.travelerIndices?.length || 1)}{" "}
                          SAR
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {parseInt(data?.totals?.taxes) *
                            parseInt(data?.travelerIndices?.length || 1)}{" "}
                          SAR
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {parseInt(data?.totals?.total) *
                            parseInt(data?.travelerIndices?.length || 1)}{" "}
                          SAR
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {data?.fareConstruction[0]?.checkedBaggageAllowance
                            ?.totalWeightInKilograms
                            ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                            : null}
                          {data?.fareConstruction[0]?.checkedBaggageAllowance
                            ?.maximumPieces
                            ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                            : null}
                        </Text>
                      </View>
                    </View>
                  ))}

                  <View style={tableRow}>
                    <View style={tableCol2}>
                      <Text
                        style={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          margin: "3px",
                        }}
                      >
                        Grand Total:{" "}
                        {commaNumber(allData?.payments?.flightTotals[0]?.total)}{" "}
                        SAR
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "70%",
                  fontSize: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "20px 0px",
                  border: "1px solid gray",
                  padding: "10px",
                }}
              >
                <View>
                  <Text style={{ marginBottom: "5px" }}>
                    Base fare total amount
                  </Text>
                  <Text style={{ marginBottom: "5px" }}>Tax</Text>
                  {copy === "Agent Invoice" ? (
                    <View>
                      <Text style={{ marginBottom: "5px" }}>Discount</Text>
                      <Text>
                        ---------------------------------------------------------------------------------
                      </Text>
                      <Text>Agent Total Ticket Fare Amount</Text>
                    </View>
                  ) : (
                    <View>
                      <Text>
                        ---------------------------------------------------------------------------------
                      </Text>
                      <Text>Customer Total Ticket Fare Amount</Text>
                    </View>
                  )}
                </View>
                <View>
                  <Text style={{ marginBottom: "5px" }}>
                    {commaNumber(allData?.payments?.flightTotals[0]?.subtotal)}{" "}
                    SAR
                  </Text>
                  <Text style={{ marginBottom: "5px" }}>
                    {commaNumber(allData?.payments?.flightTotals[0]?.taxes)} SAR
                  </Text>
                  <Text style={{ marginBottom: "5px" }}>
                    {commaNumber(allData?.payments?.flightTotals[0]?.total)} SAR
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            ""
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PDFPageDesign;
