import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useRef } from "react";
import Slider from "react-slick";
import img9 from "../../images/TrendingDeals/img1.webp";
import img8 from "../../images/TrendingDeals/img2.webp";
import img7 from "../../images/TrendingDeals/img3.webp";
import img6 from "../../images/TrendingDeals/img4.webp";
import img5 from "../../images/TrendingDeals/img5.webp";
import img4 from "../../images/TrendingDeals/img6.webp";
import img3 from "../../images/TrendingDeals/img7.webp";
import img2 from "../../images/TrendingDeals/img8.webp";
import img1 from "../../images/TrendingDeals/img9.webp";
import img0 from "../../images/TrendingDeals/img10.webp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./MoreDeals.css";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";

export const MoreDeals = () => {
  const [moreDeals, setMoreDeals] = useState(null);
  const slider = useRef(null);

  useEffect(() => {
    let url = `https://api.flyjatt.com/v1/CMS/index.php?alloffer`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setMoreDeals(data);
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          icon: "error",
          title: "Failed to Fetch!",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Again",
        });
      });
  }, []);

  const len = moreDeals?.length > 4 ? 4 : moreDeals?.length;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: len,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <Box
        sx={{
          margin: "50px 0px 10px",
          width: { lg: "100%", md: "85vw", sm: "90vw", xs: "90vw" },
        }}
        className="full-screen-slider"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "26px",
                color: "var(--secondary-color)",
                fontWeight: "500",
                paddingLeft: "5px",
                margin: "10px 0 20px",
              }}
            >
              More Deals For {new Date().getFullYear()}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Box
              sx={{
                border: "1px solid var(--primary-color)",
                borderRadius: "50%",
                background: "var(--white)",
                color: "var(--primary-color)",
                height: "30px",
                width: "30px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  color: "var(--primary-color)",
                  background: "var(--white)",
                  justifyContent: "center",
                },
              }}
              onClick={() => slider?.current?.slickPrev()}
            >
              <ArrowBackIosIcon fontSize="20px" />
            </Box>
            <Box
              sx={{
                border: "1px solid var(--secondary-color)",
                borderRadius: "50%",
                background: "var(--secondary-color)",
                color: "var(--white)",
                height: "30px",
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all .5 ease",
                "&:hover": {
                  color: "var(--white)",
                  justifyContent: "end",
                },
              }}
              onClick={() => slider?.current?.slickNext()}
            >
              <ArrowForwardIosIcon fontSize="20px" />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ".slick-slide > div": {
              margin: "1px 10px",
              width: "auto",
            },
            ".slick-list": {
              margin: "0px -5px",
            },
          }}
        >
          <Slider ref={slider} {...settings}>
            {moreDeals
              ? moreDeals?.map((item, index) => (
                  <Box key={index} className="more-deals-slider-item">
                    <Box
                      sx={{
                        display: "flex",
                        height: "350px",
                        borderRadius: "5px",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <img
                          src={`https://api.ticketfactory.sa${item?.image}`}
                          alt="..."
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                        <span
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          <a
                            style={{ color: "white", textDecoration: "none" }}
                            href="https://www.google.com/search?q=vvv%20vn"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Uxxsl{item.caption}
                          </a>
                        </span>
                      </Box>
                    </Box>
                  </Box>
                ))
              : null}
          </Slider>
        </Box>
      </Box>
    </Container>
  );
};
