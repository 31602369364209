import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  createTheme,
  Grid,
  Pagination,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../Header/Header";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import commaNumber from "comma-number";
import { format } from "date-fns";
import Loader from "../../images/loader/Render.gif";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222222",
      darker: "#222222",
    },
  },
});

const TransectionReport = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const [isLoading, setIsLoading] = useState(false);
  const [allLedgerData, setAllLedgerData] = useState([]);
  const [ledgerData, setLedgerData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [type, setType] = useState();
  const [page, setPage] = useState(1);
  const size = 25;
  const handlePageChange = (event, value) => {
    setPage(value);
    setLedgerData(allLedgerData?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSelect = (e) => {
    setType(e.target.value);
    if (e.target.value?.toLowerCase() === "purchase") {
      const filter = allLedgerData?.filter((item) => item?.purchase > "0");
      setPageCount(Math.ceil(filter.length / size));
      setLedgerData(filter?.slice(0, size));
    } else if (e.target.value?.toLowerCase() === "deposit") {
      const filter = allLedgerData?.filter((item) => item?.deposit > "0");
      setPageCount(Math.ceil(filter.length / size));
      setLedgerData(filter?.slice(0, size));
    } else if (e.target.value?.toLowerCase() === "return") {
      const filter = allLedgerData?.filter((item) => item?.return > "0");
      setPageCount(Math.ceil(filter.length / size));
      setLedgerData(filter?.slice(0, size));
    } else {
      setPageCount(Math.ceil(allLedgerData.length / size));
      setLedgerData(allLedgerData?.slice(0, size));
    }
  };
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = allLedgerData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setLedgerData(filterData);
    } else if (searchInput === "") {
      setLedgerData(allLedgerData);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const url = `https://api.flyjatt.com/v1/Report/ClientLedger.php?all&agentId=${agentId}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setPageCount(Math.ceil(data?.length / size));
        setLedgerData(data?.slice(0, size));
        setAllLedgerData(data);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Failed",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate("/user/dashboard");
        });
      });
  }, []);

  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allLedgerData.filter((item) => {
      return (
        new Date(item?.created_at).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created_at).getDate() <= new Date(endDate).getDate()
      );
    });
    setLedgerData(filterData);
  };

  if (isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <Box
          sx={{
            margin: "0px 0px 5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "var(--secondary-color)",
              fontSize: { xs: 18, sm: 18, md: "24px" },
              fontWeight: "500",
            }}
          >
            General Ledger
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: "var(--primary-color)",
                height: "35px",
                borderRadius: "20px",
                input: {
                  border: "none",
                  background: "none",
                  outline: "none",
                  float: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "normal",
                  transition: "all 0.4s ease-in-out",
                  lineHeight: "35px",
                  width: "0px",
                },
                "input::placeholder": { color: "white" },
                "&:hover input": {
                  width: { xs: "150px", sm: "235px" },
                  padding: "0 10px",
                },
                "&:hover button": {
                  background: "white",
                  color: "black",
                },
                button: {
                  border: "none",
                  color: "white",
                  float: "right",
                  minHeight: "0px",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  background: "var(--primary-color)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "all 0.4s ease-in-out",
                  cursor: "pointer",
                  textDecoration: "none",
                },
              }}
            >
              <input
                type="text"
                placeholder="Search....."
                onChange={handelSearchItems}
              />
              <button>
                <SearchIcon />
              </button>
            </Box>

            <Box display={{ xs: "none", sm: "block" }}>
              <select
                onChange={handleSelect}
                style={{
                  width: "150px",
                  borderRadius: "4px",
                  outline: "none",
                  height: "100%",
                  padding: "5px 10px",
                  color: "var(--primary-color)",
                  fontWeight: "normal",
                  borderColor: "var(--secondary-color)",
                  fontSize: "14px",
                }}
              >
                <option value="">&nbsp;Show All</option>
                <option value="purchase">&nbsp;Purchase</option>
                <option value="deposit">&nbsp;Deposit</option>
                <option value="return">&nbsp;Return</option>
              </select>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mb: "5px" }}>
          <form onSubmit={handleFilter}>
            <Grid
              container
              spacing={1}
              alignItems="end"
              justifyContent="end"
              sx={{
                zIndex: "9999",
                input: {
                  width: "100%",
                  padding: "5px 10px",
                  border: "1px solid var(--secondary-color)",
                  borderRadius: "5px",
                  cursor: "pointer",
                  outline: "none",
                },
              }}
            >
              <Grid style={{ position: "relative" }} item xs={12} sm={6} md={4}>
                <Box>
                  <input
                    title="Enter Start Date"
                    required
                    id="startDate"
                    name="startDate"
                    type="text"
                    value={startDate}
                    readOnly
                    onClick={() => {
                      setOpenEndDate(false);
                      setOpenStartDate((prev) => !prev);
                    }}
                  />
                </Box>
                {openStartDate && (
                  <Calendar
                    color={"var(--primary-color)"}
                    date={new Date(startDate)}
                    onChange={(date) => {
                      setStartDate(format(new Date(date), "dd MMM yyyy"));
                      setOpenStartDate(false);
                    }}
                    maxDate={new Date()}
                    months={1}
                    className="new-dashboard-calendar"
                    name="dashboard-calendar"
                  />
                )}
              </Grid>

              <Grid style={{ position: "relative" }} item xs={12} sm={6} md={4}>
                <Box>
                  <input
                    title="Enter End Date"
                    required
                    id="endDate"
                    name="endDate"
                    type="text"
                    value={endDate}
                    readOnly
                    onClick={() => {
                      setOpenEndDate((prev) => !prev);
                      setOpenStartDate(false);
                    }}
                  />
                </Box>
                {openEndDate && (
                  <Calendar
                    color="var(--primary-color)"
                    date={new Date(endDate)}
                    onChange={(date) => {
                      setEndDate(format(new Date(date), "dd MMM yyyy"));
                      setOpenEndDate(false);
                    }}
                    months={1}
                    className="new-dashboard-calendar"
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  gap: "5px",
                }}
              >
                <Button
                  title="Click to Filter"
                  sx={{
                    background: "var(--secondary-color)",
                    color: "white",
                    padding: "5px 20px !important",
                    fontSize: "12px !important",
                    width: "100%",
                    "&:hover": {
                      background: "var(--secondary-color)",
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  FILTER
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  gap: "5px",
                }}
              >
                <Button
                  title="Click to Reset"
                  sx={{
                    background: "var(--secondary-color)",
                    color: "white",
                    padding: "5px 20px !important",
                    fontSize: "12px !important",
                    width: "100%",
                    "&:hover": {
                      background: "var(--secondary-color)",
                      color: "white",
                    },
                  }}
                  onClick={() => setLedgerData(allLedgerData)}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box>
          <Box
            className="table-wrapper"
            sx={{
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "var(--secondary-color)",
                borderRadius: "0px",
              },
              height: "calc(100vh - 220px)",
              overflowY: "scroll",
            }}
          >
            <table className="responsive-table">
              <thead>
                <tr>
                  <th width="15%">Type</th>
                  <th width="10%">TransactionId</th>
                  <th width="15%">Date</th>
                  <th width="40%">Remarks</th>
                  <th width="10%">Amount</th>
                  <th width="10%">Balance</th>
                </tr>
              </thead>
              <tbody>
                {ledgerData
                  ? ledgerData?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item?.deposit > "0" ? (
                            <button
                              style={{
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                width: "100%",
                                backgroundColor: "#177c33",
                                borderRadius: "4px",
                              }}
                            >
                              Deposit
                            </button>
                          ) : item?.purchase > "0" ? (
                            <button
                              style={{
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                borderRadius: "4px",
                                width: "100%",
                                backgroundColor: "#ed1c24",
                              }}
                            >
                              Purchase
                            </button>
                          ) : item?.void > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#235f83",
                                borderRadius: "4px",
                              }}
                            >
                              Void
                            </button>
                          ) : item?.refund > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#7a64ff",
                                borderRadius: "4px",
                              }}
                            >
                              Refund
                            </button>
                          ) : item?.other > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "green",
                                borderRadius: "4px",
                              }}
                            >
                              Credit
                            </button>
                          ) : item?.other < "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "red",
                                borderRadius: "4px",
                              }}
                            >
                              Debit
                            </button>
                          ) : item?.servicefee > "0" ? (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "#0b8668",
                                borderRadius: "4px",
                              }}
                            >
                              Service Fee
                            </button>
                          ) : (
                            <button
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "5px",
                                color: "#fff",
                                backgroundColor: "orange",
                                borderRadius: "4px",
                              }}
                            >
                              Rejected
                            </button>
                          )}
                        </td>
                        <td>{item?.reference}</td>
                        <td>
                          {item?.created_at
                            ? format(
                                new Date(item?.created_at),
                                "dd MMM yyyy hh:mm a"
                              )
                            : "Transaction Date"}
                        </td>
                        <td>{item?.details || "-"}</td>
                        <td>
                          {item?.deposit > "0"
                            ? "+" + commaNumber(Number(item?.deposit))
                            : item?.purchase > "0"
                            ? "-" + commaNumber(Number(item?.purchase))
                            : item?.returnMoney > "0"
                            ? "+" + commaNumber(Number(item?.returnMoney))
                            : item?.loan > "0"
                            ? "+" + commaNumber(Number(item?.loan))
                            : item?.void > "0"
                            ? "+" + commaNumber(Number(item?.void))
                            : item?.refund > "0"
                            ? "+" + commaNumber(Number(item?.refund))
                            : item?.reissue > "0"
                            ? "+" + commaNumber(Number(item?.reissue))
                            : item?.other > "0"
                            ? "+" + commaNumber(Number(item?.other))
                            : item?.other < "0"
                            ? "" + commaNumber(Number(item?.other))
                            : item?.servicefee > "0"
                            ? "+" + commaNumber(Number(item?.servicefee))
                            : ""}
                          {""}
                          SAR
                        </td>

                        <td>
                          {commaNumber(item?.lastAmount) || "Balance"} SAR
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </Box>
          <Box
            sx={{
              width: "100%",
              py: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <Typography style={{ fontSize: "15px", color: "#222222" }}>
              Showing Results 1 - 10 of 20
            </Typography> */}
            <ThemeProvider theme={theme}>
              <Stack spacing={2}>
                <Pagination
                  size="small"
                  count={pageCount}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </ThemeProvider>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TransectionReport;
