import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Divider, Tooltip, Typography } from "@mui/material";
import { Link, Outlet, NavLink, useNavigate } from "react-router-dom";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddCardIcon from "@mui/icons-material/AddCard";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import { HiMenuAlt1 } from "react-icons/hi";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import secureLocalStorage from "react-secure-storage";
// import "./DashboardHome.css";
import useAuthentication from "./../../hooks/useAuthentication";
import Header from "../Header/Header";
import Logo from "../../images/logo/logo.png";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import TollIcon from "@mui/icons-material/Toll";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import BallotIcon from "@mui/icons-material/Ballot";

const DeskTopDrawer = (props) => {
  const { logout } = useAuthentication();
  const navigate = useNavigate();
  const [isCollapse, setIsCollapse] = useState(false);
  const drawerWidth = isCollapse ? 80 : 200;
  const headingHeight = 95;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const [companyLogo, setCompanyLogo] = useState();
  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;
  let staffId = users?.user?.staffId;

  const drawer = (
    <Box
      sx={{
        background: "#fff !important",
        overflowX: "hidden",
        height: "100vh !important",
        "&::-webkit-scrollbar-thumb": {
          display: "none",
        },
        "&::-webkit-scrollbar-track": {
          display: "none",
        },
        "&::-webkit-scrollbar": {
          width: "0",
          display: "none",
        },
        ".active": {
          color: "#222222 !important",
          background: "#fff !important",
          width: "100% !important",
          padding: "5px 10px",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          transition: "all 0.5s ease-in-out",
        },
        ".active>svg": {
          color: "var(--primary-color)",
        },
      }}
    >
      {/* //todo: company Logo section */}
      <Box
        mx="auto"
        my={1}
        onClick={() => setMobileOpen(!mobileOpen)}
        style={{
          border: "none",
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          width: "110px", //reduce logo size from 150px to 110px
          height: "60px",
          visibility: isCollapse ? "hidden" : "visible",
        }}
      >
        <Link to={"/dashboard/dashboardHome"} className="link-logo">
          <Box height="100%" mt={1}>
            <img
              src={Logo}
              style={{ width: "100%", objectFit: "cover" }}
              alt={"Company Name"}
            />
          </Box>
        </Link>
      </Box>
      {/*//todo:menu section */}
      <Box
        sx={{
          position: "relative",
          background: "#222222 !important",
          // borderTopRightRadius: "10px",
          height: "100vh !important",
          overflowX: "hidden !important",
          "&::-webkit-scrollbar-thumb": {
            display: "none",
            width: "0px",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiDivider-wrapper": {
            fontSize: "12px",
            color: "#5D7F9E !important",
          },
          svg: {
            fontSize: "20px",
          },
        }}
      >
        <Box
          sx={{
            mt: "30px",
            position: "relative",
            a: {
              textDecoration: "none",
              fontSize: "13px",
              color: "white",
              width: "100%",
              margin: isCollapse ? "10px 8px" : "10px 8px",
              padding: isCollapse ? "5px 10px" : "5px 0px 5px 20px",
              display: "flex",
              gap: "8px",
              justifyContent: isCollapse ? "center" : "start",
              alignItems: "end",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              transition: "all 0.5s ease-in-out",
            },
          }}
        >
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to="/user/dashboard"
          >
            {isCollapse ? (
              <Tooltip title="Home">
                <HomeIcon />
              </Tooltip>
            ) : (
              <>
                <HomeIcon />
                <span> Home</span>
              </>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to="/user/home"
          >
            {isCollapse ? (
              <Tooltip title="Dashboard">
                <DashboardIcon />
              </Tooltip>
            ) : (
              <>
                <DashboardIcon />
                <span> Dashboard</span>
              </>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to={"/user/queues"}
          >
            {isCollapse ? (
              <Tooltip title="AirTicket">
                <TollIcon />
              </Tooltip>
            ) : (
              <>
                <TollIcon />
                <span> My Booking</span>
              </>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to={"/user/pnrimport"}
          >
            {isCollapse ? (
              <Tooltip title=" PNR Import">
                <CrisisAlertIcon />
              </Tooltip>
            ) : (
              <>
                <CrisisAlertIcon />
                <span> PNR Import</span>
              </>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to={"/user/traveller"}
          >
            {isCollapse ? (
              <Tooltip title=" PNR Import">
                <ManageAccountsIcon />
              </Tooltip>
            ) : (
              <>
                <ManageAccountsIcon />
                <span> Traveler</span>
              </>
            )}
          </NavLink>

          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to="/user/deposit"
          >
            {isCollapse ? (
              <Tooltip title=" PNR Import">
                <AccountBalanceIcon />
              </Tooltip>
            ) : (
              <>
                <AccountBalanceIcon />
                <span> Deposit</span>
              </>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to="/user/staff"
          >
            {isCollapse ? (
              <Tooltip title=" PNR Import">
                <PeopleAltIcon />
              </Tooltip>
            ) : (
              <>
                <PeopleAltIcon />
                <span> My Staffs</span>
              </>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to={"/user/transection"}
          >
            {isCollapse ? (
              <Tooltip title=" PNR Import">
                <BallotIcon />
              </Tooltip>
            ) : (
              <>
                <BallotIcon />
                <span> Transection</span>
              </>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setMobileOpen(false);
            }}
            to={"/user/ledger"}
          >
            {isCollapse ? (
              <Tooltip title=" PNR Import">
                <AssessmentIcon />
              </Tooltip>
            ) : (
              <>
                <AssessmentIcon />
                <span> Ledger</span>
              </>
            )}
          </NavLink>

          {/* -------TF-------- */}

          <Box
            sx={{
              fontSize: "13px",
              color: "white",
              width: "100%",
              margin: isCollapse ? "5px 15px" : "5px 8px",
              padding: isCollapse ? "5px 10px" : "5px 0px 5px 20px",
              display: "flex",
              gap: "5px",
              justifyContent: "start",
              alignItems: "end",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              transition: "all 0.5s ease-in-out",
              cursor: "pointer",
            }}
            onClick={logout}
          >
            {isCollapse ? (
              <Tooltip title="Logout">
                <ExitToAppIcon />
              </Tooltip>
            ) : (
              <>
                <ExitToAppIcon />
                <span>Logout</span>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <AppBar
        position="fixed"
        sx={{
          width: {
            md: `calc(100% - ${drawerWidth}px)`,
          },
          ml: { md: `${drawerWidth}px`, sm: `${drawerWidth}px` },
          height: "fit-content",
          backgroundColor: "#fff !important",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            maxHeight: "fit-content",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            sx={{
              mb: { lg: 0, md: 0, sm: 0, xs: 0 },
              mt: { lg: 0, md: 0, sm: 1, xs: 2 },
            }}
          >
            <Box
              className="mobileLogo"
              sx={{
                display: { md: "none", sm: "block" },
              }}
            >
              <Box width="150px" height="100%">
                <img
                  src={Logo}
                  style={{ width: "100%", objectFit: "cover" }}
                  alt={"Company Name"}
                />
              </Box>
            </Box>
            <Box textAlign={"right"}>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  color: "#222222",
                  display: { xs: "block", sm: "block", md: "none" },
                  paddingRight: "0px",
                }}
              >
                <HiMenuAlt1 id="mobileHumbarger" style={{ fontSize: "35px" }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          // width: { md: drawerWidth, sm: drawerWidth },
          width: { md: drawerWidth },
          flexShrink: { md: 0, sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            // display: { xs: "block", md: "none", sm: "none" },
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default DeskTopDrawer;
