import React, { useState } from "react";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import CountryList from "../Shared/CountryList";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const VisaForm = ({ handleCloseVisa }) => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    agentId,
    fullname: "",
    nationality: "",
    depcountry: "",
    descountry: "",
    pupose: "",
    stayday: "",
    phone: "",
    email: "",
    termsAccepted: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const url = "https://api.flyjatt.com/v1/Visa/index.php?add";
    const body = JSON.stringify(formState);
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body,
    };
    fetch(url, config)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data?.status?.toLowerCase() === "success") {
          handleCloseVisa();
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Visa added Successfully",
            confirmationButtonText: "ok",
          }).then(() => {
            navigate(0);
          });
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        console.error(error);
        handleCloseVisa();
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed To Add",
          confirmationButtonText: "Try Again",
        }).then(() => {
          navigate(0);
        });
      });
    console.log(formState);
  };
  return (
    <Box
      sx={{
        label: {
          color: "var(--secondary-color)",
          fontWeight: "500",
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Typography
          sx={{
            width: "100%",
            textAlign: "center",
            fontSize: { xs: "20px", sm: "24px" },
            fontWeight: "500",
            color: "black",
          }}
        >
          Visa Inquiry
        </Typography>
        <Grid container spacing={1} sx={{ mt: "10px" }}>
          <Grid item md={8} sm={12} xs={12}>
            <label htmlFor="fullname">Full Name</label>
            <Box sx={{ position: "relative" }}>
              <input
                placeholder="Enter Your Full Name"
                name="fullname"
                type="text"
                id="fullname"
                onChange={handleChange}
                style={{
                  height: "40px",
                  width: "100%",
                  outline: "none",
                  border: "none",
                  borderRadius: "4px",
                  padding: "0px 10px",
                  color: "var(--secondary-color)",
                  background: "white",
                  fontSize: "16px",
                }}
              />
              <PermIdentityOutlinedIcon
                sx={{
                  color: "var(--primary-color)",
                  position: "absolute",
                  top: "50%",
                  right: "5px",
                  transform: "translate(-5px,-50%)",
                }}
              />
            </Box>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <label htmlFor="nationality">Select Nationality</label>
            <select
              required
              name="nationality"
              id="nationality"
              onChange={handleChange}
              style={{
                height: "40px",
                width: "100%",
                outline: "none",
                border: "none",
                borderRadius: "4px",
                padding: "0px 10px",
                color: "var(--secondary-color)",
                background: "white",
                fontSize: "16px",
              }}
            >
              <option value="">Select Nationality</option>
              {CountryList.map((country) => {
                return <option value={country.code}>{country.name}</option>;
              })}
            </select>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <label htmlFor="depcountry">Departure Country</label>
            <select
              required
              name="depcountry"
              id="depcountry"
              onChange={handleChange}
              style={{
                height: "40px",
                width: "100%",
                outline: "none",
                border: "none",
                borderRadius: "4px",
                padding: "0px 10px",
                color: "var(--secondary-color)",
                background: "white",
                fontSize: "16px",
              }}
            >
              <option value="">Select</option>
              {CountryList.map((country, index) => {
                return (
                  <option key={index} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <label htmlFor="descountry">Destination country</label>
            <select
              required
              name="descountry"
              id="descountry"
              onChange={handleChange}
              style={{
                height: "40px",
                width: "100%",
                outline: "none",
                border: "none",
                borderRadius: "4px",
                padding: "0px 10px",
                color: "var(--secondary-color)",
                background: "white",
                fontSize: "16px",
              }}
            >
              <option value="">Select</option>
              {CountryList.map((country) => {
                return <option value={country.code}>{country.name}</option>;
              })}
            </select>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label htmlFor="pupose">Purpose Of Visit </label>
            <Box sx={{ position: "relative" }}>
              <input
                required
                name="pupose"
                id="pupose"
                placeholder="Purpose Of Visit"
                onChange={handleChange}
                style={{
                  height: "40px",
                  width: "100%",
                  outline: "none",
                  border: "none",
                  borderRadius: "4px",
                  padding: "0px 10px",
                  color: "var(--secondary-color)",
                  background: "white",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label htmlFor="stayday">Duration Of Stay</label>
            <Box sx={{ position: "relative" }}>
              <input
                required
                name="stayday"
                id="stayday"
                placeholder="Duration Of Stay"
                onChange={handleChange}
                style={{
                  height: "40px",
                  width: "100%",
                  outline: "none",
                  border: "none",
                  borderRadius: "4px",
                  padding: "0px 10px",
                  color: "var(--secondary-color)",
                  background: "white",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label htmlFor="phone">Contact No</label>
            <Box sx={{ position: "relative" }}>
              <input
                required
                name="phone"
                id="phone"
                placeholder="Enter Phone Number"
                onChange={handleChange}
                style={{
                  height: "40px",
                  width: "100%",
                  outline: "none",
                  border: "none",
                  borderRadius: "4px",
                  padding: "0px 10px",
                  color: "var(--secondary-color)",
                  background: "white",
                  fontSize: "16px",
                }}
              />
              <CallOutlinedIcon
                sx={{
                  color: "var(--primary-color)",
                  position: "absolute",
                  top: "50%",
                  right: "5px",
                  transform: "translate(-5px,-50%)",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label htmlFor="email">Email</label>
            <Box sx={{ position: "relative" }}>
              <input
                required
                type="email"
                name="email"
                id="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                placeholder="Enter Email ID"
                onChange={handleChange}
                style={{
                  height: "40px",
                  width: "100%",
                  outline: "none",
                  border: "none",
                  borderRadius: "4px",
                  padding: "0px 10px",
                  color: "var(--secondary-color)",
                  background: "white",
                  fontSize: "16px",
                }}
              />
              <EmailOutlinedIcon
                sx={{
                  color: "var(--primary-color)",
                  position: "absolute",
                  top: "50%",
                  right: "5px",
                  transform: "translate(-5px,-50%)",
                }}
              />
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="termsAccepted"
                  checked={formState.termsAccepted}
                  onChange={handleChange}
                />
              }
              label="I agree to the terms and conditions"
            />
          </Grid>
          <Box sx={{ width: "100%", textAlign: "right" }}>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              sx={{ width: { xs: "100%", sm: "" } }}
              style={{ color: "white", background: "var(--primary-color)" }}
            >
              {isLoading ? "Processing" : "Submit"}
            </Button>
          </Box>
        </Grid>
      </form>
    </Box>
  );
};

export default VisaForm;
