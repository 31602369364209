import {
  Box,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  Pagination,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import commaNumber from "comma-number";
import Loader from "../../../images/loader/Render.gif";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import "./Queues.css";
import { addDays, format } from "date-fns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../../Header/Header";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222222",
      darker: "#222222",
    },
  },
});
const Queues = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem("user-info");
  let agentId = users?.user?.agentId;

  const [isLoading, setIsLoading] = useState(false);
  const [queuesData, setQueuesData] = useState([]);
  const [allQueuesData, setAllQueuesData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 30;
  const handlePageChange = (event, value) => {
    setPage(value);
    setQueuesData(allQueuesData?.slice((value - 1) * size, size * value));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://api.flyjatt.com/v1/Queues/index.php?agentId=${agentId}&all`)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(Math.ceil(data?.length / size));
        setQueuesData(data?.slice(0, size));
        setAllQueuesData(data);
        setIsLoading(false);
      });
    //todo: end of fetch all data
  }, [agentId]);
  //  short functionality handle
  const handleChangeOption = (e) => {
    const status = e.target.value?.replaceAll(/\s/g, "")?.toLowerCase();
    if (status !== "all") {
      const filterData = allQueuesData.filter((item) => {
        return item?.Status?.replaceAll(/\s/g, "")?.toLowerCase() === status;
      });

      setQueuesData(filterData);
    } else {
      setQueuesData(allQueuesData);
    }
  };

  const handelSearchItems = (e) => {
    const searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = allQueuesData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setQueuesData(filterData);
    } else if (searchInput === "") {
      setQueuesData(allQueuesData);
    }
  };

  const sendToQueuesDetails = (agentId, BookingId, tripType, pnr, gds) => {
    navigate("/user/queuesdetail", {
      state: {
        agentId,
        BookingId,
        tripType,
        pnr,
        gds,
        path: "queues",
      },
    });
  };

  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allQueuesData.filter((item) => {
      return (
        new Date(item?.created_at).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created_at).getDate() <= new Date(endDate).getDate()
      );
    });
    setQueuesData(filterData);
  };

  if (isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 3, md: 2 } }}>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  justifyContent: "space-between",
                  m: "0px 0px 5px",
                }}
              >
                <Typography
                  sx={{
                    mt: { xs: 1, sm: 0 },
                    color: "var(--secondary-color)",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  My Booking
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      background: "var(--primary-color)",
                      height: "35px",
                      borderRadius: "20px",
                      input: {
                        border: "none",
                        background: "none",
                        outline: "none",
                        float: "left",
                        padding: 0,
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "normal",
                        transition: "all 0.4s ease-in-out",
                        lineHeight: "35px",
                        width: "0px",
                      },
                      "input::placeholder": { color: "white" },
                      "&:hover input": {
                        width: { xs: "180px", sm: "235px" },
                        padding: "0 10px",
                      },
                      "&:hover button": {
                        background: "white",
                        color: "black",
                      },
                      button: {
                        border: "none",
                        color: "white",
                        float: "right",
                        minHeight: "0px",
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        background: "var(--primary-color)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "all 0.4s ease-in-out",
                        cursor: "pointer",
                        textDecoration: "none",
                      },
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Search....."
                      onChange={handelSearchItems}
                    />
                    <button>
                      <SearchIcon />
                    </button>
                  </Box>
                  <select
                    onChange={handleChangeOption}
                    style={{
                      width: "150px",
                      borderRadius: "4px",

                      padding: "5px 10px",
                      color: "var(--primary-color)",
                      fontWeight: "500",
                      borderColor: "var(--secondary-color)",
                    }}
                  >
                    <option value="all">&nbsp;Show All</option>
                    <option value="hold">&nbsp;Hold</option>
                    <option value="Issue In Processing">
                      &nbsp;Issue In Processing
                    </option>
                    <option value="Ticketed">&nbsp;Ticketed</option>

                    <option value="Void In Processing">
                      &nbsp;Void In Processing
                    </option>
                    <option value="Voided">&nbsp;Voided</option>
                    <option value="Void Rejected">&nbsp;Void Rejected</option>

                    <option value="Reissue In Processing">
                      &nbsp;Reissue In Processing
                    </option>
                    <option value="Reissued">&nbsp;Reissued</option>
                    <option value="Reissue Rejected">
                      &nbsp;Reissue Rejected
                    </option>

                    <option value="Refund In Processing">
                      &nbsp;Refund In Processing
                    </option>
                    <option value="Refunded">&nbsp;Refunded</option>
                    <option value="Refund Rejected">
                      &nbsp;Refund Rejected
                    </option>
                    <option value="Issue Rejected">&nbsp;Issue Rejected</option>
                    <option value="cancelled">&nbsp;Cancelled</option>
                  </select>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mb: "5px" }}>
            <form onSubmit={handleFilter}>
              <Grid
                container
                spacing={1}
                alignItems="end"
                justifyContent="end"
                sx={{
                  zIndex: "9999",
                  input: {
                    width: "100%",
                    padding: "5px 10px",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "5px",
                    cursor: "pointer",
                    outline: "none",
                  },
                }}
              >
                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box>
                    <input
                      title="Enter Start Date"
                      required
                      id="startDate"
                      name="startDate"
                      type="text"
                      value={startDate}
                      readOnly
                      onClick={() => {
                        setOpenEndDate(false);
                        setOpenStartDate((prev) => !prev);
                      }}
                    />
                  </Box>
                  {openStartDate && (
                    <Calendar
                      color={"var(--primary-color)"}
                      date={new Date(startDate)}
                      onChange={(date) => {
                        setStartDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenStartDate(false);
                      }}
                      maxDate={new Date()}
                      months={1}
                      className="new-dashboard-calendar"
                      name="dashboard-calendar"
                    />
                  )}
                </Grid>

                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box>
                    <input
                      title="Enter End Date"
                      required
                      id="endDate"
                      name="endDate"
                      type="text"
                      value={endDate}
                      readOnly
                      onClick={() => {
                        setOpenEndDate((prev) => !prev);
                        setOpenStartDate(false);
                      }}
                    />
                  </Box>
                  {openEndDate && (
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(endDate)}
                      onChange={(date) => {
                        setEndDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenEndDate(false);
                      }}
                      months={1}
                      className="new-dashboard-calendar"
                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "5px",
                  }}
                >
                  <Button
                    title="Click to Filter"
                    sx={{
                      background: "var(--secondary-color)",
                      color: "white",
                      padding: "5px 20px !important",
                      fontSize: "12px !important",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    FILTER
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "5px",
                  }}
                >
                  <Button
                    title="Click to Reset"
                    sx={{
                      background: "var(--secondary-color)",
                      color: "white",
                      padding: "5px 20px !important",
                      fontSize: "12px !important",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                    onClick={() => setQueuesData(allQueuesData)}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Box>
            <Box
              className="table-wrapper"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "var(--secondary-color)",
                  borderRadius: "0px",
                },
                height: "calc(100vh - 220px)",
                overflowY: "scroll",
              }}
            >
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th width="10%">ReferenceId</th>
                    <th width="12%">Airlines</th>
                    <th width="13%">Status</th>
                    <th width="10%">Route</th>
                    <th width="5%">Type</th>
                    <th width="5%">Pax</th>
                    <th width="15%">BookedDate</th>
                    <th width="10%">FlightDate</th>
                    <th width="10%">Passenger</th>
                    <th width="10%">Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {queuesData?.map((bookingDetail, index) => (
                    <tr key={{ index }}>
                      <td>
                        <Typography
                          disabled={
                            bookingDetail.Status === "Cancelled" ? true : false
                          }
                          sx={{
                            border: "none",
                            cursor: "pointer",
                            background: "#d1e9ff",
                            color: "var(--secondary-color)",
                            padding: "5px 10px",
                            textDecoration: "underline",
                            borderRadius: "4px",
                            fontSize: "12px",
                            textAlign: "center",
                            "&:hover": {
                              background: "#d1e9ff",
                              color: "#003566",
                            },
                          }}
                          onClick={() =>
                            sendToQueuesDetails(
                              bookingDetail?.AgentId,
                              bookingDetail?.BookingId,
                              bookingDetail?.TripType,
                              bookingDetail?.Pnr,
                              bookingDetail?.gdsSystem
                            )
                          }
                        >
                          {bookingDetail.BookingId ?? "Reference No"}
                        </Typography>
                      </td>
                      <td>{bookingDetail.Career ?? "Airlines"}</td>
                      <td>
                        <Typography
                          sx={{ fontSize: "12px", textAlign: "center" }}
                          className={`${bookingDetail.Status?.toLowerCase()
                            ?.split(" ")
                            ?.join("-")}-btn`}
                        >
                          {bookingDetail.Status}
                        </Typography>
                      </td>
                      <td>
                        {bookingDetail.DepFrom ?? "From"} -{" "}
                        {bookingDetail.ArrTo ?? "To"}
                      </td>
                      <td>{bookingDetail.TripType ?? "Flight Type"}</td>
                      <td>{bookingDetail.TotalPax ?? "Total Pax"}</td>

                      <td>
                        {bookingDetail?.created_at !== "" || "undefined" || null
                          ? format(
                              new Date(bookingDetail?.created_at),
                              "dd MMM yy hh:mm a"
                            )
                          : "Booked Date"}
                      </td>
                      <td>{bookingDetail?.journeyDate || "Flight Date"}</td>
                      <td>{bookingDetail?.Name || "Passenger Name"}</td>
                      <td>
                        {commaNumber(bookingDetail.InvoicePrice) + "SAR" ||
                          "Gross Cost"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Box
              sx={{
                width: "100%",
                py: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <Typography style={{ fontSize: "15px", color: "#222222" }}>
                Showing Results 1 - 10 of 20
              </Typography> */}
              <ThemeProvider theme={theme}>
                <Stack spacing={2}>
                  <Pagination
                    size="small"
                    count={pageCount}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Queues;
