import { Box, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const BankTab = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem("user-info");
  let agentId = users?.user?.agentId;
  console.log(users);
  const companyName = users?.user?.company;
  const [isLoading, setIsLoading] = useState(true);
  const [bankDetails, setBankDetails] = useState([]);
  const [depositFrom, setDepositFrom] = useState("");
  const [depositTo, setDepositTo] = useState("");
  const [bankName, setBankName] = useState("");
  const [reference, setReference] = useState("");

  const [transactionId, setTransactionId] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState("");

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(false);
    const formData = new FormData();
    formData.append("file", file);

    try {
      let res = await axios.post(
        `https://api.flyjatt.com/v1/Deposit/addDeposit.php?agentId=${agentId}&sender=${depositFrom}&receiver=${depositTo}&way=Bank&method=${bankName}&transactionId=${transactionId}&amount=${amount}&ref=${reference}&actopnBy=Agent`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Deposit Successfully!",
          html: `For any query.Please contact us at <strong> contact@ticketfactory.sa</strong> or Call <strong> +971509517784 </strong>`,
          button: "Done!",
        }).then(function () {
          setIsLoading(true);
          navigate(-1);
        });
      } else {
        throw new Error();
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Deposit Failed!",
        html: `For any query.Please contact us at <strong> contact@ticketfactory.sa</strong> or Call <strong> +971509517784 </strong>`,
        button: "Done!",
      }).then(function () {
        setIsLoading(true);
        navigate(0);
      });
    }
    e.target.reset();
  };

  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Bank/index.php?all`)
      .then((res) => res.json())
      .then((data) => setBankDetails(data));
  }, []);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className="passengerInput1">
          <Grid container spacing={{ xs: 2, sm: 2, md: 4 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Deposit From (Account Number)
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  type="text"
                  placeholder="Enter Account Number"
                  // value={companyName}
                  // style={{ textTransform: "uppercase" }}
                  onChange={(e) => setDepositFrom(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ alignItem: "end" }}>
              <Typography>
                Deposit To{" "}
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <select
                style={{ marginTop: "5px" }}
                id="afterSelect"
                onChange={(e) => setDepositTo(e.target.value)}
                required
              >
                <>
                  <option value="">Deposit To A/C</option>
                  {bankDetails?.map((bankName) => (
                    <option>
                      {`${bankName?.bank_name} ${bankName?.account_name}
                      (${bankName?.IBAN})`}
                    </option>
                  ))}
                </>
              </select>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Transaction Id
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  placeholder="Transaction Id"
                  required
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Reference
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  placeholder="Reference"
                  required
                  onChange={(e) => setReference(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Amount
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  placeholder="Amount"
                  required
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Transaction Copy
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }} className="input-File1">
                <input
                  required
                  style={{
                    backgroundColor: "#2564B8",
                    borderRadius: "4px",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 10px",
                    boxSizing: "border-box",
                  }}
                  onChange={onChangeFile}
                  className="customFileType"
                  type="file"
                  title="Choose a Image png and jpg please"
                  accept="image/*"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ display: "flex", alignItems: "end", mt: 2 }}
            >
              <Button
                type="submit"
                sx={{
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  background: "var(--secondary-color)",
                  color: "var(--white)",
                  width: "100%",
                  "&:hover": {
                    background: "var(--secondary-color)",
                    color: "var(--white)",
                  },
                }}
              >
                {isLoading ? (
                  "Send Deposit Request"
                ) : (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: "#fff",
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default BankTab;
