import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  createTheme,
  Grid,
  Pagination,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "./../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import moment from "moment";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222222",
      darker: "#222222",
    },
  },
});

const Deposite = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem("user-info");
  let agentId = users?.user?.agentId;
  const [depositData, setDepositData] = useState([]);
  const [allDepositData, setAllDepositData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const size = 25;
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setDepositData(allDepositData?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const [paymentMethod, setPaymentMethod] = useState("");
  const handleMethod = (e) => {
    setPaymentMethod(e.target.value);
    if (e.target.value === "" && transactionType === "") {
      setPageCount(Math.ceil(allDepositData.length / size));
      setDepositData(allDepositData?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "") {
      const filter = allDepositData?.filter(
        (item) => item?.paymentway?.toLowerCase() === "cash"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "") {
      const filter = allDepositData?.filter(
        (item) => item?.paymentway?.toLowerCase() === "bank"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "pending") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "approved") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "rejected") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "pending") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "approved") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "rejected") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    }
  };
  const [transactionType, setTransactionType] = useState("");
  const handleTransactionType = (e) => {
    setTransactionType(e.target.value);
    if (paymentMethod === "" && e.target.value === "") {
      setPageCount(Math.ceil(allDepositData.length / size));
      setDepositData(allDepositData?.slice(0, size));
    } else if (paymentMethod === "" && e.target.value === "pending") {
      const filter = allDepositData?.filter(
        (item) => item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "" && e.target.value === "approved") {
      const filter = allDepositData?.filter(
        (item) => item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "" && e.target.value === "rejected") {
      const filter = allDepositData?.filter(
        (item) => item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "cash" && e.target.value === "pending") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "cash" && e.target.value === "approved") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "cash" && e.target.value === "rejected") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "bank" && e.target.value === "pending") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "bank" && e.target.value === "approved") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    } else if (paymentMethod === "bank" && e.target.value === "rejected") {
      const filter = allDepositData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setDepositData(filter?.slice(0, size));
    }
  };
  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Deposit/index.php?agentId=${agentId}`)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(Math.ceil(data?.data?.length / size));
        setDepositData(data?.data?.slice(0, size));
        setAllDepositData(data?.data);
      });
  }, [agentId]);
  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allDepositData.filter((item) => {
      return (
        new Date(item?.created_at).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created_at).getDate() <= new Date(endDate).getDate()
      );
    });
    setDepositData(filterData);
  };
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              mb: "5px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              mb={{ xs: 1, sm: 0 }}
            >
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: { xs: 20, sm: 24 },
                }}
              >
                Deposit Requests
              </Typography>
              <Box
                onClick={() => navigate("/user/adddeposit")}
                sx={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  padding: "5px 25px",
                  background: "var(--secondary-color)",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  fontSize: "16px",
                  display: { xs: "flex", sm: "none" },
                }}
              >
                Add Deposit
              </Box>
            </Stack>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: { xs: "space-between", sm: "end" },
                alignItems: "center",

                ".MuiSelect-select": {
                  borderColor: "var(--secondary-color)",
                  outline: "none",
                  padding: "10px 5px",
                },
              }}
            >
              <select
                onChange={handleMethod}
                value={paymentMethod}
                style={{
                  width: "150px",
                  borderRadius: "4px",
                  outline: "none",
                  height: "100%",
                  padding: "5px 10px",
                  color: "var(--primary-color)",
                  fontWeight: "normal",
                  borderColor: "var(--secondary-color)",
                  fontSize: "14px",
                }}
              >
                <option value="">Show All</option>
                <option value="cash">Cash</option>
                <option value="bank">Bank Transfer</option>
              </select>
              <select
                value={transactionType}
                onChange={handleTransactionType}
                style={{
                  width: "150px",
                  borderRadius: "4px",
                  outline: "none",
                  height: "100%",
                  padding: "5px 10px",
                  color: "var(--primary-color)",
                  fontWeight: "normal",
                  borderColor: "var(--secondary-color)",
                  fontSize: "14px",
                }}
              >
                <option value="">Show All</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <Box
                onClick={() => navigate("/user/adddeposit")}
                sx={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  padding: "5px 10px",
                  background: "var(--secondary-color)",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  fontSize: "16px",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                Add Deposit
              </Box>
            </Box>
          </Box>
          <Box sx={{ mb: "5px" }}>
            <form onSubmit={handleFilter}>
              <Grid
                container
                spacing={1}
                alignItems="end"
                justifyContent="end"
                sx={{
                  zIndex: "9999",
                  input: {
                    width: "100%",
                    padding: "5px 10px",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "5px",
                    cursor: "pointer",
                    outline: "none",
                  },
                }}
              >
                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box>
                    <input
                      title="Enter Start Date"
                      required
                      id="startDate"
                      name="startDate"
                      type="text"
                      value={startDate}
                      readOnly
                      onClick={() => {
                        setOpenEndDate(false);
                        setOpenStartDate((prev) => !prev);
                      }}
                    />
                  </Box>
                  {openStartDate && (
                    <Calendar
                      color={"var(--primary-color)"}
                      date={new Date(startDate)}
                      onChange={(date) => {
                        setStartDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenStartDate(false);
                      }}
                      maxDate={new Date()}
                      months={1}
                      className="new-dashboard-calendar"
                      name="dashboard-calendar"
                    />
                  )}
                </Grid>

                <Grid
                  style={{ position: "relative" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Box>
                    <input
                      title="Enter End Date"
                      required
                      id="endDate"
                      name="endDate"
                      type="text"
                      value={endDate}
                      readOnly
                      onClick={() => {
                        setOpenEndDate((prev) => !prev);
                        setOpenStartDate(false);
                      }}
                    />
                  </Box>
                  {openEndDate && (
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(endDate)}
                      onChange={(date) => {
                        setEndDate(format(new Date(date), "dd MMM yyyy"));
                        setOpenEndDate(false);
                      }}
                      months={1}
                      className="new-dashboard-calendar"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "5px",
                  }}
                >
                  <Button
                    title="Click to Filter"
                    sx={{
                      background: "var(--secondary-color)",
                      color: "white",
                      padding: "5px 20px !important",
                      fontSize: "12px !important",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    FILTER
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "5px",
                  }}
                >
                  <Button
                    title="Click to Reset"
                    sx={{
                      background: "var(--secondary-color)",
                      color: "white",
                      padding: "5px 20px !important",
                      fontSize: "12px !important",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                    onClick={() => setDepositData(allDepositData)}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Box>
            <Box
              className="table-wrapper"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "var(--secondary-color)",
                  borderRadius: "0px",
                },
                height: "calc(100vh - 300px)",
                overflowY: "scroll",
              }}
            >
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th width="10%">RefId</th>
                    <th width="10%">Status</th>
                    <th width="10%">Type </th>
                    <th width="20%">Sender</th>
                    <th width="10%">Amount</th>
                    <th width="20%">Receiver</th>
                    <th width="10%">Date</th>
                    <th width="10%">Attachment</th>
                    <th width="10%">Remarks</th>
                  </tr>
                </thead>

                <tbody>
                  {depositData
                    ? depositData?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.depositId || "depositId"}</td>
                          <td>
                            <Typography
                              sx={{ fontSize: "12px", textAlign: "left" }}
                              className={`${data?.status
                                ?.toLowerCase()
                                ?.split(" ")
                                ?.join("-")}-btn`}
                            >
                              {data?.status || "Status"}
                            </Typography>
                          </td>
                          <td>{data?.paymentway || "PaymentWay"}</td>
                          <td>{data?.sender || "Sender"}</td>
                          <td>{`${commaNumber(
                            Number.parseInt(data?.amount) || 0
                          )} SAR`}</td>
                          <td>{data?.receiver}</td>
                          <td>
                            {" "}
                            {moment(data?.created_at).format(
                              "DD MMM YY hh:mm a"
                            )}
                          </td>
                          <td>
                            <a
                              href={`https://api.flyjatt.com/assets/deposit/${agentId}/${data?.attachment}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          </td>
                          <td>
                            {" "}
                            {data?.remarks === "" ? "N/A" : data?.remarks}{" "}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </Box>
            <Box
              sx={{
                width: "100%",
                py: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <Typography style={{ fontSize: "15px", color: "#222222" }}>
                Showing Results 1 - 10 of 20
              </Typography> */}
              <ThemeProvider theme={theme}>
                <Stack spacing={2}>
                  <Pagination
                    size="small"
                    count={pageCount}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Deposite;
