import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../Header/Header";
import Loader from "../../images/loader/Render.gif";
import AirlineNames from "../Congratulation/AirlineNames/AirlineNames";
import Address from "../Congratulation/Address/Address";
import { format, setDate } from "date-fns";
import secureLocalStorage from "react-secure-storage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "10PX",
  boxShadow: 24,
};

const PnrImport = () => {
  const users = secureLocalStorage.getItem("user-info");
  let agentId = users?.user?.agentId;
  const navigate = useNavigate();
  const [importData, setImportData] = useState({});
  const [importDetails, setImportDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `https://api.flyjatt.com/v1/AirBooking/AirRetrive.php?bookingId=${importData?.pnr}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "error") {
          Swal.fire({
            icon: "error",
            title: data?.message,
            confirmButtonText: "Ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            navigate("/user/dashboard");
            setIsLoading(false);
          });
        } else {
          setImportDetails(data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Failed To Fetch",
          confirmButtonText: "Ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate("/user/dashboard");
          setIsLoading(false);
        });
      });
    e.target.reset();
  };

  useEffect(() => {
    const url1 = `https://api.flyjatt.com/v1/AirBooking/AirReprice.php?pnr=${importData?.pnr}`;
    fetch(url1)
      .then((response) => response.json())
      .then((data) => data);
  }, [importData?.pnr]);
  const handleChange = (e) => {
    setImportData({ ...importData, [e.target.name]: e.target.value });
  };

  const handleImport = () => {
    setIsLoading(true);
    const url = "https://api.flyjatt.com/v1/AirBooking/Pnrimport.php";
    const config = {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify({
        agentId: agentId,
        pnr: importData?.pnr,
        system: "Sabre",
      }),
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => {
        if (data.status?.toLowerCase() === "success") {
          Swal.fire({
            icon: "success",
            title: data?.message,
            confirmButtonText: "Ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            navigate("/user/queues");
            setIsLoading(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data?.message,
            confirmButtonText: "Ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            navigate("/user/queues");
            setIsLoading(false);
          });
        }
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Failed To Fetch",
          confirmButtonText: "Ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate("/user/queues");
          setIsLoading(false);
        });
      });
  };

  if (isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 3, md: 2 } }}>
        <Box
          sx={{
            my: "5px",
          }}
        >
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={4}>
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "24px",
                  fontWeight: "500",
                  // width: { xs: "100%", sm: "20%" },
                }}
              >
                PNR Import
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  // alignItems="end"
                  justifyContent={"start"}
                >
                  <Grid
                    style={{ position: "relative" }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <select
                      onChange={handleChange}
                      value={importData?.system}
                      name="system"
                      style={{
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px",
                        padding: "5px 10px",
                        width: "100%",
                      }}
                    >
                      <option value="">Select System</option>
                      <option value="sabre">Sabre</option>
                    </select>
                  </Grid>

                  <Grid
                    style={{ position: "relative" }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <input
                      required
                      name="pnr"
                      placeholder="Enter PNR"
                      style={{
                        width: "100%",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px",
                        padding: "5px 10px",
                      }}
                      type="text"
                      value={importData?.pnr}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "end",
                      gap: "5px",
                    }}
                  >
                    <Button
                      sx={{
                        background: "var(--secondary-color)",
                        color: "white",
                        padding: "5px 20px !important",
                        fontSize: "12px !important",
                        width: "100%",
                        "&:hover": {
                          background: "var(--secondary-color)",
                          color: "white",
                        },
                      }}
                      type="submit"
                    >
                      PROCEED
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
        {importDetails ? (
          <Box>
            <Box my={2}>
              <Typography
                sx={{
                  width: "100%",
                  background: "var(--third-color)",
                  color: "var(--secondary-color)",
                  padding: "2px 5px",
                  borderRadius: "4px",
                }}
              >
                Flight Information
              </Typography>
              <Box className="flight-queue-detail">
                <Box mt={2}>
                  <table width="100%">
                    <thead>
                      <tr>
                        <th width="15%">Flight</th>
                        <th width="23%">Departure From</th>
                        <th width="23%">Arrival To</th>
                        <th width="12%">Depart At</th>
                        <th width="12%">Arrive At</th>
                        <th width="15%">Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {importDetails?.flights
                        ? importDetails?.flights?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <AirlineNames code={data.airlineCode} />{" "}
                                {data?.airlineCode}-{data?.flightNumber}
                                <br />
                                {data?.aircraftTypeName}
                              </td>
                              <td>
                                ({data.fromAirportCode}){" "}
                                <Address code={data.fromAirportCode} />
                                <br />
                                {data?.departureTerminalName || null}{" "}
                                {data?.departureGate
                                  ? `Gate: ${data?.departureGate || null}`
                                  : null}
                              </td>
                              <td>
                                ({data.toAirportCode}){" "}
                                <Address code={data.toAirportCode} />
                                <br />
                                {data?.arrivalTerminalName || null}{" "}
                                {data?.arrivalGate
                                  ? `Gate: ${data?.arrivalGate || null}`
                                  : null}
                              </td>
                              <td>
                                {data.departureDate}{" "}
                                {data.departureTime?.slice(0, 5)}
                              </td>
                              <td>
                                {data.arrivalDate}{" "}
                                {data.arrivalTime?.slice(0, 5)}
                              </td>
                              <td>
                                {data?.cabinTypeName?.toLowerCase()}-
                                {data?.bookingClass}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </Box>
              </Box>
            </Box>
            <Box my={2}>
              <Typography
                sx={{
                  width: "100%",
                  background: "var(--third-color)",
                  color: "var(--secondary-color)",
                  padding: "2px 5px",
                  borderRadius: "4px",
                }}
              >
                Price BreakDown
              </Typography>
              <Box mt={2} className="flight-queue-detail">
                <Box mt={2}>
                  <Box>
                    <table>
                      <tr>
                        <th width="20%">Passenger </th>
                        <th width="20%">Base Fare </th>
                        <th width="20%">Tax</th>
                        <th width="20%">Sub-Total Fare</th>
                        <th width="20%">Baggage</th>
                      </tr>

                      <tbody>
                        {importDetails?.fares
                          ? importDetails?.fares?.map((data, index) => (
                              <tr key={index}>
                                <td>
                                  {data?.pricedTravelerType === "ADT"
                                    ? "Adult"
                                    : data?.pricedTravelerType === "INF"
                                    ? "Infant"
                                    : "Child"}
                                  {" X "}
                                  {data?.travelerIndices?.length || 1}
                                </td>
                                <td>{data?.totals?.subtotal} SAR</td>
                                <td>{data?.totals?.taxes} SAR</td>
                                <td>{data?.totals?.total} SAR</td>
                                <td>
                                  {data?.fareConstruction[0]
                                    ?.checkedBaggageAllowance
                                    ?.totalWeightInKilograms
                                    ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                                    : null}
                                  {data?.fareConstruction[0]
                                    ?.checkedBaggageAllowance?.maximumPieces
                                    ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                                    : null}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </Box>

                  <table className="flight-queue-detail table-data">
                    <tr>
                      <td width="20%">Total Passenger</td>
                      <td width="20%">
                        Total Base Fare{" "}
                        {(importDetails?.fares &&
                          `${importDetails?.fares[0]?.totals?.subtotal} SAR`) ||
                          "Please Load Fare"}
                      </td>
                      <td width="20%">
                        Total Taxes{" "}
                        {(importDetails?.fares &&
                          `${importDetails?.fares[0]?.totals?.taxes} SAR`) ||
                          "Please Load Fare"}
                      </td>
                      <td width="40%">
                        Total Amount{" "}
                        {(importDetails?.fares &&
                          `${importDetails?.fares[0]?.totals?.total} SAR`) ||
                          "Please Load Fare"}
                      </td>
                    </tr>
                  </table>
                </Box>
              </Box>
            </Box>
            <Box my={3}>
              <Typography
                sx={{
                  width: "100%",
                  background: "var(--third-color)",
                  color: "var(--secondary-color)",
                  padding: "2px 5px",
                  borderRadius: "4px",
                }}
              >
                Passenger Information
              </Typography>
              <Box mt={2} className="queue-detail-passenger-detail">
                {importDetails?.travelers
                  ? importDetails?.travelers?.map((traveler) => (
                      <Box>
                        <Box
                          p="3px 5px"
                          border="1px solid #DEDEDE"
                          borderRadius="4px"
                          my="2px"
                          display={"flex"}
                          justifyContent={"space-between"}
                          width={"100%"}
                        >
                          <h5
                            style={{
                              color: "var(--secondary-color)",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            {traveler?.givenName?.slice(
                              traveler?.givenName?.lastIndexOf(" ")
                            )}{" "}
                            {traveler?.givenName?.slice(
                              0,
                              traveler?.givenName?.lastIndexOf(" ") ||
                                "First Name"
                            )}{" "}
                            {traveler?.surname || "Last Name"}
                          </h5>
                        </Box>

                        <Box
                          border="1px solid #DEDEDE"
                          borderRadius="4px"
                          p="3px 5px"
                          mb={2}
                        >
                          <Grid container spacing={{ xs: 1, sm: 2 }}>
                            <Grid item xs={4} sm={3} md={2}>
                              <h5>First Name</h5>
                              <h6>
                                {traveler?.givenName?.slice(
                                  0,
                                  traveler?.givenName?.lastIndexOf(" ") ||
                                    "First Name"
                                )}
                              </h6>
                            </Grid>
                            <Grid item xs={4} sm={3} md={2}>
                              <h5>Last Name</h5>
                              <h6>{traveler?.surname || "Last Name"}</h6>
                            </Grid>
                            <Grid item xs={4} sm={3} md={2}>
                              <h5>Nationality</h5>
                              <h6>
                                {(traveler?.identityDocuments &&
                                  traveler?.identityDocuments[0]
                                    ?.residenceCountryCode) ||
                                  "Country Code"}
                              </h6>
                            </Grid>

                            <Grid item xs={4} sm={3} md={2}>
                              <h5>Date of Birth</h5>
                              <h6>
                                {traveler?.identityDocuments &&
                                traveler?.identityDocuments[0]?.birthDate
                                  ? format(
                                      new Date(
                                        traveler?.identityDocuments[0]?.birthDate
                                      ),
                                      "dd MMM yyyy"
                                    )
                                  : "Date of Birth"}
                              </h6>
                            </Grid>

                            <Grid item xs={4} sm={3} md={2}>
                              <h5>Gender</h5>
                              <h6>
                                {(traveler?.identityDocuments &&
                                  traveler?.identityDocuments[0]?.gender) ||
                                  "Gender"}
                              </h6>
                            </Grid>

                            <Grid item xs={4} sm={3} md={2}>
                              <h5>Pax Type</h5>
                              <h6>{traveler?.type || "Type"}</h6>
                            </Grid>

                            <Grid item xs={6} sm={3} md={2}>
                              <h5>Passport Number</h5>
                              <h6>
                                {(traveler?.identityDocuments &&
                                  traveler?.identityDocuments[0]?.documentNumber?.toUpperCase()) ||
                                  "Domestic Flight"}
                              </h6>
                            </Grid>
                            <Grid item xs={6} sm={3} md={2}>
                              <h5>Expire Date</h5>

                              <h6>
                                {traveler?.identityDocuments &&
                                traveler?.identityDocuments[0]?.expiryDate
                                  ? format(
                                      new Date(
                                        traveler?.identityDocuments[0]?.expiryDate
                                      ),
                                      "dd MMM yyyy"
                                    )
                                  : "Expire Date"}
                              </h6>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    ))
                  : null}
              </Box>
            </Box>

            <Box mb={4}>
              {importDetails?.fares === undefined ||
              importDetails?.travelers[0]?.identityDocuments === undefined ? (
                <Button
                  sx={{
                    background: "var(--primary-color)",
                    color: "white",
                    padding: "5px 20px",
                    "&:hover": {
                      background: "var(--secondary-color)",
                      color: "white",
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  Import
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "var(--secondary-color)",
                    color: "white",
                    padding: "5px 20px",
                    "&:hover": {
                      background: "var(--secondary-color)",
                      color: "white",
                    },
                  }}
                  onClick={handleImport}
                >
                  Import
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={style} bgcolor="#fff" p="25px">
            <Typography
              sx={{ color: "crimson", fontWeight: 500, fontSize: 18 }}
            >
              You cannot import this pnr. This pnr not loaded and passenger
              passport number is missing. Please load fare and try again.
            </Typography>
            <Typography
              sx={{
                mt: 2,
                background: "var(--secondary-color)",
                color: "white",
                padding: "5px 20px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Typography>
          </Box>
          {/* </from> */}
        </Modal>
      </Container>
    </Box>
  );
};

export default PnrImport;
