/* eslint-disable jsx-a11y/no-distracting-elements */
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  Menu,
  ClickAwayListener,
  Avatar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import { Container } from "@mui/system";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import useAuthentication from "../../hooks/useAuthentication";
import { GiTwoCoins } from "react-icons/gi";
import Logo from "../../images/logo/logo.png";
import balanceIcon from "../../images/balance.png";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import "./Header.css";

const Header = () => {
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const { logout } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const [marqueText, setMarqueText] = useState("");
  // todo: for mobile device
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  useEffect(() => {
    const url = `https://api.flyjatt.com/v1/Agent/index.php?myaccount`;
    const body = JSON.stringify({
      agentId,
    });
    const config = {
      method: "POST",
      header: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body,
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => setBalance(data?.balance))
      .catch((err) => console.log(err.message));
  }, [balance, agentId]);
  useEffect(() => {
    const url = "https://api.flyjatt.com/v1/Notice/index.php?all";
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setMarqueText(data);
      })
      .catch((err) => console.log(err.message));
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClickAway = () => {};

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Container>
          <Box
            sx={{
              height: "fit-content",
              width: "100%",
              alignItems: "center",
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            {/* //todo:Logo  */}
            <Box
              sx={{
                width: { md: "15%", sm: "50%", xs: "50%" },
                background: "var(--white)",
                height: "100%",
                display: "flex",
                justifyContent: {
                  lg: "start",
                  md: "start",
                  sm: "center",
                  xs: "center",
                },
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/user/dashboard")}
            >
              <Box
                sx={{
                  height: "60px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    // height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "85%",
                height: "60px",
                display: { md: "flex", sm: "none", xs: "none" },
                alignItems: "center",
                justifyContent: "end",
                gap: "10px",
                position: "relative",
              }}
            >
              {/* <Box
                sx={{
                  background: "var(--primary-color)",
                  height: "35px",
                  borderRadius: "20px",
                  input: {
                    border: "none",
                    background: "none",
                    outline: "none",
                    float: "left",
                    padding: 0,
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "normal",
                    transition: "all 0.4s ease-in-out",
                    lineHeight: "35px",
                    width: "0px",
                  },
                  "input::placeholder": { color: "white" },
                  "&:hover input": {
                    width: "235px",
                    padding: "0 10px",
                  },
                  "&:hover button": {
                    background: "white",
                    color: "black",
                  },
                  button: {
                    border: "none",
                    color: "white",
                    float: "right",
                    minHeight: "fit-content",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    background: "var(--primary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.4s ease-in-out",
                    cursor: "pointer",
                    textDecoration: "none",
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Search....."
                  // onChange={handelSearchItems}
                />
                <button>
                  <SearchIcon />
                </button>
              </Box> */}

              <Box
                sx={{
                  background: "#222222",
                  height: "35px",
                  borderRadius: "20px",
                  input: {
                    border: "none",
                    background: "none",
                    outline: "none",
                    float: "left",
                    padding: 0,
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "normal",
                    transition: "all 0.4s ease-in-out",
                    lineHeight: "35px",
                    width: "0px",
                  },
                  "input::placeholder": { color: "white" },
                  "&:hover input": {
                    width: "230px",
                    padding: "0 10px",
                  },
                  "&:hover button": {
                    background: "white",
                    color: "black",
                  },
                  button: {
                    border: "1.5px solid #222222",
                    color: "white",
                    float: "right",
                    minHeight: "fit-content",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    background: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.4s ease-in-out",
                    cursor: "pointer",
                    textDecoration: "none",
                  },
                }}
              >
                <input
                  type="text"
                  value={`Balance: ${commaNumber(balance || 0)} SAR`}
                />
                <button>
                  {/* <AccountBalanceIcon /> */}
                  <img src={balanceIcon} alt="balance" width="100%" />
                </button>
              </Box>
              {/* <Box
                sx={{
                  padding: "5px 20px",
                  background: "var(--secondary-color)",
                  color: "var(--white)",
                  borderRadius: "20px",
                }}
              >
                {`Balance: ${commaNumber(balance || 0)} SAR`}
              </Box> */}
            </Box>
            {/* //todo:Normal Mobile Navbar */}
            <Box
              sx={{
                width: "80%",
                height: "60px",
                display: { md: "none", sm: "flex", xs: "flex" },
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Tooltip title="Open Menu">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>
                    {/* {user?.user?.name[0].toUpperCase() || ""} */}TF
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Box
                    sx={{
                      color: "var(--secondary-color)",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      fontSize: "17px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Tooltip title={`Balance: ${commaNumber(balance)}SAR`}>
                      <Box>
                        <GiTwoCoins style={{ fontSize: "30px" }} />
                      </Box>
                    </Tooltip>
                    <Box>{commaNumber(balance)}SAR</Box>
                    <NavLink
                      to="/adddeposite"
                      style={{
                        color: "var(--secondary-color)",
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title="Add Money">
                        <AddIcon style={{ fontSize: "30px" }} />
                      </Tooltip>
                    </NavLink>
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Button
                    style={{
                      background: "var(--mateBlack)",
                      color: "var(--white)",
                      fontWeight: 400,
                      padding: "10px",
                      cursor: "pointer",
                      width: "100%",
                      zIndex: "999",
                    }}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <LogoutIcon />
                    Logout
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <marquee
            style={{
              background: "var(--secondary-color)",
              color: "var(--white)",
              padding: "6px 0",
              margin: "10px 0px 0px",
              borderRadius: "20px",
              fontSize: "14px",
            }}
          >
            {marqueText && marqueText?.map((item) => item.notice)}
          </marquee>
        </Container>
      </Box>
    </ClickAwayListener>
  );
};

export default Header;
